@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Code&display=swap");

@import "https://cdn.jsdelivr.net/npm/@radix-ui/colors@latest/brownA.css";
@import "https://cdn.jsdelivr.net/npm/@radix-ui/colors@latest/green.css";
@import "https://cdn.jsdelivr.net/npm/@radix-ui/colors@latest/mauve.css";
@import "https://cdn.jsdelivr.net/npm/@radix-ui/colors@latest/blue.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Inter"; */
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

* {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
*::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

/* ::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #191f2fa8;
}

::-webkit-scrollbar-thumb:hover {
  background: #191f2fa8;
} */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

body {
  font-family: "Inter";
}

.crisp-client .cc-tlyw .cc-kxkl .cc-nsge .cc-imbb {
  display: none !important;
  width: 30px !important;
  height: 30px !important;
}

b {
  font-weight: 600;
}

i {
  font-weight: 500;
}

.onboardingModal .homeContainer {
  display: flex;
  height: 100%;
}

.homeContainer .rightContainer {
  /* background-color: #191f2f04; */
}

.homeContentContainer {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  /* background-color: #1252f308; */
  border-radius: 10px;
  margin: 10px 0px;
  overflow: hidden;
  padding-bottom: 40px;
  /* margin-top: 40px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.homeContainerLogo {
  width: 50px;
  height: 50px;
  /* margin-bottom: 15px; */
}

.homeContent {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* height: fit-content; */
  width: 700px;
  margin: auto;
  margin-top: 20px;
  background-color: white;
  border-radius: 15px;
  /* border: 1px solid #191f2f15; */
  /* filter: drop-shadow(0 2px 5px rgb(0 0 0 / 0.05))
    drop-shadow(0 3px 5px rgb(0 0 0 / 0.03)); */
  /* transform: scale(1.04); */
  padding: 0px;
  animation: fadeIn 0.6s ease-in-out;
  transform: scale(0.9);
}

.homeContentButtonsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.homeContentContainerLink {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  height: 200px;
  width: 220px;
  color: #2e3242;
  text-decoration: none;
  padding: 20px;
  line-height: 1.4em;
  background-color: #1252f310;
  border: 1px solid #1252f315;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.1),
    rgba(56, 127, 234, 0.1)
  );
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  margin-right: 15px;
  padding-right: 32px;
}

.homeContentContainerLinkDescription {
  font-size: 14px !important;
  line-height: 1.5em;
  color: #54596b;
  font-weight: 400;
  margin-top: 10px;
}

.homeContentHeaderText {
  font-size: 20px;
  font-weight: 600;
  color: #141928;
  text-align: center;
}

.homeContentPendingFlows {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 40px;
  /* padding-bottom: 30px; */
  /* border-bottom: 1px solid #191f2f20; */
  width: 720px;
  background-color: #1252f307;
  padding: 30px;
  border-radius: 15px;
  border: 1px solid #1252f315;
  animation: fadeIn 0.5s ease-in-out;
  max-height: 40%;
}

.pendingFlowsHeader {
  position: relative;
}

.pendingFlowsLength {
  position: absolute;
  right: 0;
  width: 35px;
  height: 35px;
  background-color: #1252f3;
  border-radius: 50px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 18px;
  font-weight: 600;
  outline: 4px solid #1252f330;
}

.pendingFlowsHeaderText {
  font-size: 19px;
  font-weight: 600;
}

.pendingFlowsDescription {
  font-size: 14px;
  margin-top: 5px;
  color: #363d52;
}

.pendingFlowsList {
  margin-top: 10px;
  max-height: 200px;
  overflow-y: scroll;
}

.eachPendingFlow {
  display: flex;
  align-items: center;
  padding: 12px 0;
  position: relative;
  cursor: pointer;
}

.pendingFlowText:hover {
  opacity: 0.6;
}

.pendingFlowIcon {
  color: white;
  border-radius: 50px;
  margin-right: 10px;
  background-color: #1252f3;
  width: 17px;
  height: 17px;
  padding: 2px;
}

.pendingFlowText {
  font-weight: 500;
  text-decoration: underline;
}

.pendingFlowRightIcon {
  position: absolute;
  right: 0;
}

.versionText {
  color: #252933;
}

.homeContentHeaderDescription {
  margin-top: 10px;
  margin-bottom: 20px;
  width: 200px;
  text-align: center;
  /* text-align: center; */
  line-height: 1.65em;
  color: #363b48;
  font-size: 14px;
}

.homeContentButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
}

.homeContentButtonsHeader {
  /* background-color: #191f2f04; */
  padding: 20px 20px;
  padding-bottom: 20px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  width: 100%;
}

.homeContentButtonsHeaderRight {
  margin-left: 10px;
}

.homeContentButtonsHeaderIcon {
  font-size: 22px;
  color: #1252f3;
  margin-bottom: 5px;
}

.homeContentButtonsHeaderText {
  font-size: 15px;
  font-weight: 600;
  text-align: left;
  align-self: flex-start;
  margin-bottom: 10px;
}

.homeContentButtonsHeaderDescription {
  font-size: 14px;
  color: #2a3144;
}

.tasksCompleted {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.tasksCompletedProgressContainer {
  width: 100%;
  height: 4px;
  background-color: #191f2f10;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}

.tasksCompletedText {
  font-size: 13px;
  width: 220px;
}

.tasksCompletedProgress {
  position: absolute;
  left: 0;
  background-color: #1252f3;
  width: 30%;
  height: 4px;
  transition: 0.3s ease-in-out;
}

.homeContentButtonsList {
  padding: 20px 18px;
  width: 100%;
  padding-top: 10px;
}

.homeContentLink {
  display: flex;
  /* align-items: center; */
  /* flex-direction: column; */
  /* justify-content: space-between; */
  width: 100%;
  /* margin-bottom: 10px; */
  padding: 10px 5px;
  margin-right: 10px;
  background-color: white;
  /* border: 1px solid #1252f315; */
  border-radius: 10px;
  cursor: pointer;
  /* background-color: #1252f304; */
  text-decoration: none;
  color: #191f2f;
}

.selectedHomeContentLink {
  padding: 20px 10px;
  background-color: #191f2f07;
}

.selectedHomeContentLink .homeContentLinkText {
  font-weight: 500;
}

.homeContentLink:hover {
  background-color: #191f2f07;
}

.homeContentLinkRight {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  width: 100%;
}

.homeContentLinkText {
  font-size: 14px;
  line-height: 1.3em;
  /* font-weight: 500; */
}

.homeContentLinkDescription {
  font-size: 13.5px;
  color: #353b4d;
  margin-top: 4px;
  line-height: 1.55em;
  width: 97%;
}

.homeContentLinkButton {
  margin-top: 15px;
  background-color: #191f2f;
  width: fit-content;
  padding: 8px 18px;
  border-radius: 8px;
  font-size: 12.5px;
  color: white;
  text-decoration: none;
  outline: 4px solid #191f2f20;
  background: linear-gradient(to top, #191f2f, #2d354a);
  /* animation: defaultPulseAnimation 0.7s infinite; */
  /* filter: drop-shadow(0 2px 2px rgb(0 0 0 / 0.5))
    drop-shadow(0 2px 1px rgb(0 0 0 / 0.15)); */
}

@keyframes defaultPulseAnimation {
  0% {
    outline: 2px solid #191f2f20;
  }
  20% {
    outline: 5px solid #191f2f20;
  }
  60% {
    outline: 7px solid #191f2f25;
  }
  100% {
    outline: 8px solid #191f2f01;
  }
}

.homeContentLinkButton:hover {
  opacity: 0.9;
}

.homeContentLinkIcon {
  width: 20px;
  height: 20px;
  background-color: white;
  border: 2px solid #191f2f57;
  border-style: dashed;
  border-radius: 50px;
  font-size: 22px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.completedHomeContentLinkIcon {
  background-color: #191f2f;
  color: white;
  padding: 3px;
  transition: 0.4s ease-in-out;
}

.homeContentLinkIcon:hover {
  outline: 3px solid #191f2f07;
  border-style: solid;
  border-color: #191f2f20;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.navbarContainer {
  /* background-color: #19; */
  /* width: 20%; */
  width: 260px;
  height: 100%;
  background: linear-gradient(to left, #0c48df, #1146ce);
  position: fixed;
  left: 0;
}

.navbar {
  /* border-bottom: 1px solid rgba(227, 227, 227, 0.054); */
  display: flex;
  flex-direction: column;
  height: 100%;
  /* align-items: center; */
  /* justify-content: space-between; */
  position: relative;
}

.searchDocumentsPopupContainer {
  position: absolute;
  top: 100%;
  width: 400px;
  background-color: white;
  right: 40px;
  z-index: 999;
  margin-top: 10px;
  border-radius: 10px;
  padding: 15px 10px;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
  transition: all 1s ease-in-out;
}

.resultContainer {
  display: flex;
  align-items: center;
  padding: 5px 10px;
}

.resultContainer:hover {
  background-color: #12162010;
  cursor: pointer;
  border-radius: 5px;
}

.searchResultsLabel {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 500;
  opacity: 0.4;
  letter-spacing: 1px;
  margin-top: 5px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.resultIcon {
  width: 30px;
  height: 30px;
  font-size: 10px;
  background-color: #1216200d;
  color: #121620d4;
  padding: 5px;
  border-radius: 5px;
}

.resultInfo {
  padding: 5px;
  margin-left: 5px;
}

.resultTitle {
  font-size: 14px;
  font-weight: 600;
  color: #191f2f;
}

.resultDescription {
  font-size: 14px;
  margin-top: 3px;
  color: #474c5b;
}

.emptySearchResults {
  padding: 5px 10px;
  font-size: 14px;
  color: #474c5b;
  font-weight: 500;
  overflow-wrap: break-word;
}

.searchText {
  color: #60677e;
}

.navbarRight {
  /* display: none; */
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 10px;
}

.accountInfo {
  position: relative;
  padding: 0px 10px;
  width: 100%;
}

@keyframes bouncingAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.04);
  }
  100% {
    transform: scale(1);
  }
}

.accountInfoPlan {
  margin: 5px;
  background-color: rgba(255, 255, 255, 0.067);
  border-radius: 10px;
  padding: 15px;
  animation: bouncingAnimation 2s ease-in-out infinite;
}

.annualProAccountInfoPlanTextLabel {
  color: rgba(255, 255, 255, 0.7) !important;
}

.accountInfoPlanTextLabel {
  font-size: 11px;
  color: rgba(255, 255, 255, 0.576);
  margin-bottom: 5px;
}

.accountInfoPlanText {
  font-size: 13px;
  color: white;
  font-weight: 500;
}

.accountInfoPlanButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: 12px;
  margin-top: 10px;
}

.accountInfoPlanButtons div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 0;
  border-radius: 5px;
  cursor: pointer;
}

.accountInfoPlanMainButton {
  width: 100%;
  background: rgba(255, 255, 255, 0.035);
  margin-top: 5px;
}

.accountInfoPlanMainButtonText {
  color: rgba(255, 255, 255, 0.507);
}

.upgradePlanButton {
  background-color: white;
  width: 100%;
  font-weight: 600;
  color: #191f2f;
  padding: 10px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.1s ease-in-out;
  box-shadow: 0px 4px 7px #09349f39;
}

.accountInfoPlanExpire {
  margin-top: 4px;
  font-size: 14px;
  font-family: "Fira Code", monospace;
  margin-top: 4px;
  color: #c3d2f7;
  font-size: 11.5px;
}

.annualUpgradePlanButton {
  background-color: white;
  width: 100%;
  font-weight: 600;
  color: #191f2f;
  padding: 10px 0 !important;
  font-size: 12.5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.1s ease-in-out;
  box-shadow: 0px 4px 7px #09349f39;
}

.proText {
  color: #1252f3;
  background-image: linear-gradient(to top, #311db5, #7966f2);
  -webkit-background-clip: text; /* For Safari */
  background-clip: text;
  color: transparent;
}

.upgradePlanButton:hover {
  transform: scale(1.03);
  box-shadow: 0px 4px 10px #191f2f20;
}

.upgradePlanButtonIcon {
  color: #573ef6;
  transform: rotate(50deg);
  margin-left: 7px;
}

.accountInfoModal {
  position: fixed;
  bottom: 65px;
  margin-top: 10px;
  left: 12px;
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 235px;
  border-radius: 10px;
  box-shadow: 0 10px 25px -5px #15203e5f, 0 8px 10px -6px #15203e2f;
  transition: 0.4s ease-in-out;
}

.accountInfoModalHeader {
  padding: 12px 15px;
  border-bottom: 1px solid #12162015;
}

.accountInfoContent {
  display: flex;
  align-items: center;
  padding: 5px 0px;
  width: 100%;
  cursor: pointer;
  border-radius: 10px;
  /* margin-left: 10px; */
  /* border-left: 1px solid rgba(255, 255, 255, 0.052); */
  /* border-radius: 10px; */
}

.accountInfoContent:hover {
  background-color: rgba(255, 255, 255, 0.036);
}

.openedAccountInfoContent {
  background-color: rgba(255, 255, 255, 0.036);
}
/* 
.accountInfoContent:hover {
  background-color: rgba(255, 255, 255, 0.062);
  border-radius: 50px;
  cursor: pointer;
  border-left: 1px solid transparent;
}  */

.accountInfoRight {
  padding: 5px 10px;
  padding-right: 50px;
  position: relative;
  width: 100%;
}

.accountInfoName {
  font-size: 13px;
  color: white;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.proPlanText {
  margin-left: 5px;
  background-color: #573ef6;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  padding: 2px 6px;
  font-size: 10px;
  border-radius: 5px;
}

.accountInfoCompany {
  font-size: 12px;
  color: white;
  opacity: 0.6;
  margin-top: 2px;
}

.accountInfoDownIcon {
  position: absolute;
  top: 32%;
  color: white;
  right: 0px;
  opacity: 0.4;
  transform: translate(-50%, 0);
}

.accountName {
  font-size: 13px;
  font-weight: 600;
  color: #191f2f;
}

.accountEmail {
  font-size: 13px;
  margin-top: 2px;
  color: #191f2fb1;
}

.accountInfoLogout {
  padding: 10px 15px;
  display: flex;
  align-items: center;
  color: #191f2fb3;
  cursor: pointer;
}

.accountInfoLogout:hover {
  background-color: #191f2f07;
}

.signoutButton {
  border-top: 1px solid #191f2f10;
}

.accountInfoLogOutIcon {
  color: #3f465b;
  font-size: 16px;
}

.accountInfoLogoutText {
  font-size: 13.5px;
  margin-left: 10px;
  letter-spacing: -0.1px;
}

.accountIcon {
  width: 32px;
  height: 32px;
  background-color: #ffffff;
  border: 2px solid #4d566e49;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  font-weight: 500;
  font-size: 12px;
  margin-left: 10px;
  color: #191f2f;
  transition: 0.3s ease-in-out;
  text-transform: uppercase;
}

.proAccountIcon {
  /* border: 0px solid #573ef6;
  outline: 3px solid #573ef6; */
}

.openedAccountIcon {
  outline: 2px solid #aebde7;
}

.accountIcon:focus {
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.accountIcon:hover {
  outline: 3px solid #aebde7;
  cursor: pointer;
}

.searchDocumentsInputContainer {
  background-color: #1729562f;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 5px;
  height: 40px;
  border-radius: 50px;
  width: 220px;
  /* border: 1px solid #7495e82b; */
  /* box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1); */
}

.activeSearchDocumentsInputContainer {
  border: 1px solid #3968df;
}

.searchDocumentsInput {
  height: 100%;
  border: none;
  border-radius: 50px;
  width: 185px;
  padding: 0px 7px;
  outline: none;
  background: transparent;
  font-size: 14px;
  color: #bcc8e9;
}

.searchDocumentsInput::placeholder {
  color: #8ea7e7;
}

.searchDocumentsButton {
  background-color: #2256da;
  color: rgba(255, 255, 255, 0.786);
  border-radius: 50px;
  font-size: 30px;
  padding: 7px;
  cursor: pointer;
}

.navLinks {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding: 20px 10px;
  /* align-items: center; */
  /* margin-left: 30px; */
}

.mainLinksContainer {
  margin-top: 10px;
  /* padding-bottom: 5px; */
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.046); */
  /* margin-bottom: 5px; */
}

.mainLinkContainer {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 7px;
  cursor: pointer;
  margin-bottom: 3px;
}

.mainLinkContainer:hover {
  background-color: rgba(255, 255, 255, 0.047);
}

.currentLinkContainer {
  background-color: rgba(255, 255, 255, 0.067);
}

.currentLinkContainer .navLinkIcon {
  opacity: 1;
  color: white;
}

.activeMainLinkContainer {
  background-color: white;
  width: 100%;
  justify-content: center;
  margin-bottom: 10px;
  box-shadow: 0px 6px 2px #191f2f15;
}

.activeMainLinkContainer:hover {
  background-color: white;
}

.activeMainLinkContainer .mainLinkIcon {
  color: #191f2f;
  background-color: #191f2f15;
  padding: 2px;
  border-radius: 3px;
}

.activeMainLinkContainer .mainLinkText {
  color: #191f2f;
  font-size: 13.5px;
  font-weight: 600;
  letter-spacing: -0.12px;
}

.mainLinkIcon {
  color: rgba(255, 255, 255, 0.638);
  font-size: 20px;
}

.mainLinkText {
  font-size: 14px;
  margin-left: 10px;
  font-weight: 500;
  color: #f0efef;
  /* color: white; */
}

.navLinkContainer {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border-radius: 7px;
  margin-bottom: 3px;
  text-decoration: none;
}

.navLinkContainer:hover {
  background-color: rgba(255, 255, 255, 0.067);
}

.navLinkIcon {
  color: rgba(255, 255, 255, 0.638);
  font-size: 18px;
}

.navLink {
  font-size: 14px;
  margin-left: 10px;
  font-weight: 500;
  /* color: #eae9e9; */
  /* color: white; */
  color: #f0efef;
}

.circleDivider {
  height: 3px;
  width: 3px;
  background-color: #97b2f5;
  border-radius: 50px;
  margin: 0px 10px;
}

.logoText {
  font-size: 16px;
  color: rgb(224, 232, 244);
  cursor: pointer;
  /* text-transform: uppercase; */
  /* letter-spacing: 1.5px; */
  font-family: "Lexend";
  margin-left: 5px;
  /* position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); */
}

.navbarLogoContent {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 20px;
  padding-bottom: 0px;
  position: relative;
}

.notificationsLength {
  position: absolute;
  left: 115px;
  padding: 2px;
  font-size: 12px;
  background-color: rgb(209, 18, 18);
  color: white;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
}

.logoImage {
  width: 25px;
  height: 25px;
}

.welcomeSection {
  padding: 30px 0;
  height: 100px;
}

.welcomeText {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  padding-bottom: 10px;
  font-size: 20px;
  letter-spacing: -0.3px;
  font-weight: 500;
  text-align: center;
}

.homeMain {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.rightContainer {
  width: calc(100% - 260px); /* Calculate the width of the remaining space */
  position: fixed;
  right: 0;
  margin-left: auto;
  padding: 0px 40px;
  height: 100%;
  overflow-y: scroll;
}

.documentsContainer {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  padding: 50px 0;
  padding-top: 0px;
  padding-bottom: 0px;
}

.findDocumentsForYouContainer {
  /* padding-bottom: 55px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  color: white;
  height: 220px;
  /* width: 100%; */
}

.findDocumentsHeaderText {
  font-size: 21px;
  font-weight: 500;
  letter-spacing: -0.2px;
}

.findDocumentsHeaderDescription {
  margin-top: 7px;
  width: 60%;
  line-height: 1.7rem;
  color: rgb(146, 189, 242);
  text-align: center;
  font-size: 16px;
}

.findDocumentsPopupContainer {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 999999;
  overflow-y: scroll;
  padding: 20vh 0;
  /* padding-bottom: 100px; */
  /* overflow: hidden; */
}

.findDocumentsOverlay {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #15203eb4;
  z-index: 99999999;
  overflow-y: hidden;
}

.findDocumentsPopups {
  /* max-height: 80vh; */
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  /* background-color: blue; */
}

.findDocumentsPopup {
  max-width: 700px;
  background-color: white;
  z-index: 99999999999999;
  border-radius: 20px;
  min-height: 400px;
  box-shadow: 0 20px 25px -5px #15203e5f, 0 8px 10px -6px #15203e2f;
  position: absolute;
  top: 20%;
}

.findDocumentsPopupContent {
  height: 100%;
  width: 100%;
}

.findDocumentsPopupHeader {
  display: flex;
  border-bottom: 1px solid #15203e15;
  padding: 20px;
  padding-bottom: 10px;
}

.findDocumentsIcon {
  font-size: 2px;
  background-color: #15203e10;
  color: #15203e;
  width: 40px;
  height: 35px;
  padding: 7px;
  border-radius: 50px;
}

.findDocumentsHeaderRight {
  margin-left: 15px;
}

.findDocumentsHeaderRightTitle {
  font-size: 16px;
  font-weight: 500;
}

.findDocumentsHeaderRightDescription {
  margin-top: 2px;
  line-height: 1.5em;
  font-size: 14px;
  width: 80%;
  color: #7d828d;
}

.findDocumentsTextContainer {
  /* height: 300px; */
  position: relative;
  /* background-color: blue; */
  border-radius: 50px;
}

.findDocumentsInput {
  margin-top: 10px;
  min-width: 100%;
  max-width: 100%;
  min-height: 240px;
  /* max-height: 240px; */
  font-size: 20px;
  border: none;
  line-height: 1.5em;
  letter-spacing: -0.2px;
  font-weight: 500;
  padding: 5px 20px;
  outline: none;
  color: #121620;
  border-radius: 20px;
  padding-bottom: 40px;

  /* text-align: center; */
  /* white-space: nowrap; */
  /* overflow: hidden; */
}

.findDocumentsInput::placeholder {
  color: #e8e9eb;
}

.findDocumentsButton {
  /* position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translate(-50%, 0); */
  width: fit-content;
  display: flex;
  align-items: center;
  background-color: #191f2f;
  padding: 10px 20px;
  border-radius: 50px;
  margin-left: 20px;
  margin-top: 10px;
  color: white;
  font-weight: 500;
  font-size: 14px;
  /* box-shadow: 0 5px 15px -3px #1252f3bd, 0 0px 6px -4px #1252f312; */
  cursor: pointer;
  z-index: 9999999999999;
  margin-bottom: 20px;
}

.findDocumentsGradient {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 86.56%
  );
  height: 50px;
  width: 100%;
  position: absolute;
  bottom: 50px;
  width: 700px;
  border-radius: 50px;
}

.findDocumentsButtonIcon {
  /* opacity: .7; */
  margin-left: 10px;
  font-size: 18px;
}

.findDocumentsButtonText {
}

.foundDocumentsListContainer {
  padding: 30px 20px;
  border-top: 1px solid #15203e10;
  margin-top: 20px;
}

.foundDocumentsListHeader {
  display: flex;
  align-items: center;
  position: relative;
}

.documentsListHeaderCount {
  position: absolute;
  right: 0;
  color: #121620c9;
  background-color: #12162017;
  padding: 3px 6px;
  font-size: 14px;
  border-radius: 50px;
  font-weight: 500;
}

.documentsListHeaderText {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.2px;
  margin-left: 10px;
}

.foundDocumentsList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin-top: 20px;
}

.reasonText {
  font-size: 12px;
  margin-top: 10px;
  line-height: 1.7em;
  color: #15203ea1;
}

.seeMoreText {
  cursor: pointer;
  color: #191f2fdd;
  text-decoration: underline;
}

/*  */

.findDocumentsInputContainer {
  position: absolute;
  bottom: -20px;
  margin-top: 20px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  display: flex;
  align-items: center;
  height: 55px;
  width: 525px;
  padding: 5px;
  border-radius: 50px;
  background-color: white;
}

.findDocumentInput:focus {
  /* border: 1px solid rgba(3, 22, 60, 0.04); */
}

.findDocumentInput {
  height: 100%;
  width: 100%;
  border: none;
  border-radius: 50px;
  padding: 0px 22px;
  font-family: "Inter";
  outline: none;
  font-size: 14px;
  border: 1px solid white;
}

.findDocumentButton {
  /* padding: 10px; */
  background-color: white;
  border: none;
  font-family: "Inter";
  font-size: 14px;
  border-radius: 50px;
  cursor: pointer;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 110px;
  background-color: #191f2f;
  color: white;
}

.findDocumentIcon {
  margin-right: 7px;
  opacity: 0.5;
}

.reviewAndRewriteContainer {
  display: flex;
  align-items: center;
  background-color: #191f2f;
  padding: 20px 10px;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  /* display: none; */
  margin-top: 5px;
  margin-bottom: 5px;
}

.reviewAndRewriteButton {
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;
  background-color: white;
  padding: 10px 15px;
  border-radius: 50px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  cursor: pointer;
}

.reviewAndRewriteButtonText {
  font-size: 14px;
  font-weight: 500;
  margin-right: 5px;
}

.reviewAndRewriteText {
  font-weight: 600;
  letter-spacing: -0.2px;
  color: #c9ccd4;
  font-size: 15px;
}

.reviewAndRewriteDescription {
  margin-top: 5px;
  font-size: 14px;
  color: #6b6b7b;
}

.reviewAndRewriteIcon {
  font-size: 20px;
  color: #4b5369;
  margin: 0px 10px;
  opacity: 1;
}

.reviewAndRewriteInfo {
  margin-left: 10px;
}

.documentsHeader {
  margin-top: 25px;
  position: relative;
}

.documentsHeaderText {
  font-size: 22px;
  letter-spacing: -0.4px;
  font-weight: 600;
}

.chatWithAIContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.chatPopupContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.savedChats {
  width: 250px;
  border-right: 1px solid #191f2f10;
  max-height: 100vh;
  overflow-y: scroll;
  transition: 0.3s ease-in-out;
}

.savedChats:hover {
  width: 350px;
}

.newChatIcon {
  position: absolute;
  right: 10px;
  top: 19px;
  color: #1252f3;
  font-size: 18px !important;
  cursor: pointer !important;
}

.savedChatsHeader {
  font-size: 14px;
  font-weight: 600;
  padding-left: 7px;
  position: relative;
  display: flex;
  border-bottom: 1px solid #191f2f10;
  padding: 17px 13px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  background-color: #191f2f07;
}

.savedChatsHeaderText {
  width: 120px;
  margin-left: 5px;
}

.savedChatsHeaderIcon {
  color: #1252f3;
  padding-top: 3px;
  font-size: 22px;
  margin-right: 2px;
}

.savedChatsList {
  margin-top: 0px;
  font-size: 13px;
  padding: 0px 13px;
  padding-top: 10px;
  background-color: #191f2f04;
}

.newChat {
  color: #191f2f !important;
  display: flex;
  align-items: center;
  border: 1px solid #191f2f40;
  background-color: #191f2f05 !important;
  border-radius: 10px !important;
}

.newChatIcon {
  font-size: 15px;
}

.eachSavedChatText {
  margin-left: 5px;
}

.emptyChats {
  color: #474c5a;
  padding: 7px;
  background-color: #191f2f07;
  border-radius: 3px;
  text-align: center;
  font-size: 13px;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.eachSavedChat {
  padding: 10px 10px;
  border-radius: 6px;
  margin-bottom: 5px;
  font-weight: 500;
  color: #687083;
  background: transparent;
  cursor: pointer;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis;
  width: 100%;
  font-size: 12px;
}

.eachSavedChat:hover {
  background-color: #191f2f04;
}

.currentEachChat {
  color: #191f2f;
  background-color: #191f2f10;
}

.currentEachChat:hover {
  background-color: #191f2f10;
}

.chatContainer {
  width: 100%;
  height: 100%;
  display: flex;
}

.chatMainContent {
  width: 100%;
  height: 100%;
  background-color: #f0f3f713;
}

.chatMainContent .chatPopupHeaderLeft {
  margin-left: 0;
  padding-left: 0px;
}

.chatHeader {
  display: flex;
  align-items: center;
  backdrop-filter: blur(100.5px);
  width: 100%;
  background-color: white;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.06);
  border-bottom: 1px solid #191f2f10;
}

.chatPopupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1252f3d3;
  /* background-color: #1252f3; */
  z-index: 9999;
}

.AIChatPopupContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  /* max-height: 900px; */
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0px;
  background-color: #1252f31f;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
  /* fallback for old browsers */
  /* background: linear-gradient(to right, #dee5fb, #adc0ef);  */
}

.fullScreenChatPopupContainer {
  background-color: #1252f3;
}

.AIChatSection {
  display: flex;
  height: 100%;
}

.AIChatLeft {
  width: 20%;
  height: 100%;
  background-color: #f2f4f9;
}

.chatMainSection {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 1300px;
  /* height: 90%; */
  background-color: white;
  border-radius: 10px;
  z-index: 9999999999;
  /* border-left: 1px solid #191f2f15; */
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  position: fixed;
  top: 20px;
}

.chatMainSectionHeader {
  border-bottom: 1px solid #191f2f0f;
  backdrop-filter: blur(18.5px);
  /* position: absolute; */
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  /* box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ); */
  backdrop-filter: blur(18.5px);
  -webkit-backdrop-filter: blur(18.5px);
  /* border: 1px solid rgba( 255, 255, 255, 0.18 ); */

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  z-index: 999999;
}

.chatPopupHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* padding: 15px; */
  /* padding-bottom: 15px; */
}

.chatPopupHeaderRight {
  padding-right: 15px;
  height: 100%;
  display: flex;
  align-items: center;
  border-left: 1px solid #191f2f0f;
}

.chatRightIcon {
  padding: 10px;
  width: 40px;
  height: 40px;
  margin-left: 15px;
}

.chatRightIcon:hover {
  background-color: #191f2f10;
  cursor: pointer;
  border-radius: 5px;
}

.chatPopupHeaderLeft {
  display: flex;
  /* justify-content: center; */
  padding: 11px 25px;
  align-items: center;
  height: 100%;
  width: 100%;
}

.chatPopupHeaderIcon {
  font-size: 22px;
  color: #1252f3;
  background-color: #1252f329;
  height: 30px;
  width: 40px;
  padding: 7px;
  border-radius: 10px;
}

.chatHeaderRight {
  margin-left: 20px;
  width: 100%;
  position: relative;
  /* width: 90%; */
}

.chatFeedbackButton {
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  border: 1px solid #191f2f09;
  background-color: #191f2f05;
  padding: 15px;
  border-radius: 10px;
  width: 100% !important;
}

.chatFeedbackText {
  font-size: 15px;
  color: #191f2f75;
  margin-right: 10px;
}

.chatFeedbackButtonIcon {
  color: #191f2f99;
  /* font-size: 14px; */
  margin-right: 5px;
  width: 24px;
  height: 24px;
  padding: 2px;
  border-radius: 5px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
/* 
.chatFeedbackButtonIcon:hover {
  background-color: #191f2f10;
} */

.thumbsUp:hover {
  background-color: #1252f310;
  color: #1252f3;
}

.thumbsDown:hover {
  background-color: rgba(255, 166, 0, 0.15);
  color: orange;
}

.chatPopupHeaderLeftText {
  font-size: 16px;
  padding: 8px 0px;
  font-weight: 500;
  /* margin-top: 3px; */
}

.chatHeaderLeftDescription {
  font-size: 12px;
  line-height: 1.4em;
  opacity: 0.6;
  margin-top: 2px;
}

.chatContext {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  align-self: flex-end;
  margin-left: auto;
  max-width: 320px;
  background-color: #191f2f12;
  /* border: 1px solid #191f2f09; */
  border-radius: 10px;
  padding: 10px 5px;
}

.chatContextIcon {
  min-width: 28px;
  height: 20px;
  padding: 1px;
  font-size: 18px;
  color: #12162044;
}

.chatContextCloseIcon {
  min-width: 20px;
  font-size: 12px;
  padding: 2px;
  color: #12162097;
  background-color: #12162012;
  height: 20px;
  border-radius: 5px;
  margin-left: 5px;
  cursor: pointer;
}

.chatContextText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  font-weight: 500;
  margin-right: 2px;
}

.loadingChatList {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.loadingChatListText {
  margin-top: 5px;
  font-size: 14px;
  color: #3d455c;
}

.chatContainerChatLists {
  display: flex;
  flex-direction: column;
  /* align-self: flex-end; */
  justify-content: flex-start;
  width: 100%;
  margin: 0px auto;
  padding: 20px 0px;
  /* padding-bottom: 30px; */
  overflow-y: scroll;
  height: calc(100% - 230px);
  /* padding-top: 100px; */
  position: relative;
  padding-bottom: 50px;
}

.processEachChatContainer {
  width: 100%;
}

.chatContainerChatLists .chatContainerChats {
  display: flex;
  flex-direction: column;
  /* align-self: flex-end; */
  justify-content: flex-start;
  width: 100%;
  margin: 0px auto;
  padding: 10px 30px;
  padding-bottom: 30px;
  overflow-y: scroll;
  height: calc(100% - 190px);
  padding-top: 100px;
  position: relative;
}

.chatSectionChats {
  display: flex;
  flex-direction: column;
  /* align-self: flex-end; */
  justify-content: flex-start;
  width: 100%;
  margin: 0px auto;
  padding: 10px 30px;
  padding-bottom: 30px;
  overflow-y: scroll;
  height: 100%;
  padding-top: 100px;
  background-color: #00000005;
  position: relative;
  /* margin-top: 0px; */
  /* max-height: 600px; */
}

.processEachChatContainer {
  padding: 20px 0px;
}

.eachChatContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.documentSuggestionsHeaderText {
  font-size: 12px;
  color: #191f2f86;
  font-weight: 500;
  margin-bottom: 5px;
}

.chatDocumentSuggestions {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  /* background-color: blue; */
  max-width: 600px;
  width: fit-content;
  /* background-color: #191f2f0b; */
  /* padding: 10px; */
  border-radius: 10px;
  /* margin-bottom: 10px; */
}

.chatDocumentSuggestionsList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
}

.eachDocumentSuggestion {
  transform: scale(1);
  width: 100%;
  transition: 0.3s ease-in-out;
}

.eachChat {
  background-color: white;
  /* box-shadow: 0 5px 15px -3px rgb(0 0 0 / 0.03),
    0 4px 6px -4px rgb(0 0 0 / 0.03); */
  width: fit-content;
  padding: 18px 200px;
  line-height: 1.6em;
  font-size: 15px;
  /* border-top: 1px solid #191f2f09; */
  /* border-bottom: 1px solid #191f2f09; */
  /* border-radius: 10px; */
  width: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  /* margin-bottom: 5px; */
}

.typingLoadingGif {
  width: 10%;
  width: 50px;
  margin-top: 2px;
  /* border-radius: 100px; */
}

.eachChatType {
  font-size: 11px;
  color: #47567e7e;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 2px;
}

.assistantChatType {
  color: #1252f3;
  font-weight: 500;
  font-size: 11px;
}

.fullScreenEachChat {
  /* max-width: 800px; */
}

.processEachChat {
  padding: 20px 100px !important;
}

.eachChatText {
  white-space: pre-line;
}

.loadingChat {
  animation: fadeIn 0.5s ease-in-out;
}

.updatingDocumentLoader {
  display: flex;

  /* align-items: center; */
  /* justify-content: space-between; */
}

.updatingDocumentLoaderLeftIcon {
  font-size: 20px;
  color: #1252f3;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  background-color: #1252f310;
  padding: 7px;
  border-radius: 10px;
  margin-right: 12px;
}

.updatingDocumentLoaderContent {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
}

.getStartedFinalDocumentChat {
  height: 540px;
  background-color: #7e8aaa10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.getStartedFinalDocumentChatContainer {
  width: 480px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.getStartedFinalDocumentChatHeaderIcon {
  font-size: 40px;
  display: flex;
  text-align: center;
  color: #1252f3;
  background-color: #1252f310;
  border-radius: 50px;
  padding: 5px;
  animation: waveAnimation 0.7s ease-in-out;
  rotate: -7deg;
}

.getStartedFinalDocumentChatHeaderIcon:focus {
  animation: waveAnimation 1s ease-in-out;
  /* animation-duration: 4s; */
}
/* 
#getStartedFinalDocumentChatHeaderIcon:not(:hover) {
  animation-play-state: paused;
} */

@keyframes waveAnimation {
  0% {
    rotate: -30deg;
    /* transform: scale(1.1); */
  }
  50% {
    rotate: 30deg;
    /* transform: scale(1.2); */
  }
  100% {
    rotate: 0deg;
    /* transform: scale(1); */
  }
}

.getStartedFinalDocumentChatHeaderText {
  font-size: 24px;
  font-weight: 600;
  color: #21242f;
  text-align: center;
  margin-top: 5px;
}

.getStartedFinalDocumentChatDescription {
  font-size: 14px;
  color: #44495a;
  text-align: center;
  margin-top: 5px;
}

.getStartedFinalDocumentChatHeader {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  /* border-bottom: 1px solid #191f2f11;
  padding-bottom: 10px; */
  margin-top: 10px;
}

.getStartedFinalDocumentSection {
  margin-top: 30px;
  width: 520px;
}

.getStartedFinalDocumentChatSectionText:nth-child(1) {
  font-weight: 600;
  color: #353a45;
  background-color: transparent;
  padding: 0px;
}

.getStartedFinalDocumentChatSectionText:nth-child(1):hover {
  background: transparent;
  cursor: default;
}

.getStartedFinalDocumentChatSectionText {
  color: #4b5162;
  font-size: 15px;
  margin-bottom: 10px;
  line-height: 1.4em;
  padding: 4px 6px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.getStartedFinalDocumentChatSectionText:focus {
  background-color: #191f2f10;
}

.getStartedFinalDocumentChatSectionTextIcon {
  background-color: white;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 0.07);
  border-radius: 50px;
  padding: 1px;
  font-size: 20px;
  font-weight: 600;
  margin-right: 9px;
}

.getStartedFinalDocumentChatSectionText:hover {
  background-color: #191f2f09;
}

.updatingDocumentLoader {
  display: flex;
  align-items: center;
  padding: 9px 15px;
  border: 1px solid #1252f314;
  border-radius: 12px;
  position: relative;
  background-color: #1252f303;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.04);
  /* margin-top: -12px; */
  width: 100%;
  animation: fadeIn 0.4s ease-in-out;
}

.updatingDocumentLoaderIcon {
  position: absolute;
  right: 15px;
}

.updatingDocumentLoaderHeader {
  font-weight: 600;
  font-size: 14px;
  margin: 0px;
  padding: 0px;
  margin-top: 2px;
}

.updatingDocumentLoaderLeftIcon {
}

.updatingDocumentLoaderText {
  color: #6c7487;
  font-size: 13px;
  /* margin-top: -2px; */
  padding: 0px;
  width: 400px;
  line-height: 1.5em;
}

.leftChat {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  justify-self: flex-start;
  /* font-weight: 500; */
  width: 100%;
  border-top: 1px solid #191f2f10;
  border-bottom: 1px solid #191f2f10;
  /* font-weight: 500; */
  font-size: 14px;
  background-color: white;
}

.rightChat {
  display: flex;
  flex-direction: column;
  color: #191f2f;
  align-self: flex-end;
  justify-self: flex-end;
  color: #121620;
  background-color: #191f2f05;
  padding: 14px 200px;
  /* color: white; */
  font-weight: 400;
  font-size: 14px;
  /* margin-bottom: 20px; */
}

.chatMainInputContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  /* border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px; */
  border-radius: 15px;
  border: 1px solid #191f2f15;
  box-shadow: 0 4px 10px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -2px rgba(0, 0, 0, 0.1);
  width: 80%;
  margin: 0 auto;
  margin-bottom: 0px;
  padding: 10px 12px;
  padding-bottom: 0px;
  padding-top: 5px;
  /* border: 1px solid transparent; */
  position: relative;
  /* position: fixed; */
  /* bottom: 0; */
}

.chatMainInputContainer:focus {
  /* outline: 2px solid #191f2f20; */
}

.chatMainInputContainer .chatInputButton {
  margin-bottom: 10px;
  position: absolute;
  right: 10px;
  bottom: 0px;
}

.chatMainInputContainer .chatInput {
  font-size: 14px !important;
  height: 0px !important;
  min-height: 100px !important;
  width: 90%;
}

.chatMainInputContainer .chatInput:focus {
  border: none;
}

.chatInputSection {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  /* border: 1px solid #191f2f19; */
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.45),
    0 2px 4px -2px rgba(0, 0, 0, 0.567);
  width: 100%;
  margin: 0 auto;
  margin-bottom: 0px;
  border-top: 1px solid #191f2f26;
}

.activeChatInputSection {
  outline: 2px solid #191f2f10;
  box-shadow: 0 -5px 10px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.chatExamples {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0px 15px;
  padding-top: 40px;
  opacity: 0.6;
  /* border-top: 1px solid #12162010; */
  /* border-bottom: 1px solid #12162010; */
  /* background-color: #12162003; */
  /* overflow: auto; */
  /* white-space: nowrap; */
  /* display: grid; */
}

.chatExamplesHeader {
  display: flex;
  align-self: flex-start;
  width: 500px;
  margin: 0 auto;
  margin-bottom: 10px;
  font-size: 16px;
  color: #7b87a5;
  letter-spacing: -0.02em;
  font-weight: 500;
}

.chatExamplesHeaderIcon {
  margin-right: 10px;
}

.chatExampleText {
  font-size: 12px;
  /* background-color: #12162005; */
  color: #121620b6;
  border-bottom: 1px solid #12162018;
  margin-right: 10px;
  width: 500px;
  cursor: pointer;
  padding: 10px 13px;
  padding-left: 0px;
  margin-bottom: 10px;
  /* text-align: center; */
  /* width: 100%; */
  transition: 0.1s ease-in-out;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.chatExampleTextContent {
  flex-grow: 1;
  font-weight: 500;
  width: 100%;
  line-height: 1.4em;
}

.chatExampleIcon {
  /* position: absolute; */
  /* right: 20px; */
  /* opacity: 0; */
  font-size: 15px;
  transition: 0.3s ease-in-out;
}

.chatSuggestions {
  display: flex;
  align-items: center;
  padding: 0px 15px;
  padding-top: 5px;
  border-top: 1px solid #12162010;
  border-bottom: 1px solid #12162010;
  background-color: #12162003;
  overflow: auto;
  white-space: nowrap;
}

.processChatSuggestions {
  padding-bottom: 0px;
}

.processChatSuggestions .chatExampleText {
  font-size: 13px !important;
  margin-bottom: 7px !important;
}

.chatSuggestions .chatExampleText {
  padding: 7px 10px;
  font-size: 14px;
  background-color: #12162005;
  color: #121620b6;
  border: 1px solid #12162018;
  border-radius: 10px;
  margin-right: 10px;
  cursor: pointer;
  width: fit-content;
  /* width: 100%; */
}

.chatExampleText:hover {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #12162028;
}

.chatExampleText:hover .chatExampleIcon {
  opacity: 1;
  transform: translateX(5px);
}

.chatInputContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  padding-top: 14px;
}

.mainChatInputContainer .chatSuggestions {
  width: 100% !important;
  font-size: 12px !important;
  border: none !important;
  padding-left: 3px;
}

.mainChatInputContainer .chatSuggestions .chatExampleText {
  font-size: 13px !important;
}

.mainChatInputContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  /* padding-top: 14px; */
  /* border: 1px solid #191f2f20; */
  /* padding: 15px; */
  /* outline: none !important; */
  position: fixed;
  bottom: 10px;
  margin-left: auto;
  width: calc(100% - 570px); /* Calculate the width of the remaining space */
}

.chatInput {
  width: 100%;
  min-width: 93%;
  padding: 0px 5px;
  border-radius: 10px;
  border: none;
  font-size: 15px;
  font-weight: 500;
  outline: none;
  line-height: 1.6em;
  min-height: 50px;
  height: 50px;
  max-height: 120px;
  padding-top: 7px;
  resize: none;
  overflow: scroll;
  min-height: 50px;
  max-height: 100px;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  resize: vertical;
  text-overflow: clip;
}

.chatInput:focus {
}

.chatInput::placeholder {
  color: #bcc0cbd4;
}

.chatInputButton {
  padding: 15px;
  border-radius: 10px;
  background-color: #1252f3;
  border: none;
  margin-left: 10px;
  color: white;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  align-self: flex-end;
  /* box-shadow: 0 10px 25px -5px rgba(11, 70, 244, 0.4), 0 8px 10px -6px rgba(0, 94, 255, 0.9); */
}

.chatInputButtonIcon {
  font-size: 18px;
}

.chatWithMiraAiOverlay {
  position: fixed;
  bottom: 30px;
  right: 35px;
}

.chatWithMiraAiButton {
  display: flex;
  align-items: center;
  background-color: white;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  padding: 8px 14px;
  border-radius: 50px;
  border: 1px solid rgb(230, 230, 230);
  color: #191f2f;
  cursor: pointer;
}

.chatWithMiraAiIcon {
  margin-right: 10px;
  color: #1253f3;
}

.chatWithMiraAiText {
  font-size: 15px;
  letter-spacing: -0.3px;
  font-weight: 500;
}

.documents {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.documentsLoadingContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 300px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.documentsLoadingText {
  margin-top: 15px;
  color: #2f3546;
}

.eachDocumentList {
  margin-bottom: 40px;
}

.documentsList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 20px;
  margin-top: 10px;
}

.eachDocumentListHeader {
  /* margin-top: 10px; */
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.eachDocumentListHeaderIcon {
  font-size: 18px;
  margin-right: 5px;
  color: #4b5369;
}

.eachDocumentListHeaderText {
  letter-spacing: -0.2px;
  font-size: 14px;
  font-weight: 500;
  /* margin-left: 10px; */
}

.eachDocument {
  background: transparent;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  border-radius: 10px;
  border: 1px solid rgb(227, 227, 228);
  cursor: pointer;
}

.smallDocumentPreview {
  transition: 0.3s ease-in-out;
}

.smallDocumentPreview > .eachDocumentPreview {
  height: 80px;
}

.smallDocumentPreview > .eachDocumentInfo {
  padding: 10px;
}

.smallDocumentPreview > .eachDocumentInfo > .eachDocumentName {
  font-size: 13px;
}

.smallDocumentPreview .eachDocumentImageName {
  font-size: 7px;
}

.eachDocumentPreview {
  height: 150px;
  background-color: rgb(249, 250, 250);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.eachDocumentImage {
  width: 70%;
  height: 87%;
  position: absolute;
  bottom: 0;
  margin: 0 auto;
  background-color: white;
  overflow: hidden;
  box-shadow: 0 -2px 4px -1px rgb(0 0 0 / 0.04), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  /* background: linear-gradient(
    47deg,
    rgba(255, 255, 255, 0) 70%,
    rgba(24, 75, 206, 0.1) 100%
  ); */
  border: 1px solid #191f2f10;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 5px;
  user-select: none;
}

.eachDocumentImageName {
  font-size: 8px;
  /* border: 1px solid #191f2f09; */
  /* padding-bottom: 5px; */
  font-weight: 600;
  line-height: 1.3em;
  color: #191f2f7c;
  letter-spacing: -0.2px;
  /* margin-bottom: 10px; */
  border-radius: 5px;
  /* width: 100%; */
  /* height: 100%; */
  display: flex;
  padding: 10px;
  line-height: 1.6em;
  border-radius: 10px;
}

.eachDocumentImageSkeletonContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 0px 10px;
}

.eachDocumentImageSkeleton {
  width: 100%;
  height: 3px;
  background-color: #26355b10;
  border-radius: 5px;
  margin-bottom: 5px;
}

.eachDocumentInfo {
  border-top: 1px solid rgb(227, 227, 228);
  background-color: white;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
}

.documentDetailButton {
  position: relative;
  z-index: 999;
}

.detailDropdown {
  position: absolute;
  top: 25px;
  left: -180px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  background-color: white;
  width: 200px;
  border: 1px solid rgba(227, 227, 228, 0.623);
  padding: 5px;
  border-radius: 7px;
}

.eachDetailDropdown {
  padding: 8px 10px;
  border-radius: 5px;
  color: rgb(68, 68, 68);
}

.eachDetailDropdown:hover {
  background-color: rgba(240, 240, 240, 0.659);
}

.eachDropdownIcon {
  font-size: 16px;
}

.eachDropdownName {
  font-size: 13.5px;
  margin-left: 15px;
  font-weight: 500;
}

.deleteDropdown {
  color: rgb(253, 64, 64);
}

.deleteDropdown:hover {
  background-color: rgba(255, 0, 0, 0.077);
}

.eachDetailDropdown {
  display: flex;
  align-items: center;
}

.documentDetailIcon {
  font-size: 20px;
  opacity: 0.7;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
}

.documentDetailIcon:hover {
  background-color: rgb(228, 228, 230);
}

.deleteDocumentModalContainer {
  /*  */
}

.deleteDocumentModal .ButtonMain {
  background-color: red !important;
}

.deleteDocumentText {
  line-height: 1.6em;
  font-size: 15px;
  opacity: 0.8;
}

.deleteButton {
  background-color: red !important;
  color: white !important;
  margin-left: 20px;
}

.eachDocumentName {
  font-size: 14px;
  /* font-weight: 500; */
  font-weight: 500;
  color: #191f2f;
  line-height: 1.2rem;
  /* margin-top: 5px; */
}

.createDocumentDetail {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  /* overflow-y: scroll; */
  /* padding-bottom: 60px; */
  position: relative;
}

.documentLoadingContainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
}

.scrollGradientContainer {
  position: fixed;
  left: calc(100% - 52%);
  top: 44.5%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* animation: bouncingAnimation 1s ease-in-out infinite; */
  background-color: #1252f3;
  height: 30px;
  width: 30px;
  border-radius: 50px;
  color: white;
  transition: 0.5s ease-in-out;
  z-index: 9999;
}

.scrollGradient {
  background: linear-gradient(to top, #f0f3fa, transparent);
  /* background-color: blue; */
  height: 50px;
  width: 100%;
  position: fixed;
  left: calc(100% - 72%);
  top: 43.5%;
}

.scrollMoreTextIcon {
  font-size: 18px;
}

.scrollMoreText {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.updateClauseChats {
  /*  */
}

.updateClauseChatsContainer {
  padding: 10px 0px;
  padding-top: 30px;
  padding-bottom: 0px;
  max-height: 500px;
  overflow-y: scroll;
}

.updateClauseEachChatIcon {
  color: #626b86;
}

.updateClauseEachChatMain {
  display: flex;
  flex-direction: column;
}

.updateClauseEachChat {
  display: flex;
  margin-bottom: 20px;
  animation: fadeIn 0.4s ease-in-out;
}

.updateClauseEachChat:last-child {
  margin-bottom: 5px !important;
}

.updateClauseEachChatMain {
  margin-left: 10px;
}

.updateClauseEachChatType {
  font-size: 16px;
  letter-spacing: -0.3px;
  color: #191f2f;
  font-weight: 600;
  margin-bottom: 3px;
}

.assistantChat {
  position: relative;
}

.updateClauseLoadingIcon {
  position: absolute;
  top: 0;
  right: 0;
}

.assistantChat .imageIcon {
  /*  */
}

.updateClauseEachChatText {
  line-height: 1.5em;
  color: #191f2f;
  font-size: 16px;
}

.updateClauseEmptyChats {
  background-color: #e9ebf3;
  display: flex;
  padding: 15px 20px;
  font-size: 15.5px;
  font-weight: 500;
  color: #5d6375;
  line-height: 1.5em;
  border-radius: 10px;
}

.updateClauseEmptyChatsDecision {
  display: flex;
  flex-direction: column;
}

.updateClauseEmptyChatsIcon {
  font-size: 22px;
  margin-right: 15px;
}

.updateClauseModal {
  width: 650px !important;
  /* height: 90%; */
  justify-content: space-between !important;
  border-radius: 20px !important;
}

.updateClauseModalContent {
  height: 100%;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.updateClauseSuccess {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: white;
  z-index: 9999;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: fadeAnimation 0.7s ease-in-out;
}

@keyframes fadeAnimation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.updateClauseSuccessIcon {
  width: 60px;
  height: 60px;
  background-color: #008000;
  color: white;
  padding: 13px;
  border-radius: 50px;
}

.updateClauseText {
  margin-top: 15px;
  font-weight: 500;
  font-size: 16px;
}

.updateClauseInputContainer {
  display: flex;
  flex-direction: column;
  margin-top: 25px !important;
  border-top: 1px solid #191f2f15;
  padding-top: 20px;
  /* align-items: center; */
}

.updateClauseButton {
  background-color: #1252f3;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.14),
    rgba(92, 92, 92, 0.1)
  );
  padding: 15px;
  justify-content: center;
  text-align: center;
  border-radius: 8px;
  color: white;
  font-weight: 500;
  /* margin-left: 10px; */
  font-size: 15px;
  /* margin-top: -7px; */
  display: flex;
  align-items: center;
  cursor: pointer;
}

.updateClauseButtonIcon {
  font-size: 16px;
}

.updateClauseButton p {
  margin-right: 3px;
}

.updateClauseInput {
  width: 100%;
  padding: 15px;
  font-size: 16px !important;
}

.updateClauseModal .modalMainContentButton {
  /* width: 100%; */
  padding: 22px 10px;
  width: 100%;
  border-radius: 15px;
  font-size: 14px !important;
  background-color: #171d2e !important;
  border: 1px solid #191f2f !important;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.14),
    rgba(92, 92, 92, 0.1)
  );
}

.updateClauseModal
  > .modalContentHeader
  > .modalContentHeaderTop
  > .modalContentHeaderLeft
  > .modalContentHeaderIcon {
  background: none;
  color: #1253f3;
}

.updateClauseModal .modalContentHeaderDescription {
  width: 100%;
  margin-bottom: 5px;
}

.updateClauseModal .modalContentHeader {
  padding-bottom: 15px;
}

.explainBusinessModal {
  width: 540px !important;
  max-height: 90%;
  justify-content: space-between !important;
  border-radius: 20px !important;
}

.explainBusinessModal .modalContentHeaderTextContent {
  margin-left: 10px;
}

.explainBusinessModal .modalMainContentButton {
  /* width: 100%; */
  padding: 22px 10px;
  width: 100%;
  border-radius: 15px;
  font-size: 14px !important;
  background-color: #171d2e !important;
  border: 1px solid #191f2f !important;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.14),
    rgba(92, 92, 92, 0.1)
  );
}

.explainBusinessModalHeader {
  justify-content: unset !important;
}

.explainBusinessModalHeader .modalContentHeaderCloseIcon {
  position: absolute;
  right: 5px;
  color: #545c70;
}

.explainBusinessModalHeader .modalContentHeaderDescription {
  width: 100%;
  margin-bottom: 5px;
}

.explainBusinessModalHeader .modalContentHeaderText {
  font-size: 17px;
}

.explainBusinessModal > .modalMainContent {
  /* max-height: 400px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}

.explainBusinessModal .modalContentHeaderIcon {
  font-size: 18px;
  margin-top: 3px;
  margin-right: 5px;
}

.explainBusinessModal > .modalContentHeader {
  /* border-bottom: none; */
  /* padding-bottom: 0px; */
  padding-bottom: 10px;
  margin-bottom: 0px;
}

.explainBusinessModal
  > .modalContentHeader
  > .modalContentHeaderTop
  > .modalContentHeaderLeft
  > .modalContentHeaderIcon {
  background: none;
  color: #1253f3;
}

.modalContentHeaderDescription {
  margin-top: 5px;
  line-height: 1.5em;
  font-size: 14px;
  width: 90%;
  color: #7d828d;
}

.modalExplainContent {
  /* padding: 20px; */
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 20px;
}

.contextInputsContainer {
  margin-top: 5px;
  padding: 0px 0;
}

.contextInputContainer {
  width: 100%;
  padding: 15px 25px;
  padding-bottom: 5px;
}

.contextInput {
  width: 100%;
  margin-top: 5px;
  padding: 12px 10px;
  font-family: "Inter";
  border-radius: 5px;
  border: 1px solid #191f2f39;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 170px;
  font-size: 14px;
  background-color: #191f2f02;
}

.contextInputsContainer textarea {
  min-height: 77px;
  line-height: 1.5em;
}

.contextInput:focus {
  outline: 3px solid #191f2f14;
}

.contextInput::placeholder {
  color: #191f2f4b;
}

.contextInputLabel {
  font-size: 14px;
  font-weight: 500;
  color: #191f2fb1;
}

.contextInputError {
  margin-left: 25px;
  display: flex;
  align-items: center;
  margin-bottom: 0px !important;
  margin-top: 5px !important;
  color: rgb(242, 47, 47) !important;
}

.contextInputErrorIcon {
  background-color: rgb(242, 47, 47);
  color: white;
  width: 17px;
  height: 17px;
  border-radius: 50px;
  padding: 2px;
  outline: 2px solid rgb(248, 73, 73, 0.6);
  margin-right: 7px;
}

.importantMistakeSuggestion {
  background-color: rgba(255, 166, 0, 0.15) !important;
  border: 1px solid orange !important;
}

.importantMistakeSuggestion .answerMistakeSuggestionIcon {
  color: orange;
  outline: 1px solid rgba(255, 166, 0, 0.3);
}

.answerMistakeSuggestionLoading {
  background-color: #191f2f05;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 15px;
  position: relative;
  animation: answerSuggestionLoadingPulse 0.4s infinite;
  border: 1px solid #191f2f40;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.1),
    rgba(92, 92, 92, 0.1)
  );
}

@keyframes answerSuggestionLoadingPulse {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 0.5;
  }
}

.answerMistakeLoadingIcon {
  position: absolute;
  right: 20px;
  top: 20px;
}

.mistakeLoadingContentHeader {
  font-weight: 600;
}

.mistakeLoadingContentDescription {
  margin-top: 5px;
  line-height: 1.6em;
  color: #262d41;
}

.answerMistakeSuggestion {
  display: flex;
  flex-direction: column;
  background-color: #1252f308;
  border: 1px solid #1252f3;
  border-radius: 10px;
  margin-bottom: 20px;
  /* background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.08),
    rgba(92, 92, 92, 0.05)
  ); */
}

.answerMistakeSuggestionLeft {
  padding: 15px;
  padding-top: 5px;
  padding-bottom: 0px;
  border-right: 1px solid #191f2f16;
  display: flex;
  align-items: center;
}

.answerMistakeSuggestionIcon {
  color: #1252f3;
  font-size: 21px;
  margin-left: 4px;
  outline: 1px solid #1252f330;
  border-radius: 50px;
}

.answerMistakeSuggestionRight {
  padding: 15px 20px;
  padding-top: 0px;
}

.answerMistakeSuggestionTextHeader {
  font-size: 16px;
  margin-bottom: 12px;
  margin-top: 10px;
  padding-top: 4px;
  font-weight: 600;
  color: #091a44;
  margin-left: 10px;
  /* border-top: 1px solid #191f2f10; */
  /* padding-top: 20px; */
}

.answerMistakeSuggestionText {
  line-height: 1.6em;
  color: #353c4f;
  font-size: 16px;
}

.importantMistakeSuggestion .answerMistakeSuggestionButton {
  display: flex;
  align-items: center;
  margin-top: 12px;
  font-size: 14px;
  font-weight: 500;
  color: #252b3b;
  margin-bottom: 0px;
  width: fit-content;
  border-radius: 5px;
  cursor: pointer;
  padding: 7px 10px;
  background-color: rgba(255, 166, 0, 0.25);
}

.importantMistakeSuggestion .answerMistakeSuggestionButton:hover {
  background-color: rgba(255, 166, 0, 0.3);
}

.importantMistakeSuggestion .answerMistakeSuggestionButtonIcon {
  margin-right: 5px;
  font-size: 20px;
  color: orange;
}

.answerMistakeSuggestionButton {
  display: flex;
  align-items: center;
  margin-top: 12px;
  font-size: 14px;
  font-weight: 500;
  color: #1252f3;
  margin-bottom: 0px;
  width: fit-content;
  border-radius: 5px;
  cursor: pointer;
  padding: 7px 10px;
  background-color: #1252f319;
}

.answerMistakeSuggestionButton:hover {
  /* background-color: rgba(255, 166, 0, 0.3); */
}

.answerMistakeSuggestionButtonIcon {
  margin-right: 5px;
  font-size: 20px;
  color: #1252f3;
}

.addMoreDetailsContainer {
  display: flex;
  align-items: center;
  margin: 0px 25px;
  color: #1252f3;
  font-weight: 500;
  margin-top: 10px;
  border-top: 1px solid #191f2f09;
  padding-top: 15px;
  cursor: pointer;
  padding-bottom: 5px;
  position: relative;
  width: 100%;
}

.addIcon {
  font-size: 19px;
  background-color: #1252f31b;
  border-radius: 50px;
  padding: 1px;
  margin-right: 7px;
}

.addMoreDetailsContainer > .toolTipText {
  font-size: 12px;
  color: #191f2f;
  text-decoration: underline;
  margin-left: 10px;
  /* position: absolute; */
  /* right: 0px; */
  color: #1252f3bc;
  cursor: help;
}

.addMoreDetailsContainer > .signupFlowQuestionToolTipIcon {
  margin-left: 3px;
  margin-top: 4px;
  color: black;
}

.extraInfoInputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  width: 90%;
}

.addMoreDetailsCloseIcon {
  position: absolute;
  top: 15px;
  right: 0px;
  background-color: #191f2f12;
  border-radius: 50px;
  font-size: 16px;
  padding: 1px;
}

.extraInfoInputContainer .contextInput {
  max-width: 100%;
}

.addMoreDetailsText {
  margin-left: 0px;
  font-size: 15px;
}

.modalExplainInputContainer {
  margin-bottom: 20px;
}

.modalExplainInputContainer > .inputLabel {
  font-size: 13px;
  color: #121620d0;
}

.modalExplainInputContainer > .createDocumentInput {
  font-size: 13px;
}

.explainBusinessModal > .modalButtonContainer > .modalMainContentButton {
  background-color: #191f2f;
  min-width: 180px;
}

.explainBusinessModal > .modalButtonContainer > .modalMainContentButton {
  font-size: 13px;
}

.explainBusinessModal > .modalButtonContainer {
  background: none;
  /* border: none; */
  /* padding-top: 0px; */
}

.modalMainButtonArrowIcon {
  font-size: 16px;
  margin-left: 10px;
  opacity: 0.7;
}

.whatToInclude {
  display: flex;
  margin: 0px 20px;
  margin-bottom: 20px;
  border-top: 1px solid #12162012;
  padding-top: 15px;
  /* background-color: #12162005; */
}

.whatToIncludeIcon {
  width: 25px;
  height: 25px;
  padding: 3px;
  color: #191f2f;
  background-color: #12162010;
  border-radius: 5px;
  cursor: pointer;
  transform: rotate(-90deg);
}

.whatToIncludeIconOpen {
  transform: rotate(0deg);
}

.whatToIncludeIcon:hover {
  background-color: #1216201b;
}

.whatToIncludeContent {
  margin-left: 15px;
}

.whatToIncludeContentTitle {
  font-size: 15px;
  font-weight: 500;
  /* letter-spacing: -0.1px; */
  color: #191f2f;
  margin-bottom: 7px;
  margin-top: 3px;
  cursor: pointer;
}

.whatToIncludeContentPoints {
  margin-top: 5px;
}

.whatToIncludeContentEachPoint {
  margin-bottom: 7px;
  line-height: 1.5em;
  color: #51586d;
  width: 100%;
  font-size: 15px;
  /* text-indent: -5px; */
}

.whatToIncludeContentEachPoint {
}
.whatToIncludeContentEachPoint:before {
  /* content: "- "; */
  /* text-indent: -5px; */
}

/* .modalExplainInputContainer > crea */

.createDocumentDetailLeft {
  width: 43%;
  background-color: #1253f3;
  height: 100%;
  padding: 20px;
  position: fixed;
  left: 0;
  top: 0;
  /* position: relative; */
  overflow-y: hidden;
}

.documentDetailBackIcon {
  margin-top: 20px;
  font-size: 18px;
  color: #7092e7;
  margin-left: 30px;
  cursor: pointer;
}

.createDocumentDetailSkeleton {
  width: 38%;
  position: fixed;
  bottom: -10%;
  transform: translate(80%, 0);
  left: -28%;
  opacity: 0.7;
}

.createDocumentRight {
  width: 680px;
  min-height: 100%;
  /* background-color: blue; */
  margin: 0 auto;
  padding: 50px;
  padding-left: 30px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.createDocumentDetailRight {
  width: 55%;
  /* max-width: 900px; */
  /* background-color: blue; */
  height: 100%;
  margin-left: auto;
  padding: 50px;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.generalInputLogoName {
  font-size: 12px;
  font-weight: 500;
  background-color: #1252f3;
  padding: 4px 10px;
  border-radius: 15px;
  width: fit-content;
  color: white;
  border-top-left-radius: 3px;
}

.generalInputLogoIcon {
  position: absolute;
  left: -40px;
  top: 0px;
  width: 30px;
  height: 30px;
  border-radius: 50px;
}

.documentDetailHeaderText {
  font-size: 24px;
  font-weight: 600 !important;
  letter-spacing: -0.2px;
  margin-top: 8px;
}

.documentDetailHeaderDescription {
  margin-top: 25px;
  font-size: 16px;
  line-height: 1.7rem;
  color: #444a5c;
}

.documentDetailBenefits {
  margin: 40px 0;
}

.documentDetailBenefitsHeaderText {
  font-size: 16px;
  font-weight: 600;
  color: #191f2f;
  letter-spacing: -0.2px;
}

.documentBenefitsList {
  margin-top: 10px;
}

.documentBenefit {
  /*  */
}

.documentBenefitHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.documentBenefitTitle {
  color: #50586b;
  font-size: 16px;
  letter-spacing: -0.02px;
  padding: 8px 0;
}

.expandedBenefitTitle {
  opacity: 0.7;
}

.documentBenefitExpandIcon {
  color: #444a5c;
}

.expandedBenefitIcon {
  transform: rotate(180deg);
}

.documentMoreInfo {
  color: #444a5c;
  font-size: 14px;
  line-height: 1.7rem;
  border-bottom: 1px solid #0000000d;
  padding-top: 5px;
  padding-bottom: 15px;
  margin-bottom: 10px;
}

.documentDetailTip {
  display: flex;
  align-items: center;
  background-color: #15203e0a;
  padding: 18px 20px;
  border-radius: 5px;
  margin-bottom: 60px;
}

.documentTipIcon {
  font-size: 18px;
  width: 70px;
}

.documentDetailTipText {
  font-size: 16px;
  line-height: 1.6em;
  color: #484d5e;
}

.buyDocumentModalButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  position: relative;
  padding-bottom: 25px;
}

.refundableText {
  position: absolute;
  bottom: -13px;
  margin-left: 3px;
  font-size: 14px;
  color: #191f2fa2;
  display: flex;
  align-items: center;
}

.refundableTextIcon {
  color: #008000;
  font-size: 16px;
  margin-right: 5px;
}

.refundableTextMain {
  color: #008000;
}

.secondaryUpgradeButton {
  margin-left: 0px;
  font-size: 14px;
  color: #191f2f95;
  padding: 5px 18px;
  width: 100%;
  justify-content: center;
  background-color: #191f2f09;
  margin-top: 5px;
  border-radius: 10px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  margin-left: 5px;
  display: flex;
  align-items: center;
}

.secondaryUpgradeButton:hover {
  background-color: #191f2f12;
}

.upgradeToProButtonLabel {
  opacity: 0.7;
}

.buyDocumentUsersSection {
  display: flex;
  align-items: center;
  user-select: none;
  margin-top: 20px;
  margin-left: 7px;
  /* border-top: 1px solid #191f2f10; */
  /* padding-top: 20px; */
}

.buyDocumentUsersSection .appOnboardingSuccessDescription {
  color: #737886;
}

.buyDocumentUsersAvatars {
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.buyDocumentEachUserAvatar {
  border-radius: 50px;
  background-color: #f5f4fb;
  border: 1px solid #7562f6;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1252f3;
  font-size: 16px;
  padding: 2px 5px;
  font-weight: 600;
  margin-left: -7px;
}

.buyDocumentUsersSection .appOnboardingSuccessDescription {
  font-size: 14px;
}

.upgradePlanOverlay {
  /* position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 600px; */
  background-color: white;
  filter: drop-shadow(0 10px 20px rgb(0 0 0 / 0.1))
    drop-shadow(0 5px 20px rgb(0 0 0 / 0.1));
  border-radius: 10px;
  padding: 25px 20px;
  display: flex;
  /* animation: pulseAnimation infinite 1s; */
  outline: 10px solid #6f5ce931;
}

.upgradeOverlayViewPlansButton {
  margin-left: 5px;
  /* border: 1px solid #2c33462c; */
  color: #4b5268;
  padding: 9px 13px;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.upgradeOverlayViewPlansButton:hover {
  background-color: #191f2f07;
}

.upgradePlanOverlay .upgradePlanButtonIcon {
  font-size: 24px;
  margin-right: 20px;
}

.upgradePlanOverlayHeader {
  font-size: 20px;
  font-weight: 600;
}

.upgradePlanOverlayDescription {
  width: 500px;
  font-size: 14px;
  color: #40485f;
  line-height: 1.6em;
  margin-top: 5px;
}

.upgradePlanOverlayButtons {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.upgradePlanOverlayButtons .upgradeToProButton {
  width: fit-content;
  border-radius: 50px;
  padding: 8px 18px;
  font-size: 14px;
}

.upgradeToProButton {
  width: 100%;
  text-align: center;
  justify-content: center;
  color: white;
  background-image: linear-gradient(to top, #311db5, #5d49dc);
  border: 1px solid #311db5;
  padding: 10px 18px;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  display: flex;
  align-items: center;
}

.hoverButton:hover {
  transition: 0.3s ease-in-out;
  opacity: 0.9;
  cursor: pointer;
}

.useFreeDocumentButton {
  position: absolute;
  right: 20px;
  /* margin-left: 15px;
  padding-left: 10px;
  border-left: 1px solid #191f2f20;
  padding: 5px 15px; */
  background-color: #1252f320;
  padding: 10px 15px;
  border-radius: 50px;
  color: #1252f3;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: 0.3s ease-in-out;
}

.purchaseDocumentButton {
  position: absolute;
  right: 20px;
  /* margin-left: 15px;
  padding-left: 10px;
  border-left: 1px solid #191f2f20;
  padding: 5px 15px; */
  color: #41495f;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: 0.3s ease-in-out;
}

.useFreeDocumentButtonIcon {
  margin-left: 5px;
}

.createDocumentLastSection {
  flex-grow: 1;
  display: flex;
  width: 100%;
  padding-top: 20px;
  border-top: 1px solid #12162010;
  margin-top: 50px;
  display: flex;
  align-items: center;
  /* background-color: blue; */
  /* margin-bottom: 40px; */
  /* padding-top: 20px; */
}

.documentStatusInfo {
  display: flex;
  align-items: center;
  color: green;
  background-color: rgba(0, 128, 0, 0.09);
  padding: 13px 20px;
  border-radius: 50px;
}

.noAccessDocumentStatusInfo {
  color: orange;
  background-color: rgba(255, 166, 0, 0.15);
}

.noAccessDocumentStatusInfo > .documentStatusInfoIcon {
  outline: 2px solid orange;
}

.documentStatusInfoIcon {
  font-size: 18px;
  outline: 2px solid rgba(0, 128, 0, 0.341);
  border-radius: 50px;
}

.documentStatusText {
  font-weight: 500;
  margin-left: 10px;
  font-size: 15px;
}

.needHelpButton {
  display: inline-flex;
  align-items: center;
  height: 20px;
  margin-left: 30px;
  /* border: 2px solid #0845df7f; */
  padding: 10px 0px;
  /* box-shadow: 0 0px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1); */
  border-radius: 10px;
  padding-bottom: 5px;
  cursor: pointer;
  color: #191f2f;
  /* background-color: white; */
  transition: 0.1s ease-in-out;
  /* outline: 3px solid #1252f337; */
  /* box-shadow: 0 4px 20px -1px #1252f3e5, 0 2px 4px -2px #1252f3d3; */
  position: relative;
}

.needHelpButton > .needHelpText {
  font-weight: 600;
}

.needHelpButton:hover {
  text-decoration: underline;
  /* background-color: #1d53f3;
  outline: 3px solid #1252f337;
  color: white;
  box-shadow: 0 4px 20px -1px #1252f3e5, 0 2px 4px -2px #1252f3d3; */
}

.needHelpTextExample {
  position: absolute;
  top: 100%;
  margin-top: 0px;
  margin-left: 27px;
  font-size: 14px;
  left: 0;
  width: 300px;
  color: #535b70;
}

.needHelpIcon {
  font-size: 18px;
  opacity: 0.8;
  margin-right: 10px;
}

.needHelpText {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.1px;
}

.createDocumentLoading {
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}

.createDocumentLoadingSpinner {
  margin-right: 10px;
}

.createDocumentButton {
  display: flex;
  align-items: center;
  /* align-self: flex-end; */
  /* justify-content: flex-end; */
  justify-self: flex-end;
  margin-left: auto;
  background-color: rgb(6, 13, 32);
  /* border: 2px solid #e3e4e7; */
  border: none;
  padding: 0px 20px;
  height: 40px;
  border-radius: 50px;
  color: #d2d8e5;
  font-weight: 500;
  letter-spacing: -0.2px;
  font-size: 14px;
  /* box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1); */
  /* position: absolute; */
  /* bottom: 30px; */
  /* right: 50px; */
  cursor: pointer;
}

.paymentButton {
  background-color: #37a42f;
  color: white;
}

.createDocumentButtonIcon {
  margin-left: 10px;
  font-size: 16px;
  margin-top: 1px;
}

.currentDocumentInput {
  opacity: 1 !important;
}

.createDocumentInputsContainer {
  margin-top: 30px;
  border-bottom: 1px solid #17295613;
  padding-bottom: 30px;
  /* opacity: 0.3; */
}

.createDocumentDateInput {
  position: absolute;
  top: 100%;
}

.createDocumentContainer {
  /* margin-bottom: 10%; */
  height: 100%;
  overflow-y: scroll;
  background-color: #f0f3fa;
  justify-content: center;
  align-items: center;
}

.flowStatusLoadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.createDocumentStepper {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  z-index: 999999;
  background-color: white;
  /* filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.01))
    drop-shadow(0 2px 2px rgb(0 0 0 / 0.01)); */
  padding: 15px 0;
  border-bottom: 1px solid #2d395a2f;
}

.confirmAndCreateDocument {
  position: absolute;
  right: 20px;
  display: flex;
  align-items: center;
  background-color: #1252f3;
  padding: 12px 22px;
  font-weight: 500;
  border-radius: 10px;
  color: white;
  font-size: 15px;
  cursor: pointer;
  overflow: hidden;
  animation: scaleIn 0.6s ease-in-out;
  /* box-shadow: 0px 3px 5px rgba(18, 82, 243, 0.354); */
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.14),
    rgba(92, 92, 92, 0.1)
  );
}

@keyframes scaleIn {
  0% {
    transform: scale(1.05);
    outline: 10px solid #1252f330;
  }
  50% {
    transform: scale(1.03);
    outline: 5px solid #1252f330;
  }
  100% {
    transform: scale(1);
    outline: none;
  }
}

.brandNewAnimation {
  position: absolute;
  left: -300px;
  background-color: white;
  height: 200px;
  width: 150px;
  rotate: 30deg;
  animation: brandNewAnimation 0.7s ease-in-out;
}

@keyframes brandNewAnimation {
  0% {
    left: -200px;
    transform: scale(1.1);
  }
  50% {
    left: 40px;
    transform: scale(1.05);
  }
  100% {
    left: 200px;
    transform: scale(1);
  }
}

.confirmAndCreateDocument:hover {
  opacity: 0.9;
}

.confirmAndCreateIcon {
  margin-left: 8px;
  font-size: 20px;
  opacity: 0.7;
}

.createDocumentStepperProgress {
  position: absolute;
  top: 100%;
  height: 2px;
  width: 100%;
  margin-top: 0px;
  background-color: #1252f3;
  left: 0;
  transition: 0.7s ease-in-out;
}

.createDocumentConfirmStep {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, 0);
  /* background-color: blue; */
  overflow-y: scroll;
  padding-bottom: 60px;
  /* animation: zoomInAnimation 0.7s ease-in-out; */
}

.highltightText {
  text-decoration: underline;
  text-decoration-color: #1252f3;
  color: #1252f3;
  font-weight: 500;
}

@keyframes zoomInAnimation {
  0% {
    transform: scale(0.7);
  }
  50% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}

.createDocumentConfirmDetails {
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid #e9ebf0;
  width: 680px;
  margin: 20px;
  border-radius: 15px;
  margin-top: 30px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  position: relative;
}

.reviewItemsDivider {
  width: 100%;
  height: 1px;
  background-color: #191f2f16;
  position: absolute;
}

.reviewItemsDividerContainer {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 680px;
}

.reviewItemsShowMore {
  background-color: white;
  padding: 10px 20px;
  font-weight: 500;

  border-radius: 50px;
  font-size: 14px;
  cursor: pointer;
}

.showMore {
  filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1))
    drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
  box-shadow: inset 0px -2px 5px #505b782c;
}

.showLess {
  box-shadow: inset 0px 3px 5px #505b782c;
  filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07))
    drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
  /* border: 1px solid #191f2f25; */
}

.createDocumentEachDetail {
  /*  */
}

.createDocumentEachDetailExpand {
  padding: 20px 20px;
  padding-left: 70px;
  background-color: #191f2f06;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #191f2f12;
  transition: 0.5s ease-in-out;
}

.createDocumentEachDetailExpand:nth-last-child() {
  /* border-bottom: none; */
}

.eachDetailInfoSection {
  margin-bottom: 25px;
}

.eachDetailInfoSectionHeader {
  font-size: 14px;
  /* text-transform: uppercase; */
  font-weight: 500;
  /* letter-spacing: 1px; */
  margin-bottom: 5px;
  color: #555c6f;
}

.eachDetailInfoSectionDescription {
  line-height: 1.6em;
  font-size: 15px;
}

.confirmModal {
  padding: 20px;
}

.confirmModal .explainBusinessModalHeader {
  padding: 0px;
  padding-bottom: 0px !important;
  height: 45px !important;
}

.confirmModalParagraph {
  padding: 20px;
  line-height: 1.6em;
  margin-bottom: 10px;
  margin-top: 5px;
}

.confirmModalButtons {
  display: flex;
  align-self: flex-end;
  justify-self: flex-end;
  justify-content: flex-end;
  width: 100%;
  border-top: 1px solid #191f2f10;
}

.confirmCancelButton,
.confirmCreateButton {
  padding: 10px 15px;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
}

.confirmCancelButton:hover {
  opacity: 0.7;
}

.confirmCreateButton {
  background-color: #1252f3;
  border-radius: 50px;
  color: white;
  padding: 10px 20px;
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.confirmCreateButton:hover {
  opacity: 0.9;
}

.confirmModalButtons {
  padding: 15px 20px;
}

.eachDetailInfoStepper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
}

.eachDetailInfoStepperPrevious,
.eachDetailInfoStepperNext {
  display: flex;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
}

.eachDetailInfoStepperPrevious {
  opacity: 0.7;
}

.eachDetailInfoStepperPrevious:hover {
  opacity: 0.7;
}

.eachDetailInfoStepperPrevious .eachDetailInfoStepperIcon {
  margin-right: 5px;
}

.eachDetailInfoStepperNext .eachDetailInfoStepperIcon {
  margin-left: 5px;
}

.eachDetailInfoStepperNext:hover {
  background-color: #191f2f15;
}

.eachDetailInfoStepperNext {
  margin-left: 20px;
  font-weight: 600;
  border-radius: 50px;
  padding: 7px 15px;
  background-color: #191f2f10;
  color: #191f2f;
}

.eachDetailInfoStepperIcon {
  font-size: 20px;
}

.eachDetailInfoStepperText {
  font-size: 15px;
}

.eachDetailAcceptSection {
  margin-top: 10px;
  display: flex;
  align-items: center;
  position: relative;
}

.eachDetailUpdateSection {
  background-color: #ebedf1;
  padding: 15px;
  border-radius: 10px;
  margin-top: 5px;
}

.eachDetailRejectIcon {
  width: 22px;
  height: 22px;
  /* font-size: 13px; */
  /* padding: 7px 13px; */
  padding: 1px;
  font-weight: 500;
  /* color: rgb(226, 101, 101); */
  /* color: white; */
  /* background-color: #1252f3; */
  /* background-color: rgba(255, 0, 0, 0.1); */
  border-radius: 50px;
  cursor: pointer;
  /* position: absolute;
  left: -70px; */
  opacity: 0.6;
  background-color: #565c6f30;
  display: flex;
  align-items: center;
  justify-content: center;
}

.eachDetailRejectIcon:hover {
  opacity: 0.7;
}

.updateClauseInputSuggestions {
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow-x: scroll;
}

.updateClauseEachSuggestion {
  width: fit-content;
  padding: 9px;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 14px;
  background-color: #e8ebf1;
  cursor: pointer;
}

.updateClauseEachSuggestion:hover {
  background-color: #e8ebf1;
}

.eachDetailAcceptIcon {
  width: 25px;
  height: 25px;
  padding: 4px;
  margin-left: 7px;
  color: green;
  border-radius: 50px;
  cursor: pointer;
}

.eachDetailAcceptIcon:hover {
  background-color: rgba(0, 128, 0, 0.225);
}

.createDocumentEachDetailHeader {
  display: flex;
  justify-content: space-between;
  padding: 20px 25px;
  border-bottom: 1px solid #191f2f12;
}

.createDocumentEachDetailHeader:last-of-type {
  /* border-bottom: none; */
}

.createDocumentEachDetailHeader:hover {
  background-color: #191f2f04;
  cursor: pointer;
}

.createDocumentEachDetailLeft {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 85%;
}

.createDocumentEachDetailNumber {
  font-size: 24px;
  font-weight: 500;
  margin-top: -3px;
  color: #393f51;
  /* margin-right: 20px; */
  font-family: "Lexend";
  display: flex;
  align-self: flex-start;
  justify-self: flex-start;
  min-width: 50px;
}

.detailTitle {
  font-weight: 500;
  font-size: 15px;
  line-height: 1.5em;
}

.detailDescription {
  margin-top: 5px;
  font-size: 14px;
  line-height: 1.6em;
  color: #515869;
}

.createDocumentEachDetailLeftMain {
  width: 450px;
  /* background-color: blue; */
}

.createDocumentEachDetailRight {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  justify-self: flex-start;
  align-self: flex-start;
  position: relative;
}

.createDocumentEachDetailCheckIcon {
  width: 25px;
  height: 25px;
  border-radius: 50px;
  padding: 3px;
  color: white;
  background-color: green;
}

.createDocumentEachDetailDownIcon {
  margin-left: 15px;
  font-size: 20px;
  opacity: 0.7;
}

.createDocumentReviewStep {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 750px;
  animation: fadeIn 0.4s ease-in-out;
}

.createDocumentReviewLoading {
  margin-top: 10px;
  margin-bottom: 15px;
}

.createDocumentReviewStepHeader {
  margin-top: 10px;
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 5px;
}

.createDocumentReviewStepLabel {
  margin-top: 20px;
  opacity: 0.7;
}

.createDocumentReviewStep > p {
  font-size: 15px;
  color: #535a6d;
}

.createDocumentReviewStepDescription {
  width: 70%;
  margin: 0 auto;
  text-align: center;
  line-height: 1.5em;
  margin-top: 5px;
  font-size: 17px !important;
}

.createDocumentStepperLeft {
  position: absolute;
  left: 30px;
  display: flex;
  align-items: center;
}

.imageIcon {
  width: 35px;
  height: 35px;
  border-radius: 50px;
  margin-left: -7px;
  background-color: white;
  border-radius: 50px;
  border: 2px solid white;
  font-size: 16px;
}

.fileIcon {
  padding: 4px;
  background-color: rgb(227, 229, 234);
  color: #4a5165;
}

.backIconStepper {
  background-color: rgb(227, 229, 234);
  margin-right: 30px;
  font-size: 14px;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  padding: 2px;
  color: rgba(0, 0, 0, 0.6);
  border: 1px solid lightgray;
}

.createDocumentStepperCenter {
  display: flex;
  align-items: center;
  width: 340px;
  justify-content: space-evenly;
  position: relative;
  /* background-color: blue; */
}

.createDocumentEachStep {
  display: flex;
  align-items: center;
  padding: 4px;
}

.createDocumentEachStep:nth-child(1) {
  margin-left: 0px;
}

.currentDocumentEachStep {
  background-color: rgba(38, 83, 219, 0.067);
  outline: 2px solid rgba(38, 83, 219, 0.067);
  border-radius: 50px;
  width: 95px;
}

.currentDocumentEachStep .createDocumentStepNumber {
  background-color: rgb(38, 83, 219);
  color: white;
}

.currentDocumentEachStep .createDocumentStepName {
  color: rgb(38, 83, 219);
  font-weight: 500;
  display: block;
}

.createDocumentStepNumber {
  width: 25px;
  height: 25px;
  background-color: #ebeef1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  color: #77829f;
  font-size: 14px;
  margin-right: 10px;
}

.createDocumentStepName {
  font-size: 14px;
  color: #282e3c;
}

.createDocumentContainer > .createDocumentDetailLeft {
  /* background-color: #191F2F; */
}

.lastDocumentInputContainer {
  border-bottom: none;
  padding-bottom: 0px;
}

.createDocumentInputHeaderText {
  font-weight: 600;
  margin-bottom: 10px;
}

.newPartnerInputContainers {
  margin-top: 20px;
}

.createDocumentInputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.createDocumentInputs > .createDocumentInputContainer {
  width: 49%;
  margin-bottom: 0px;
  position: relative;
}

.createDocumentInputContainer {
  width: 100%;
  margin-top: 0px;
  position: relative;
}

.createDocumentInputContainer:nth-child(3) {
  margin-top: 20px;
}

.inputLabel {
  color: #444c61;
  margin-bottom: 5px;
  font-size: 14px;
}

.createDocumentDateInputContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding: 12px 15px;
  width: 100%;
  background-color: #15203e02;
  border-radius: 4px;
  border: 1px solid rgba(67, 75, 97, 0.179);
  /* box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1); */
}

.dateInputIcon {
  color: #444a5c;
}

.createDocumentInput {
  font-size: 14px;
  /* letter-spacing: -0.2px; */
  padding: 12px 15px;
  width: 100%;
  background-color: #15203e02;
  border-radius: 4px;
  border: 1px solid rgba(67, 75, 97, 0.225);
  /* transition: 0.1s ease-in-out; */
  /* box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1); */
}

.createDocumentInput:focus,
.createDocumentDateInputContainer:focus {
  border: 1px solid #4775e7;
  outline: 3px solid #1252f312;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.createDocumentTextarea {
  min-height: 150px;
}

.createDocumentInput::placeholder {
  color: #d1d5e0;
}

.dateInputText {
  color: #c3c7d2;
  font-size: 15px;
}

.addPartnerContainer {
  display: flex;
  align-items: center;
  margin-top: 15px;
  color: #2256da;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.addPartnerText {
  margin-left: 5px;
  letter-spacing: -0.2px;
}

.createDocumentRight {
  display: inline-flex;
  height: 100%;
  /* width: 55%; */
  /* margin-right: 50px; */
}

.createDocumentRight > .createDocumentButtonContainer {
  /* margin-top: 20px; */
  /* padding-top: 20px; */
  /* padding-top: 50px; */
  display: inline-flex;
  position: fixed;
  z-index: 99999999;
  right: 0;
  padding-bottom: 40px;
}

.createDocumentButtonContainer {
  width: 680px;
}

.createDocumentButtonContent {
  width: fit-content;
  margin-left: auto;
}

.createDocumentButtonStepper {
  /* padding-bottom: 40px !important; */
  /* position: fixed; */
  /* right: 0; */
  /* bottom: 0; */
  width: 720px;
  margin: 0 auto;
  /* border-top: 1px solid #12162019; */
  /* height: 100px; */
  padding-right: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 10px;
  /* background-color: red; */
  /* background-color: white; */
  /* background-color: rgb(250, 250, 250); */
  /* box-shadow: 0 -2px 30px -1px rgb(0 0 0 / 0.05), 0 0px 4px -2px rgb(0 0 0 / 0.05); */
}

.lastStepButton {
  background-color: #3cac34;
  color: white;
  position: relative;
}

.warningAnswersText {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  font-weight: 600;
}

.lastStepButton > p {
  font-weight: 600;
}

.animationClass {
  animation: fadeIn 1s ease-in-out;
}

.createDocumentGeneralInput {
  display: inline-flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  justify-self: center;
  align-self: center;
  animation: fadeIn 0.3s ease-in-out;
  height: 100%;
  transition: 0.4s ease-in-out;
  /* margin: 90px 0; */
  /* overflow-y: scroll; */
  /* margin-top: 150px; */
  /* margin-bottom: 150px; */
  /* background-color: blue; */
}

.createDocumentGeneralInputWithSuggestions {
  /* margin-top: 160px !important; */
  /* height: 50%;
  background-color: blue;
  overflow-y: scroll; */
  /* max-height: 200vh;
  he
  100% */
  /* background-color: blue; */
  /* margin-bottom: 150px !important; */
  margin-top: 40px;
}

.keyboardShortcutText {
  font-size: 13px;
  color: #636b82;
  margin-right: 10px;
}

.generalInputHeaderLabel {
  color: #356bf3;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 4px;
  margin-bottom: 20px;
  font-weight: 500;
  /* opacity: .5; */
}

.generalInputHeaderText {
  letter-spacing: -0.4px;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.6em;
}

.generalInputHeaderDescription {
  margin-top: 10px;
  font-size: 16px;
}

.generalInput {
  font-size: 16px;
  font-weight: 400;
  height: 60px;
  border: none;
  border: 1px solid #3b519056;
  border-radius: 5px;
  padding: 10px 15px;
  outline: none;
  margin-top: 15px;
  margin-bottom: 15px;
  min-height: 150px;
  line-height: 1.6em;
  min-width: 100%;
  max-width: 100%;
  max-height: 250px;
  transition: 0.05s ease-in-out;
  letter-spacing: -0.1px;
}

.generalInput:focus {
  border: 1px solid #4775e7;
  outline: 3px solid #1252f312;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  /* border-bottom: 1px solid #96b4ff; */
}

.generalInput::placeholder {
  color: #15203e3e;
}

.stepperErrorText {
  animation: fadeFromTop 0.3s ease-in-out;
  color: red;
  font-weight: 500;
  margin-top: -5px;
  margin-bottom: 10px;
  font-size: 14px;
  margin-left: 0px;
}

@keyframes fadeFromTop {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.createDocumentSuggestionsContainer {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  /* padding: 5px 10px; */
  border: 1px solid #e3e5ea;
  margin-bottom: 30px;
  margin-top: 10px;
  transition: 0.6s ease-in-out;
  /* padding-top: 20px; */
}

.suggestionsHeader {
  display: inline-flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 15px;
  border-radius: 0px;
  display: flex;
  /* align-items: center; */
  /* cursor: pointer; */
  position: relative;
  border-bottom: 1px solid #15203e14;
  background-color: #15203e02;
  cursor: pointer;
  transition: 0.4s ease-in-out;
}

.suggestionsHeader:hover {
  background-color: #15203e04;
}

.suggestionsHeaderDescription {
  margin-top: 7px;
  width: 90%;
  font-size: 15px;
  line-height: 1.5em;
  color: #474e60;
}

.suggestionsHeaderTop {
  display: flex;
  align-items: center;
}

.suggestionsHeaderIcon {
  color: #1252f3;
  font-size: 20px;
}

.suggestionsHeaderText {
  margin-left: 10px;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: -0.2px;
}

.suggestionHeaderArrowIcon {
  position: absolute;
  right: 20px;
  opacity: 0.8;
}

.suggestionsList {
  margin-top: 0px;
  /* margin-bottom: 10px; */
  border-radius: 10px;
  margin: 10px;
  margin-top: 0px;
  transition: 0.6s ease-in-out;
}

.eachSuggestion {
  padding: 13px 10px;
  border: 1px solid white;
  border-radius: 10px;
  margin-bottom: 5px;
}

.selectedEachSuggestion {
  background-color: #1252f307;
  border: 1px solid #1252f3;
}

.eachSuggestion:nth-child(even) {
  /* border-top: 1px solid #15203e19; */
  /* padding-top: 25px; */
}

.eachSuggestionHeader {
  display: flex;
  align-items: flex-start;
  /* justify-content: space-between; */
  cursor: pointer;
}

.suggestionHeaderTop {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10px;
}

.eachSuggestionCheckbox {
  margin-top: -2px;
  font-size: 22px;
  border: 1px solid #191f2f;
  border-radius: 50px;
  color: #191f2f;
  opacity: 0.1;
}

.selectedSuggestionCheckbox {
  color: #1252f3;
  opacity: 1 !important;
  border: 1px solid #1252f3;
}

.viewInDetail {
  display: flex;
  align-items: center;
  /* background-color: #191f2f0e; */
  color: #5f6b8a;
  /* border: 1px solid #191f2f20; */
  /* padding: 5px 12px; */
  border-radius: 50px;
  /* font-weight: 500; */
  font-size: 13px;
  margin-top: 3px;
}

.viewInDetailIcon {
  margin-left: 5px;
}

.eachSuggestionTitle {
  line-height: 1.6em;
  margin-top: -5px;
  font-weight: 500;
  color: #293044;
  font-size: 15px;
  /* width: 95%; */
  /* margin-left: 10px; */
}

.eachSuggestionFullCase {
  /* margin-top: 10px; */
  color: #292f41;
  line-height: 1.8em;
  font-size: 15px;
  /* width: 100%; */
  border-top: 1px solid #e8ebf5;
  padding: 0px 0px;
  margin: 0 30px;
  margin-top: 10px;
  padding-top: 10px;
  /* border-radius: 5px; */
}

.createDocumentGeneralInput
  > .createDocumentButtonContainer
  > .createDocumentButton {
  margin-bottom: 0px;
}

.sideStepperContainer {
  /*  */
}

.sideStepperWarningIndicators {
  position: absolute;
  top: -5px;
  right: 0;
  display: flex;
  align-items: center;
}

.improvementIndicator {
  display: flex;
  align-items: center;
  background-color: #336af7;
  border-radius: 20px;
  font-size: 13px;
  padding: 3px 8px;
  color: rgba(255, 255, 255, 0.573);
  margin-right: 7px;
}

.warningIndicator {
  display: flex;
  align-items: center;
  background-color: rgb(253, 191, 74);
  border-radius: 20px;
  font-size: 13px;
  padding: 3px 8px;
}

.improvementIndicatorCount,
.warningIndicatorCount {
  margin-left: 4px;
}

.warningIndicatorCount {
  font-weight: 500;
  color: rgb(86, 57, 4);
}

.warningIndicatorCountText {
  font-weight: 500;
  margin-left: 10px;
  opacity: 0.7;
  margin-right: 2px;
  color: rgb(86, 57, 4);
}

.sideStepperHeader {
  padding: 0px 20px;
  margin-top: 20px;
  z-index: 999999;
  position: relative;
}

.sideStepper {
  display: flex;
  flex-direction: column;
  height: 70%;
  /* align-items: center; */
  /* margin-top: -50px; */
  padding: 0px 20px;
  position: absolute;
  top: 190px;
  overflow: scroll;
  padding-bottom: 120px;
  width: 560px;
  /* background-color: blue; */
}

.sideStepperHeader {
  padding: 0px 30px;
  padding-bottom: 15px;
  margin-top: 30px;
  border-bottom: 1px solid #ffffff09;
}

.sideStepperHeaderText {
  font-size: 16px;
  color: white;
  font-weight: 500;
  opacity: 0.78;
}

.sideStepperHeaderDescription {
  margin-top: 5px;
  color: white;
  opacity: 0.3;
  font-size: 14px;
  line-height: 1.5em;
}

.sideStepperChatText {
  text-decoration: underline;
  font-weight: 600;
}

.sideStepper {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.sideStepper::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.sideStepperGradient {
  position: fixed;
  bottom: 15px;
  width: 40%;
  height: 155px;
  background: linear-gradient(180deg, rgba(18, 82, 243, 0) 0%, #1252f3 50.23%);
}

.sideStepperGradientTop {
  position: fixed;
  top: 24%;
  width: 40%;
  height: 20px;
  background: linear-gradient(180deg, rgba(18, 83, 243, 0) 0%, #1253f3 68.23%);
  transform: rotate(-180deg);
}

.eachStep {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  opacity: 0.25;
  border-radius: 15px;
  padding: 10px 25px;
  transition: opacity 0.3s ease-in-out;
}

.finishedStep {
  opacity: 0.1;
  cursor: pointer;
}

.stepNotLoaded {
  opacity: 0.1;
}

.currentStep {
  padding: 20px 25px;
  opacity: 1 !important;
  background-color: rgba(255, 255, 255, 0.04);
  background: linear-gradient(
    90deg,
    rgba(108, 142, 228, 0.15) 0%,
    #2a63f253 100%
  );
  margin: 10px 0;
  margin-bottom: 10px;
  /* max-width: 525px; */
}

.eachStepMoreDetails {
  margin-top: 10px;
}

.eachStepDescription {
  color: #749bfe;
  line-height: 1.6em;
}

.eachStepVideoContainer {
  display: flex;
  align-items: center;
  padding: 15px 0;
  padding-bottom: 5px;
  color: white;
  margin-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  cursor: pointer;
}

.eachStepVideoPlayIcon {
  background-color: white;
  color: #1253f3;
  height: 25px;
  width: 25px;
  border-radius: 50px;
  padding: 5px;
}

.eachStepVideoDetail {
  margin-left: 10px;
}

.learnMoreText {
  font-size: 12px;
  color: #86a7ff;
}

.eachStepVideoTitle {
  margin-top: 5px;
  font-size: 14px;
}

.currentStep > .eachStepHeader > .stepTitle {
  font-weight: 500;
  font-size: 16px;
}

.eachStepHeader {
  display: flex;
  align-items: center;
}

.stepNumber {
  height: 30px;
  width: 30px;
  background-color: white;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #1253f3;
}

.stepTitle {
  margin-left: 15px;
  color: white;
}

.eachStepLoader {
  position: absolute;
  right: 40px;
}

.warningEachStepIcon {
  background-color: orange;
  color: rgb(94, 55, 4) !important;
  border-radius: 50px;
  padding: 3px;
  opacity: 1 !important;
}

/*  */

.finalDocumentContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  min-height: 100vh;
}

.finalDocumentUpgradeInfo {
  display: flex;
  /* align-items: center; */
  /* justify-content: space-between; */
  color: white;
  padding: 25px;
  position: relative;
  z-index: 9999999;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 20px;
}

.finalDocumentUpgradeInfoButtonIcon {
  opacity: 0.7;
  font-size: 20px;
}

.finalDocumentUpgradeInfoButtonText {
  margin-left: 10px;
}

.finalDocumentUpgradeInfoIcon {
  font-size: 20px;
  margin-top: 5px;
  opacity: 0.7;
}

.finalDocumentUpgradeInfoText {
  display: flex;
  flex-direction: column;
  margin-left: 26px;
}

.finalDocumentUpgradeInfoHeader {
  font-weight: 600;
  font-size: 15px;
}

.finalDocumentUpgradeInfoHeaderText {
  font-size: 13px;
  margin-top: 5px;
  color: #e0ddf4;
}

.finalDocumentUpgradeInfoLabel {
  font-size: 12px;
  margin-top: 7px;
  color: #aea3f5;
  position: absolute;
  right: 25px;
  bottom: 20px;
}

.finalDocumentUpgradeInfoButtons {
  /* position: absolute;
  right: 20px; */
  margin-top: 13px;
  width: 100%;
}

.finalDocumentUpgradeInfoButton {
  background-color: #6b54fb;
  color: white;
  font-size: 14px;
  padding: 15px 25px;
  border-radius: 50px;
  font-weight: 500;
  cursor: pointer;
  box-shadow: 0px 10px 10px #6b54fb50;
  display: flex;
  align-items: center;
}

.finalDocumentUpgradeInfoButtonSecondary {
  background-color: white;
  color: #573ef6;
  font-size: 12px;
  padding: 7px 16px;
  border-radius: 50px;
  margin-right: 5px;
  font-weight: 500;
  cursor: pointer;
}

.finalDocumentAlertInfo {
  display: flex;
  align-items: center;
  background-color: #1252f318;
  color: #1252f3;
  padding: 15px 20px;
  position: relative;
  width: calc(100% - 420px);
  border-bottom: 1px solid #1252f3;
}

.finalDocumentAlertInfoLoadingIcon {
  position: absolute;
  right: 20px;
}

.finalDocumentAlertInfoIcon {
  font-size: 16px;
}

.finalDocumentAlertInfoText {
  margin-left: 15px;
  font-size: 13px;
  font-weight: 500;
}

.finalDocumentLeft {
  width: 100%;
}

.finalDocumentHeader {
  /* width: 99%; */
  /* width: calc(100% - 420px); */
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  /* border: 1px solid #191f2f13; */
  /* margin-: 0 auto; */
  /* margin-top: 15px; */
  /* border-radius: 10px; */
  color: #191f2f;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #191f2f18;
  /* box-shadow: 0 4px 4px -1px rgba(0, 0, 0, 0.023),
    0 2px 4px -2px rgba(0, 0, 0, 0.014); */
  /* z-index: 9; */
  position: relative;
  background-color: #191f2f03;
}

.finalDocumentHeaderLeft {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  width: 100%;
  position: relative;
}

.finalDocumentHeaderIcon {
  color: #191919;
  border: 1px solid #191f2f32;
  width: 30px;
  height: 30px;
  padding: 6px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 20px;
}

.finalDocumentHeaderText {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.2px;
  margin-right: 10px;
}

.finalDocumentHeaderTextInput {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.2px;
  margin-right: 5px;
  background: transparent;
  border: none;
  color: #191f2f;
  outline: none;
  padding: 0px;
}

.finalDocumentHeaderTextInput:focus {
  background-color: #343a4f20;
}

.documentNameInputChangeIcon {
  font-size: 14px;
  margin-right: 10px;
}

.documentNameSaveButton {
  background-color: #1252f3;
  padding: 3px 6px;
  font-size: 12px;
  border-radius: 5px;
  margin: 0 10px;
  margin-right: 15px;
  cursor: pointer;
  color: white;
}

.documentNameSaveButton .saveButtonIcon {
  font-size: 14px;
  opacity: 0.7;
}

.saveButton {
  padding: 10px 20px;
  background-color: #1252f3;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.14),
    rgba(92, 92, 92, 0.1)
  );
  border: none;
  color: white;
  border-radius: 10px;
  font-weight: 500;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.saveButton:hover {
  outline: 2px solid #1252f325;
}

.saveButtonText {
  font-size: 12px;
  margin-left: 5px;
}

.saveButtonIcon {
  font-size: 14px;
}

.saveButton:hover {
  opacity: 0.9;
  /* background-color: #1252f3 !important; */
}

.signButton {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 0px 6px -1px rgba(255, 255, 255, 0.177),
    0 2px 4px -2px rgb(0 0 0 / 0.1);
  background: rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 32px 0 rgb(22, 35, 215);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  /* border-radius: 10px; */
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.downloadButton {
  box-shadow: none;
  background-color: #191f2f10;
  color: #191f2f;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.06),
    rgba(92, 92, 92, 0.06)
  );
}

.saveButtonLoadingIcon {
  margin: 0px;
  padding: 0px;
  margin-left: 4px;
}

.downloadButton:hover {
  outline: 2px solid #757b8920;
}

.downloadLine {
  height: 30px;
  width: 1px;
  background-color: #444b611e;
  margin-left: 10px;
  margin-right: 10px;
}

.signButton:hover {
  /* background-color: rgba(255, 255, 255, 0.05); */
}

.unsavedChangesTextContainer {
  display: flex;
  align-items: center;
  margin-right: 35px;
  border-radius: 50px;
  background-color: #1252f320;
  width: fit-content;
  padding: 10px 15px;
  position: absolute;
  left: 0px;
  top: -50px;
  align-items: flex-end;
}

.unsavedChangesText {
  font-size: 12px;
  color: #1252f3;
  font-weight: 500;
}

.unsavedChangesIcon {
  color: #1252f3;
  font-size: 14px;
  margin-right: 10px;
}

.finalDocumentHeaderButtons {
  display: flex;
  align-items: center;
  position: absolute;
  right: 20px;
}

.finalDocumentCreatedDate {
  font-size: 12px;
  color: #767d90;
}

.versionIconContainer {
  background-color: #dde5fd;
  padding: 5px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  margin-left: 20px;
  /* position: absolute;
  left: 0;
  top: 30px; */
  /* box-shadow: 0px 2px 2px #1252f352; */
  border: 1px solid #1252f3;
}

.latestVersionIconContainer {
  background-color: rgb(206, 246, 206);
  border: 1px solid rgb(20, 152, 20);
}

.latestVersionIconContainer .versionText {
  color: rgb(20, 152, 20);
}

.versionText {
  color: #1252f3;
  font-weight: 500;
  font-size: 11px;
}

.documentLoading {
  margin-left: 20px;
  border-left: 1px solid #1252f320;
  padding-left: 20px !important;
}

.documentLoadingText {
  font-size: 12px;
  color: #191f2f90;
  margin-left: 10px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 99 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: scroll;
}

.modalOverlay {
  position: fixed;
  background-color: #121620b4;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.modalContent {
  background-color: white;
  width: 600px;
  min-height: 250px;
  max-height: 700px;
  /* height: 450px; */
  z-index: 999;
  border-radius: 10px;
  box-shadow: 0 20px 25px -5px #15203e5f, 0 8px 10px -6px #15203e2f;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.modalContentHeader {
  min-height: 60px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: space-between; */
  border-bottom: 1px solid #12162011;
  /* padding-bottom: 15px; */
  background-color: #12162003;
}

.flexModalContentHeader {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.signatureModalHeaderContent {
  flex-direction: row;
  justify-content: unset !important;
  align-items: center;
}

.signatureModalHeaderContent > .modalContentHeaderCloseIcon {
  position: absolute;
  right: 0;
}

.flexModalContentHeader > .modalContentHeaderLeft {
  display: flex;
  align-items: center;
}

.flexModalContentHeader > .modalContentHeaderLeft > .modalContentHeaderText {
  margin-left: 15px;
}

.modalContentHeaderTop {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  width: 100%;
}

.modalContentHeaderLeft {
  display: flex;
  /* align-items: center; */
}

.modalContentHeaderIcon {
  font-size: 18px;
  color: #191f2f;
  background-color: #191f2f1d;
  width: 25px;
  height: 25px;
  padding: 2px;
  border-radius: 5px;
}

.modalContentHeaderCloseIcon {
  font-size: 18px;
  width: 50px;
  height: 20px;
  cursor: pointer;
  display: flex;
  align-self: flex-start;
}

.modalContentHeaderCloseIcon:hover {
  opacity: 0.7;
}

.modalContentHeaderTextContent {
  margin-left: 20px;
}

.modalContentHeaderText {
  font-size: 17px;
  font-weight: 600;
  color: #191f2f;
  letter-spacing: -0.2px;
}

.modalMainContent {
  padding-bottom: 0px;
  width: 100%;
  min-height: 130px;
  overflow: scroll;
}

.modalCenterContent {
  /*  */
}

.modalInputs {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  padding-bottom: 10px;
}

.modalInputContainer {
  width: 100%;
  margin-bottom: 25px;
}

.modalInputLabel {
  font-size: 13px;
  font-weight: 500;
  color: #121620;
  letter-spacing: -0.2px;
}

.modalInput {
  width: 100%;
  margin-top: 5px;
  padding: 10px 15px;
  border: 1px solid #12162059;
  border-radius: 5px;
  background-color: #12162003;
  height: 45px;
}

.modalMainContentButton {
  padding: 10px 15px;
  background-color: #1253f3;
  /* border:1px solid #1450ea; */
  width: fit-content;
  min-width: 145px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* align-self: flex-end; 
   justify-self: flex-end; */
  font-size: 14px;
  font-weight: 500;
  color: white;
  border-radius: 50px;
  cursor: pointer;
}

.modalMainContentButtonLoader {
  margin-left: 5px;
}

.modalButtonContainer {
  padding: 12px 20px;
  width: 100%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top: 1px solid #12162010;
  background-color: #12162008;
  display: flex;
  flex-direction: column;

  /* align-items: flex-end;
  justify-content: flex-end;
  align-self: flex-end;
  justify-self: flex-end; */
}

.disabledInput {
  background-color: #12162005;
  color: #121620ac;
  opacity: 0.7;
}

.detailAddedSignature {
  background-color: #1252f31e;
}

.detailAddedSignature > p {
  font-weight: 500;
  font-style: italic;
}

.disabledButton {
  opacity: 0.6;
  user-select: none;
  /* cursor: help; */
  box-shadow: none;
}

.disabledButton:hover {
  /* background-color: inherit !important; */
}

.modalSignatureContent {
  padding: 20px;
  margin-bottom: 10px;
}

.signatureVerifyText {
  font-weight: 500;
  font-size: 16px;
}

.modalSignatureDetails {
  margin-top: 20px;
}

.modalSignatureDetailsHeader {
  display: flex;
  align-items: center;
  padding-bottom: 5px;
  border-bottom: 1px solid #1216200d;
}

.modalSignatureNameHeader,
.modalSignatureEmailHeader {
  font-size: 14px;
  color: #4e515b;
}

.modalSignatureNameHeader {
  width: 100px;
}

.modalEachSignatureDetail {
  display: flex;
  align-items: center;
  padding: 13px 0px;
  /* padding-bottom: 5px; */
  border-bottom: 1px solid #12162008;
}

.modalEachSignatureDetail > p {
  font-weight: 500;
  font-size: 15px;
  color: #191f2f;
}

.modalSignatureName {
  width: 100px;
}

.modalSignatureSentForSignature {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 20px 0;
  margin-bottom: 0px;
}

.modalSignatureSentForSignatureIcon {
  font-size: 50px;
  color: #37a42f;
}

.modalSignatureSentForSignatureHeaderText {
  margin-top: 15px;
  font-weight: 600;
  letter-spacing: -0.2px;
  font-size: 19px;
}

.modalSignatureSentForSignatureDescription {
  width: 85%;
  line-height: 1.6em;
  margin-top: 5px;
  font-size: 16px;
  color: #121620c0;
  letter-spacing: 0.1px;
}

.checkEmailLink {
  margin-top: 30px;
  color: #2256da;
  font-weight: 500;
}

.incompleteDocumentModal {
  max-width: 850px;
  overflow-y: scroll;
}

.incompleteDocumentModal .ButtonMain {
  background-color: #4f37e8;
}

.incompleteDocModalText {
  line-height: 1.6em;
  font-size: 13px;
  opacity: 0.6;
  margin-top: 25px;
}

.incompleteDocumentModal .DialogContentContainer {
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.incompleteDocModalContent {
  /*  */
}

.incompleteDocTable {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  /* border: 1px solid #191f2f12; */
  border-radius: 15px;
  margin-top: 15px;
}

.eachPricingTableHeader {
  padding: 15px 20px;
  border-radius: 10px;
  height: 100%;
  border-bottom: 1px solid #191f2f12;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.eachPricingTableLabel {
  color: #4f37e8;
  border: 1px solid #4f37e8;
  background-color: #4f37e810;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  padding: 3px 7px;
  font-size: 9px;
  font-weight: 500;
  width: fit-content;
  border-radius: 5px;
  margin-bottom: 15px;
}

.eachPricingTableType {
  font-size: 14px;
  opacity: 0.7;
}

.eachPricingTableSection {
  border: 1px solid #191f2f14;
  border-radius: 10px;
}

.eachPricingTablePrice {
  font-size: 28px;
  letter-spacing: -0.04rem;
  font-weight: 600;
  margin-top: 10px;
}

.eachPricingBenefits {
  padding: 15px 20px;
  padding-right: 10px;
  padding-top: 20px;
}

.eachPricingBenefits div {
  margin-top: 30px;
}

.eachPricingBenefits p {
  font-size: 13px;
  margin-bottom: 15px;
  font-weight: 500;
}

.featureNotAvailable {
  /* text-decoration: line-through; */
  opacity: 0.3;
  font-weight: 400 !important;
}

.incompleteDocPricingBenefits {
  /*  */
  margin-top: 10px;
}

.incompleteTitle {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.01rem;
}

.incompleteDocPricingBenefitsList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 15px;
}

.incompleteDocPricingBenefitsList div {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.incompleteDocPricingBenefitsList div p {
  font-size: 14px;
  margin-left: 10px;
}

.benefitsIcon {
  font-size: 22px;
  color: #191f2f50;
}

.finalDocumentViewerContent {
  width: 100%;
  position: relative;
  width: calc(100% - 420px);
}

.finalDocumentViewerContainer {
  width: 100%;
  background-color: transparent;
  overflow-y: scroll !important;
  /* justify-content: center; */
  max-height: 100%;
  padding: 50px 0;
  padding-bottom: 100px;
}

.versionsContainer {
  position: absolute;
  left: -60px;
  top: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.versionsIcon {
  font-size: 20px;
  cursor: pointer;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 10px;
  background-color: #19191905;
}

.versionsList {
  position: absolute;
  top: 0;
  width: 370px;
  height: 270px;
  overflow: scroll;
  left: 0;
  background: linear-gradient(90deg, #ffffff 55%, rgba(255, 255, 255, 0) 100%);
  /* background-color: blue; */
  z-index: 99999;
  animation: fadeIn 0.4s ease-in-out;
  border-left: 2px solid #191f2f10;
  padding-left: 10px;
}

.versionsList::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.versionsList {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.versionsListHeader {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 12px;
  margin-left: 40px;
}

.closeVersionsListIcon {
  position: absolute;
  top: 0;
  left: 22px;
  width: 13px;
  height: 13px;
  background-color: #191f2f15;
  border-radius: 50px;
  color: #2d3445;
  padding: 1px;
  cursor: pointer;
}

.currentVersionContainer {
  background-color: #f3f5f8;
}

.eachVersionContainer {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 4px 10px;
  padding-right: 15px;
  margin: 7px 0px;
  border-radius: 10px;
  width: fit-content;
  cursor: pointer;
  text-decoration: none;
  min-width: 200px;
}

.eachVersionContainer:hover {
  background-color: #f6f7fa;
}

.eachVersionName {
  font-weight: 600;
  font-size: 13px;
}

.eachVersionDate {
  font-size: 12px;
  /* text-transform: uppercase; */
  /* letter-spacing: 0.5px; */
  margin-left: 0px;
  color: #343949;
  font-weight: 500;
  text-decoration: none;
}

.eachVersionIcon {
  margin-right: 10px;
  width: 26px;
  height: 26px;
  background: transparent;
  color: #1252f3;
}

.versionsIcon:hover {
  background-color: #19191910;
}

.documentContent {
  position: relative;
  transition: all 0.9s ease-in-out;
  height: 100%;
  min-height: 100vh;
}

.finalDocumentViewerContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.finalDocumentViewerContainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.pdfViewer {
  max-width: 100%;
  margin: 0 auto;
  /* transform: scale(0.8); */
}

.pdfViewerDocument {
  /*  */
}

.documentViewerLoadingContainer {
  width: 100%;
  position: fixed;
  top: 0px;
  min-height: 100vh;
  background-color: white;
  z-index: 99999999999999999999999999;
  animation: fadeIn 0.2s ease-in-out;
}

.documentViewerLoader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

.documentViewerLoaderText {
  font-size: 18px;
  letter-spacing: -0.2px;
  font-weight: 500;
  margin-top: 10px;
  margin-left: 30px;
}

.pdfViewerPage {
  max-width: 700px;
  margin: 0 auto;
  /* transform: scale(0.8); */
}

.finalDocumentViewer {
  /* min-height: 1162px; */
  width: 700px;
  margin: 0 auto;
  position: relative;
  background-color: white;
  background-color: blue;
  /* margin: 0 auto; */
  /* box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1); */
  /* border: 1px solid #ecedf1; */
  /* border-radius: 10px; */
  /* margin-bottom: 20px; */
  /* padding: 100px 79px; */
  padding: 40px 0px;
  /* padding-bottom: 0px; */
  position: relative;
  font-family: "Courier New", Courier, monospace;
  outline: none;
}

.finalDocumentViewer > p,
h1 {
  /* font-family:san; */
  /* font-family: ; */
}

.finalDocumentSignatureElement {
  border: 1px solid #1253f3;
  border-style: dashed;
  padding: 12px 20px;
  text-align: center;
  width: 170px;
  font-weight: 500;
  border-radius: 5px;
  position: relative;
  /* cursor: pointer; */
  user-select: none;
}

.signatureElementRemove {
  position: absolute;
  top: -10px;
  right: 10px;
  background-color: white;
  border: 1px solid #12162018;
  background-color: #e1e3e7;
  font-size: 14px;
  color: #121620;
  cursor: pointer;
  border-radius: 50px;
}

.finalDocumentSignatureElement > p {
  font-size: 12px;
}

.finalDocumentViewerParagraph {
  line-height: 2.1em;
  font-size: 14px;
  color: black;
  letter-spacing: 0.3px;
  font-family: "Inter" !important;
  color: #1f2635;
  padding: 12px 0px;
  padding-bottom: 0px;
  white-space: pre-line;
  animation: fadeIn 0.3s ease-in-out;
}

.finalDocumentViewerHeading {
  font-weight: 700;
  padding-bottom: 10px;
  margin-bottom: 20px;
  margin-top: 30px;
  border-bottom: 1px solid #12162015;
  font-size: 34px;
  letter-spacing: -0.4px;
  font-family: "Playfair Display", serif;
}

.finalDocumentViewerHeading:nth-child(1) {
  margin-bottom: 20px;
}

.document-builder {
  width: 100%;
  position: relative;
}

.finalDocumentViewerSubHeading {
  font-weight: 700;
  font-size: 16px;
  margin-top: 30px;
  margin-bottom: 15px;
  border-bottom: 1px solid black;
  width: fit-content;
  color: #151b2a;
  font-family: "Playfair Display", serif;
}
/* 
.finalDocumentUpgradePlanContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: calc(100% - 450px);
  z-index: 9999999999;
  height: 400px;
  background-color: blue;
  background: linear-gradient(to top, #ffffff 70%, rgba(255, 255, 255, 0) 100%);
  padding-top: 30px;
}

.finalDocumentUpgradePlan {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 530px;
  margin: 0 auto;
  background-color: transparent;
  padding: 15px;
  border-radius: 10px;
  animation: fadeIn 0.4s ease-in-out;
}

.finalDocumentUpgradeButton {
  position: fixed;
  bottom: 25px;
  left: 110px;
  z-index: 99;
  width: 180px;
  background-image: linear-gradient(to top, #311db5, #624de9);
  padding: 12px;
  text-align: center;
  border: 1px solid #311db5;
  font-size: 13px;
  color: white;
  font-weight: 500;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(0 4px 10px #311db51c) drop-shadow(0 2px 5px #311db5ab);
}

.finalDocumentUpgradeButtonIcon {
  margin-right: 10px;
}

.finalDocumentUpgradePlan .closeIcon {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  background-color: #191f2f10;
  color: #191f2f;
  border-radius: 50px;
  padding: 2px;
  cursor: pointer;
}

.finalDocumentUpgradePlan .upgradePlanButtonIcon {
  font-size: 25px;
  margin-bottom: 7px;
}

.finalDocumentUpgradePlanHeader {
  font-size: 19px;
  font-weight: 600;
  line-height: 1.4em;
  margin-bottom: 8px;
}

.eachBenefitIcon {
  width: 20px;
  height: 20px;
  background-color: rgba(0, 128, 0, 0.161);
  color: green;
  padding: 2px;
  border-radius: 50px;
  margin-right: 9px;
}

.finalDocumentUpgradePlanFeature {
  font-size: 14px;
  color: #454c5d;
  margin: 14px 0px;
  display: flex;
  align-items: center;
}

.finalDocumentUpgradePlanFeatureText {
  line-height: 1.5em;
}

.finalDocumentUpgradePlanButtons {
  display: flex;
  flex-direction: column;
  margin-top: 18px;
}

.finalDocumentUpgradePlanButton {
  background-image: linear-gradient(to top, #311db5, #624de9);
  width: 100%;
  padding: 12px 7px;
  text-align: center;
  border: 1px solid #311db5;
  font-size: 14px;
  color: white;
  font-weight: 500;
  border-radius: 5px;
} */

.finalDocumentUpgradePlan {
  position: fixed;
  bottom: 25px;
  left: 110px;
  z-index: 99;
  width: 280px;
  background-color: white;
  border: 1px solid #191f2f10;
  padding: 15px;
  border-radius: 15px;
  filter: drop-shadow(0 4px 10px #311db520) drop-shadow(0 2px 7px #311db520);
  animation: fadeIn 0.4s ease-in-out;
}

.finalDocumentUpgradeButton {
  position: fixed;
  bottom: 25px;
  left: 110px;
  z-index: 99;
  width: 180px;
  background-image: linear-gradient(to top, #311db5, #624de9);
  padding: 12px;
  text-align: center;
  border: 1px solid #311db5;
  font-size: 13px;
  color: white;
  font-weight: 500;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(0 4px 10px #311db51c) drop-shadow(0 2px 5px #311db5ab);
}

.finalDocumentUpgradeButtonIcon {
  margin-right: 10px;
}

.finalDocumentUpgradePlan .closeIcon {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  background-color: #191f2f10;
  color: #191f2f;
  border-radius: 50px;
  padding: 2px;
  cursor: pointer;
}

.finalDocumentUpgradePlan .upgradePlanButtonIcon {
  font-size: 25px;
  margin-bottom: 7px;
}

.finalDocumentUpgradePlanHeader {
  font-size: 15px;
  font-weight: 600;
  line-height: 1.4em;
  margin-bottom: 8px;
}

.finalDocumentUpgradePlanFeature {
  font-size: 13px;
  color: #454c5d;
  margin-bottom: 8px;
}

.finalDocumentUpgradePlanButtons {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.finalDocumentUpgradePlanButton {
  background-image: linear-gradient(to top, #311db5, #624de9);
  width: 100%;
  padding: 7px;
  text-align: center;
  border: 1px solid #311db5;
  font-size: 13px;
  color: white;
  font-weight: 500;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.finalDocumentContactButton {
  border: 1px solid #191f2f10;
  background-color: #191f2f06;
  width: 100%;
  padding: 7px;
  text-align: center;
  font-size: 13px;
  color: #191f2f;
  font-weight: 500;
  border-radius: 5px;
  margin-top: 4px;
}

.finalDocumentContactButton {
  border: 1px solid #191f2f10;
  background-color: #191f2f06;
  width: 100%;
  padding: 6px 7px;
  text-align: center;
  font-size: 13px;
  color: #191f2f;
  font-weight: 500;
  border-radius: 5px;
  margin-top: 4px;
}

.finalDocumentRight {
  /* display: flex; */
  overflow: hidden;
  height: 100%;
}

.finalDocumentRightSidebar {
  width: 420px;
  background-color: white;
  position: fixed;
  top: 0px;
  right: 0px;
  box-shadow: 0 10px 10px -3px rgb(0 0 0 / 0.2), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  border-left: 1px solid #191f2f17;
  /* border-left: 1px solid #5561821a; */
  min-height: 100%;
}

.finalDocumentRightSidebarHeader {
  padding: 21px 20px;
  border-bottom: 1px solid #191f2f10;
  display: flex;
  align-items: center;
  /* background-color: #191f2f04; */
}

.finalDocumentRightSidebarHeaderIcon {
  font-size: 16px;
  color: #898fa2;
  outline: 2px solid #dbdde47e;
  margin-right: 10px;
  border-radius: 50px;
}

.dontCloseBrowserText {
  font-size: 14px;
  margin: 13px;
  margin-bottom: 10px;
  background-color: #1252f315;
  padding: 10px 12px;
  border-radius: 10px;
  line-height: 1.5em;
  font-weight: 500;
  color: #051336;
}

.lawyerReviewSection {
  background: linear-gradient(to right, #1b59f3, #1b59f3);
  margin: 18px;
  border-radius: 10px;
  padding: 15px;
  color: #c3d1f4;
  outline: 5px solid #a0b7f0;
}

.lawyerReviewIcon {
  font-size: 18px;
  margin-bottom: 10px;
}

.lawyerReviewHeader {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  color: #f0f2f8;
}

.lawyerReviewHeaderText {
  font-size: 15px;
  font-weight: 600;
  line-height: 1.5em;
  /* margin-left: 12px; */
}

.lawyerReviewDescription {
  margin-top: 7px;
  font-size: 13px;
  line-height: 1.5em;
  display: flex;
  align-items: center;
  color: #dee4f5;
}

.lawyerReviewLoading {
  position: relative;
  display: flex;
  margin-top: 10px;
  border-top: 1px solid #ffffff10;
  padding-top: 14px;
  margin-top: 10px;
  padding-bottom: 2px;
}

.lawyerReviewTextContainer {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  color: white;
}

.lawyerReviewLoadingText {
  font-size: 14px;
}

.lawyerReviewLoading .loadingCircle {
  position: unset;
  background-color: white;
}

.lawyerReviewButton {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  padding: 10px 12px;
  font-size: 13px;
  margin-top: 14px;
  font-weight: 600;
  color: #1348ce;
  cursor: pointer;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.reviewLoader {
  margin-left: 10px;
}

.lawyerReviewButton:hover {
  opacity: 0.9;
}

.finalDocumentRightSidebarHeaderText {
  font-size: 15px;
  font-weight: 600;
  color: #303544;
}

.quickOverviewSection {
  padding: 15px 20px;
  position: relative;
  /* border-bottom: 1px solid #191f2f07; */
  transition: 0.4s ease-in-out;
  background-color: #191f2f08;
  margin: 12px;
  border-radius: 10px;
}

.quickOverviewSectionScroll {
  position: absolute;
  bottom: 15px;
  width: 100%;
  height: 70px;
  background: linear-gradient(to top, #fbfbfb, transparent);
  /* background-color: blue; */
}

.quickOverviewLoadingInfo {
  font-size: 12px;
  margin-bottom: 15px;
  color: #191f2f;
  font-weight: 500;
  border-bottom: 1px solid #191f2f15;
  padding-bottom: 10px;
}

.quickOverviewHeaderText {
  font-size: 15px;
  font-weight: 600;
  color: #191f2f;
  position: relative;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.quickOverviewHeaderText:hover {
  opacity: 0.7;
}

.quickOverviewHeaderTop {
  display: flex;
  align-items: center;
}

.quickOverviewHeaderCloseIcon {
  position: absolute;
  right: 0px;
  font-size: 20px;
  top: 0px;
  color: #4a5061;
  cursor: pointer;
}

.quickOvervhewHeaderTextMain {
  color: #191f2f;
}

.quickOverviewHeaderTextIcon {
  color: #1252f3;
  font-size: 16px;
  margin-right: 7px;
}

.quickOverviewHeaderDescription {
  font-size: 13px;
  color: #353c4d;
  line-height: 1.55em;
  margin-top: 8px;
  font-weight: 500;
  /* border-bottom: 1px solid #191f2708; */
  /* padding-bottom: 10px; */
}

.quickOverviewContent {
  margin-top: 12px;
  padding-bottom: 40px;
  max-height: 300px;
  overflow: scroll;
}

.eachQuickOverviewContent {
  display: flex;
  width: 100%;
  margin-bottom: 0px;
  padding: 5px 6px;
  border-radius: 7px;
  cursor: pointer;
  animation: fadeIn 0.4s ease-in-out;
}

.eachQuickOverviewContent:hover {
  background-color: #191f2f06;
}

.selectedQuickOverviewContent {
  background-color: #1252f312;
  border: 1px solid #1252f3;
}

.quickOverviewNumber {
  font-size: 12px;
  width: 22px;
  height: 20px;
  background-color: #1252f320;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  margin-top: 4px;
  color: #1252f3;
  font-style: italic;
}

.quickOverviewText {
  font-size: 13.5px;
  margin-left: 18px;
  font-style: italic;
  width: 100%;
  line-height: 1.7em;
  display: flex;
  align-self: flex-start;
  justify-self: flex-start;
}

.signatureComingSoon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  border-top: 1px solid #15203e0b;
  padding-top: 15px;
}

.signatureTextLabel {
  font-weight: 500;
  font-size: 14px;
  margin-left: 5px;
}

.signatureComingSoonText {
  text-transform: uppercase;
  letter-spacing: 1.2px;
  font-size: 10px;
  color: #191f2f9c;
}

.finalDocumentSignatureInfoHeader {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #15203e0b;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.finalDocumentSignatureInfoHeader > .helpSectionIcon {
  font-size: 17px;
}

.finalDocumentSignatureInfo {
  margin-bottom: 20px;
  padding: 10px 15px;
  border-radius: 10px;
  background-color: #737e9d13;
}

.finalDocumentSignaturesHeaderText {
  margin-left: 7px;
  font-size: 15px;
}

.finalDocumentEachSignature {
  padding: 10px 0px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #15203e07;
}

.finalDocumentEachSignature:last-child {
  border-bottom: none;
}

.finalDocumentEachSignature:nth-of-type(odd) {
  border-bottom: none;
}

.eachSignatureLeftName {
  font-size: 13px;
  font-weight: 500;
  color: #121620;
}

.eachSignatureLeftEmail {
  font-size: 13px;
  color: #484e5c;
  margin-top: 3px;
}

.eachSignatureStatus {
  padding: 2px 5px;
  background-color: #37a42f18;
  height: fit-content;
  border-radius: 3px;
}

.signedStatus {
  background-color: #12162010 !important;
  color: #121620 !important;
}

.eachSignatureStatusText {
  text-transform: uppercase;
}

.eachSignatureStatus {
  color: #37a42f;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.4px;
}

.finalDocumentRightSectionPopup {
  background-color: white;
  border: 1px solid #191f2f30;
  outline: 4px solid #dfe3ed;
  border-radius: 50px;
  filter: drop-shadow(0 20px 10px rgb(0 0 0 / 0.1))
    drop-shadow(0 15px 10px rgb(0 0 0 / 0.2));
  width: 420px;
  height: fit-content;
  max-height: 80%;
  position: fixed;
  bottom: 50px;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 9999999999999999999999999999;
  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
  overflow-y: scroll;
  transition: 0.6s ease-in-out;
}

.finalDocumentRightSectionInfoLink {
  background-color: #1252f304;
  font-weight: 500;
  color: #1252f3;
  padding-left: 10px;
  padding: 12px;
  font-size: 13px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.finalDocumentRightSectionInfoLinkIcon {
  margin-left: 6px;
}

.finalDocumentRightSectionPopupWithChats {
  border-radius: 15px !important;
  width: 600px;
  z-index: 9999999;
}

.finalDocumentRightSectionOverlay {
  background-color: #191f2fcf;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  animation: fadeIn 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}

.finalDocumentPopupGradientTop {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 50.56%
  );
  height: 50px;
  width: 700px;
  top: 14%;
  z-index: 9999;
  position: fixed;
}

.finalDocumentPopupChatSection {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow-y: scroll;
  /* padding-top: 20px; */
}

.finalDocumentChatUpgrade:hover {
  cursor: pointer;
  background-color: #573ef618;
}

.finalDocumentChatUpgrade {
  transition: 0.3s ease-in-out;
  background-color: #573ef610;
  border-radius: 5px;
  font-size: 13px;
  color: #252c3e;
  padding: 10px 15px;
  display: flex;
  /* align-items: center; */
}

.finalDocumentChatUpgradeText {
  margin-left: 10px;
  line-height: 1.4em;
}

.finalDocumentChatUpgradeIcon {
  font-size: 22px;
  margin-bottom: 5px;
  margin-top: 5px;
  color: #573ef6;
}

.finalDocumentUpgradeLink {
  color: #573ef6;
  font-weight: 600;
  text-decoration: underline;
}

.finalDocumentRightSectionPopupWithChats .finalDocumentChatInputContainer {
  padding: 0px !important;
  border-top: 1px solid #191f2f10;
}

.finalDocumentChatInputContent {
  width: 100%;
  margin: 0 auto;
  /* position: fixed; */
  /* margin-bottom: 10px; */
  z-index: 99999999;
  /* border: 1px solid rgba(67, 75, 97, 0.225); */
  width: 100%;
  border-radius: 50px;
  display: flex;
  align-items: center;
}

.finalDocumentChatActiveIcon {
  position: absolute;
  left: 32px;
  top: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50px;
  background-color: #aec0ec;
}

.finalDocumentRightSectionPopup .finalDocumentChatInputIcon {
  padding-left: 14px !important;
}

.finalDocumentChatInputIcon {
  width: 46px;
  height: 50px;
  background-color: #1252f3;
  color: white;
  background-color: #1252f3;
  /* border: 1px solid #1252f3; */
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.17),
    rgba(92, 92, 92, 0.2)
  );
  /* border-radius: 50px; */
  padding: 10px;
}

.finalDocumentChatInput {
  background: transparent;
  width: 100%;
  padding: 15px 17px;
  font-size: 14.5px;
  /* background-color: rgb(244, 246, 250); */
  border-radius: 50px;
  border: none;
  outline: none;
  color: #191f2f;
  /* box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1); */
}

.finalDocumentChatInput::placeholder {
  color: #848a99;
}

.finalDocumentChatSendButton {
  background-color: #1252f3;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  color: white;
  border-radius: 5px;
  font-size: 14px;
  transition: 0.3s ease-in-out;
}

.finalDocumentChatsHeader {
  display: flex;
  align-items: center;
  padding: 10px 10px;
  border-bottom: 1px solid #12162016;
  position: absolute;
  top: 0px;
  width: 420px;
  /* background: rgba( 255, 255, 255, 0.25 ); */
  /* box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ); */
  backdrop-filter: blur(40.5px);
  -webkit-backdrop-filter: blur(18.5px);
  z-index: 99999;
  background-color: white;
}

.pilotLogo {
  width: 40px;
  height: 40px;
  margin-right: 5px;
}

.finalDocumentChatHeaderRight {
  display: flex;
  flex-direction: column;
}

.finalDocumentChatsHeaderDescription {
  font-size: 12px;
  color: #191f2f70;
  margin-top: 4px;
}

.finalDocumentChatsHeaderIcon {
  color: #12162079;
  font-size: 18px;
}

.finalDocumentHeaderBackIcon {
  margin-right: 15px;
  background-color: #1216201b;
  padding: 2px;
  font-size: 22px;
  border-radius: 5px;
  cursor: pointer;
}

.finalDocumentChatsHeaderText {
  font-size: 13px;
  font-weight: 500;
  color: #121620;
}

.finalDocumentPopupChats {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* margin-bottom: 50px; */
  overflow-y: scroll;
  height: 100%;
  /* padding-top: 45px; */
  background-color: #191f2f07;
  /* margin-top: 10px; */
  padding-bottom: 10px;
  position: relative;
  /* background-color: blue; */
}

.chatTutorial {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 340px;
  text-align: center;
}

.chatTutorialHeader {
  font-weight: 500;
  color: #3b4256;
  font-size: 13px;
  line-height: 1.5em;
}

.finalDocumentPopupChats > .eachChat {
  padding: 14px 20px;
  border-radius: 0px;
  background-color: white;
  color: #191f2f;
  font-size: 14px;
  width: 100%;
  box-shadow: none;
  max-width: 100% !important;
  min-width: 390px;
  margin-bottom: 10px;
  position: relative;
}

.loadingCircle {
  background-color: #1252f3;
  width: 15px;
  height: 15px;
  border-radius: 50px;
  animation: 0.7s pulseAnimation infinite;
  position: absolute;
  right: 20px;
  margin-top: 4px;
}

@keyframes pulseAnimation {
  0% {
    outline: 2px solid #1252f375;
  }
  20% {
    outline: 6px solid #1252f355;
  }
  60% {
    outline: 8px solid #1252f335;
  }
  100% {
    outline: 10px solid #1252f315;
  }
}

.finalDocumentPopupChats > .rightChat {
  letter-spacing: -0.1px;
  background-color: #191f2f03;
  margin: 0px;
  width: 100%;
  /* font-weight: 500; */
}

.finalDocumentPopupChats > .leftChat {
  white-space: pre-line;
  background-color: white;
  margin: 0px;
  width: 100%;
  border-top: 1px solid #191f2f13;
  border-bottom: 1px solid #191f2f13;
}

.userChatName {
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 1.7px;
  text-transform: uppercase;
  color: #191f2f;
  margin-bottom: 3px;
}

.leftChat > .userChatName {
  color: #1252f3;
}

.finalDocumentRating {
  background-color: #737e9d13;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 15px;
  /* border-bottom: 1px solid #15203e0b; */
  /* padding-bottom: 15px; */
  /* padding-top: 5px; */
}

.overallRatingHeader {
  display: flex;
  justify-content: space-between;
}

.overallRatingHeaderText {
  font-weight: 600;
  font-size: 16px;
  color: #191f2f;
}

.overallRatingText {
  font-size: 18px;
  font-weight: 600;
  color: red;
}

.overallRatingDescription {
  margin-top: 5px;
  line-height: 1.6em;
  color: #383f54;
  font-size: 14px;
}

.rightHelpSection {
  /* margin-top: 10px; */
  /* border-top: 1px solid #15203e0b; */
}

.feedbackSection {
  border-top: 1px solid #15203e0b;
  padding: 0px;
  margin-top: 10px !important;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: unset !important;
}

.feedbackSection .helpSectionText {
  margin-left: 0px !important;
  font-size: 14px;
  font-weight: 500;
}

.sendFeedbackButton {
  width: 100%;
  background-color: #191f2f;
  padding: 12px 20px;
  color: white;
  text-align: center;
  border-radius: 5px;
  margin-top: 10px;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.sendFeedbackButton:hover {
  opacity: 0.9;
}

.sendFeedbackButtonText {
  text-decoration: none;
}

.sendFeedbackButtonIcon {
  margin-left: 12px;
}

.feedbackSection .helpSectionDescription {
  font-size: 12px;
  color: #4e5566;
  margin-top: 3px;
  line-height: 1.5em;
  font-weight: 400;
}

.helpSectionHeader {
  font-weight: 600;
  font-size: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 20px;
}

.helpSectionIcon {
  opacity: 0.5;
}

.helpSectionText {
  margin-left: 5px;
}

.apiMessageContainer {
  padding: 20px;
  background-color: #eef0f7;
  border-radius: 10px;
  margin-bottom: 10px;
}

.apiMessageHeaderText {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}

.apiMessageButton {
  width: 100%;
  background-color: white;
  border: 1px solid #1252f3;
  padding: 12px 0;
  border-radius: 5px;
  margin-top: 20px;
  font-weight: 600;
  color: #1252f3;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  cursor: pointer;
  font-size: 14px;
}

.apiMessageText {
  line-height: 1.6em;
  font-size: 15px;
  color: #191f2f;
  letter-spacing: -0.1px;
}

.finalDocumentRightSection:hover {
  background-color: #15203e0f;
  cursor: pointer;
}

.finalDocumentRightSection {
  /* border: 1px solid #15203e13; */
  /* box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.05), 0 2px 4px -2px rgb(0 0 0 / 0.01); */
  border-radius: 5px;
  margin-bottom: 5px;
  display: flex;
  /* min-height: 65px; */
  padding: 7px;
  padding-top: 10px;
  /* align-items: center; */
}

.headerTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.headerTitleIcon {
  opacity: 0.2;
}

.rightSectionHeader {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  margin-left: 10px;
  width: 100%;
  /* padding: 10px 0; */
}

.rightSectionHeaderIcon {
  height: 40px;
  font-size: 22px;
  width: 40px;
  color: rgba(255, 255, 255, 0.8);
  border-right: 1px solid #15203e0d;
  background-color: #1252f3;
  padding: 9px;
  border-radius: 5px;
  /* border-top-left-radius: 10px; */
  /* border-bottom-left-radius: 10px; */
}

.summariseIcon {
  /* color: #1252f3; */
}

.rightSectionHeaderTitle {
  font-weight: 500;
  font-size: 15px;
}

.rightSectionDescription {
  margin-top: 2px;
  font-size: 14px;
  line-height: 1.6em;
  color: #2d354c;
}

.rightSectionButton {
  margin-top: 10px;
  background-color: #1252f3;
  border: none;
  padding: 10px 20px;
  color: white;
  font-weight: 500;
  border-radius: 50px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rightSectionButtonIcon {
  font-size: 16px;
  color: #9fb8fe;
}

.rightSectionButtonText {
  margin-left: 10px;
}

.finalDocumentBlocksContainer {
  padding-top: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
  border-top: 1px solid #15203e0d;
}

.blocksContainerHeaderText {
  font-weight: 600;
  font-size: 15px;
  letter-spacing: -0.2px;
}

.blocksList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 15px;
}

.eachBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #191f2f1d;
  color: #191f2f;
  padding: 12px 0px;
  border-radius: 10px;
  width: 95%;
  cursor: pointer;
}

.blockIcon {
  font-size: 20px;
  opacity: 0.5;
}

.blockText {
  font-size: 14px;
  margin-left: 10px;
  font-weight: 600;
}

.finalDocumentSignatureContainer {
  padding: 20px 15px;
  margin-bottom: 20px;
  /* border-top: 1px solid #15203e10; */
  border: 2px solid #15203e;
  border-radius: 10px;
  background-color: #15203e0e;
}

.signatureContainerHeaderText {
  font-weight: 600;
  letter-spacing: -0.2px;
  font-size: 18px;
}

.signatureDragElement {
  display: flex;
  align-items: center;
  background-color: #191f2f;
  width: 160px;
  color: white;
  justify-content: center;
  padding: 10px;
  border-radius: 50px;
  margin-top: 10px;
  cursor: pointer;
}

.signatureIcon {
  font-size: 20px;
  opacity: 0.7;
}

.signatureText {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
}

.signatureHeaderDescription {
  margin-top: 5px;
  font-size: 14px;
  line-height: 1.6em;
  color: #2d354c;
}

.myDocumentsContainer {
  display: flex;
}

.myDocumentsHeader {
  margin-top: 50px;
}

.myDocumentsHeaderText {
  font-size: 22px;
  font-weight: 700;
  letter-spacing: -0.4px;
  color: #191f2f;
}

.myDocumentsLengthCount {
  margin-top: 5px;
  /* text-transform: uppercase; */
  /* letter-spacing: 1px; */
  font-size: 14px;
  color: #191f2f81;
}

.myDocumentsList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 20px;
  margin-top: 30px;
  margin-bottom: 60px;
  min-width: 100%;
}

.emptyMyDocuments {
  width: 100%;
  height: 400px;
  background-color: #1252f305;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.emptyMyDocumentsHeader {
  font-weight: 600;
  letter-spacing: -0.05px;
  font-size: 18px;
}

.emptyDocumentsIcon {
  width: 55px;
  color: #1252f3;
  border-radius: 12px;
  height: 50px;
  background-color: white;
  filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07))
    drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
  padding: 10px;
  margin-bottom: 25px;
  outline: 4px solid #1252f310;
}

.emptyMyDocumentsDescription {
  font-size: 14.5px;
  width: 350px;
  margin-top: 7px;
  margin-bottom: 15px;
  text-align: center;
  color: #191f2f;
  line-height: 1.6em;
}

.emptyMyDocumentsButton {
  border: 1px solid #1252f3;
  background-color: #1252f3;
  /* background-color: #1252f3; */
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.14),
    rgba(92, 92, 92, 0.1)
  );
  padding: 11px 15px;
  color: white;
  font-size: 14px;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
  outline: 4px solid #1252f318;
}

.emptyMyDocumentsButton:hover {
  /* background-color: #1252f305; */
  outline: 4px solid #1252f315;
}

.skeletonLoader {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 20px 0;
}

.skeletonLoaderElement {
  background-color: #12162018;
  width: 100%;
  border-radius: 5px;
}

.skeletonHeader {
  height: 40px;
  width: 70%;
  margin-bottom: 20px;
}

.skeletonParagraph {
  height: 13px;
  margin: 15px 0;
  opacity: 0.6;
}

.skeletonParagraphGroup {
  margin-bottom: 0px;
}

.skeletonTask {
  height: 15px;
  margin: 15px 0;
  width: 80%;
  margin-left: 10px;
}

.skeletonDivider {
  height: 1px;
  margin: 30px 0;
  opacity: 0.7;
  margin-bottom: 20px;
}

.skeletonButton {
  height: 40px;
  width: 150px;
  border-radius: 50px;
  margin-left: auto;
  margin-top: 20px;
}

.signLinkContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(121.17deg, #e6eaf4 10.18%, #c3cde8 60.26%);
}

.signLinkContent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.signLinkLoadingIcon {
  margin-bottom: 30px;
}

.signLinkIcon {
  width: 4%;
}

.signLinkHeaderText {
  color: #15203e;
  font-weight: 600;
  font-size: 22px;
  width: 250px;
  text-align: center;
  letter-spacing: -0.2px;
  margin-top: 10px;
}

.signLinkDescriptionText {
  margin-top: 10px;
  width: 400px;
  text-align: center;
  line-height: 1.5em;
  color: #3c4869;
}

.signCompleteIcon {
  font-size: 50px;
  color: #37a42f;
}

.signCompleteIconRed {
  color: red;
}

.signSuccessButtons {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.signSuccessButton {
  display: flex;
  align-items: center;
  /* box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1); */
  padding: 10px 15px;
  margin-left: 20px;
  border-radius: 5px;
  cursor: pointer;
}

.signSuccessButtonIcon {
  font-size: 20px;
  margin-right: 10px;
}

.primaryButton {
  background-color: #1e2331;
  color: white;
}

.signSuccessButtonText {
  font-size: 14px;
  font-weight: 500;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 0.6;
  }
}

@keyframes pulseTwo {
  0% {
    opacity: 0.3;
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 0.3;
  }
}

.font-bold {
  font-weight: 600;
}

.loginContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(
    35deg,
    hsl(240deg 80% 72%) 0%,
    hsl(324deg 69% 70%) 3%,
    hsl(5deg 86% 79%) 10%,
    hsl(32deg 56% 78%) 20%,
    hsl(41deg 40% 84%) 31%,
    hsl(15deg 73% 86%) 42%,
    hsl(344deg 78% 87%) 53%,
    hsl(280deg 54% 84%) 64%,
    hsl(247deg 71% 85%) 74%,
    hsl(236deg 74% 84%) 84%,
    hsl(228deg 79% 81%) 93%,
    hsl(221deg 81% 77%) 100%
  );
}

.logoContainer {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  border-bottom: 1px solid #191f2f09;
  align-self: center;
  justify-self: center;
  margin-bottom: 30px;
  margin-left: -25px;
  /* left: 0;
  position: absolute;
  top: -85px; */
  width: 100%;
}

.loginLogo {
  width: 70px;
  height: 70px;
}

.loginLogoText {
  margin-left: -5px;
  font-size: 18px;
  font-family: "Lexend";
  color: #191f2f;
}

.loginInputContainers {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.loginInputContainers > .loginInputContainer {
  width: 49%;
}

.loginLeft {
  width: 50%;
  min-height: 100%;
  /* background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    #1b1a1abb 96.56%
  ),  url(/src/backgroundImage.jpg); */
  background-color: #191f2f;
  border-radius: 10px;
  background-size: cover;
  background-position: top;
  margin: 20px;
  /* background-color: blue; */
}

.loginRight {
  width: 560px;
  /* height: 470px; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  padding: 60px 45px;
  padding-top: 15px;
  border-radius: 10px;
  box-shadow: 0 10px 50px -1px rgba(5, 17, 42, 0.2),
    0 2px 4px -2px rgb(0 0 0 / 0.1);
  /* padding-left: 50px; */
  border: none;
  background-color: white;
  transition: 0.3s ease-in-out;
  position: relative;
}

.loginRightDescription {
  font-size: 16px;
  color: #767c8d;
  margin-top: 7px;
  line-height: 1.5em;
  width: 90%;
}

.verificationDescription {
  /* word-break: break-all; */
  hyphens: manual;
  overflow-wrap: break-word;
}

.loginRightHeader {
  font-size: 25px;
  font-weight: 600;
  letter-spacing: -0.02rem;
  color: #121725;
}

.verifyLoginHeader {
  line-height: 1.3em;
  width: 80%;
}

.loginRightForm {
  margin-top: 30px;
  width: 100%;
}

.loginInputContainer {
  width: 100%;
  margin-bottom: 20px;
}

.loginInputLabel {
  margin-bottom: 5px;
  font-size: 14px;
  opacity: 0.7;
}

.loginInput {
  width: 100%;
  padding: 14px 15px;
  font-size: 15px;
  border: 1px solid #191f2f30;
  border-radius: 7px;
  box-shadow: 0 1px 9px -1px rgba(0, 0, 0, 0.05),
    0 2px 4px -2px rgb(0 0 0 / 0.1);
  outline: none;
  /* font-weight: 500; */
  /* letter-spacing: -0.2px; */
}

.loginInput:focus {
  border: 1px solid #191f2f75;
  outline: 2px solid #191f2f1b;
}

.loginInput::placeholder {
  color: #191f2f7b;
}

.verificationForm {
  margin-top: 20px;
}

.verificationCodeInputContainer {
  width: 100% !important;
}

.loginVerificationForm {
  margin-top: 20px !important;
}

.verifyButton {
  margin-top: 0px !important;
  /* background-color: #1252f3 !important; */
}

.verificationCodeInput {
  width: 100%;
  text-align: center;
  font-size: 18px;
  padding: 18px 15px !important;
}

.changeEmailContainer {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #646b7b;
  margin-bottom: 20px;
  cursor: pointer;
}

.changeEmailIcon {
  font-size: 14px;
  margin-right: 3px;
}

.loginButton {
  width: 100%;
  background-color: #191f2f;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0px;
  margin-top: 20px;
  border-radius: 7px;
  color: white;
  position: relative;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  border: none;
  /* all: unset; */
}

.loginButton:hover {
  outline: 3px solid #c8d1e2;
}

.verificationContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.verifyLockIconContainer {
  width: 60px;
  height: 60px;
  background-color: rgba(0, 128, 0, 0.132);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  /* align-self: center;
  justify-self: center;
  margin: auto; */
  margin-bottom: 20px;
  margin-top: 10px;
}

.verifyLockIcon {
  font-size: 30px;
  color: #008000;
  /* width: 40px; */
  /* height: 40px; */
}

.loginVerifyButton {
  background-color: #191f2f;
  width: 100%;
  /* margin: auto; */
  margin-left: auto;
  /* color: #191f2f; */
}

.loginVerifyButton .loginButtonText {
  /* color: #191f2f; */
  margin-left: -20px;
}

.loginButtonText {
  font-weight: 600;
  letter-spacing: -0.2px;
  font-size: 15px;
  color: white;
}

.loginButtonIcon {
  position: absolute;
  right: 20px;
  font-weight: 700;
}

.loginCheckIconContainer {
  width: 15px;
  height: 15px;
  background-color: white;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginCheckIcon {
  color: #191f2f;
  font-size: 20px;
}

.loginAlreadyHaveAccountText {
  margin-top: 12px;
  font-size: 14px;
  letter-spacing: -0.1px;
  color: #191f2f88;
}

.signupText {
  color: #191f2fca;
  text-decoration: underline;
  cursor: pointer;
}

.errorText {
  color: red;
  font-size: 14px;
}

.appOnboardingModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.appOnboardingModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #191f2fe4;
  /* backdrop-filter: blur(20px); */
  z-index: -1;
}

.appOnboardingModalContentSuccess {
  width: 550px !important;
  animation: fadeIn 0.4s ease-in-out;
  /* transform: scale(0.94); */
}

.appOnboardingModalContentInitial {
  width: 700px !important;
  animation: fadeIn 0.4s ease-in-out;
}

.appOnboardingModalContentInitial .appOnboardingSuccess {
  padding-bottom: 0px;
}

.appOnboardingModalContentInitial .appOnboardingSuccessDescription {
  font-size: 14px;
  padding-top: 0px;
  margin-top: 4px;
  width: 100%;
}

.appOnboardingSuccessBadge {
  /* background-color: #1252f340; */
  color: #1252f3;
  /* padding: 3px 7px; */
  border-radius: 5px;
  font-size: 10px;
  font-weight: 500;
  width: fit-content;
  margin-bottom: 5px;
}

.uploadDocumentModal {
  overflow-y: scroll;
}

.appOnboardingSuccessDescriptionInput {
  padding: 20px;
  height: 230px;
  max-width: 700px !important;
  max-height: 800px !important;
  /* border-bottom-left-radius: 15px; */
  /* border-bottom-right-radius: 15px; */
  border: 1px solid #191f2f10;
  font-size: 14px;
  line-height: 1.5em;
}

.appOnboardingSuccessButtons {
  display: flex;
  align-items: center;
}

.switch_button {
  padding: 7px 13px;
  color: #4567b7;
  background-color: #1252f315;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-left: 15px;
  cursor: pointer;
}

.switch_button p {
  margin-left: 5px;
}

.appOnboardingSuccessDescriptionInput:focus {
  outline: 5px solid #0f49dd20;
}

.appOnboardingSuccessButton {
  background-color: #1252f3;
  padding: 10px 20px;
  width: fit-content;
  justify-self: flex-end;
  border-radius: 7px;
  color: white;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  border: none;
  margin-top: 10px;
  font-size: 13px;
  float: right;
  margin: 20px;
  margin-left: auto;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -2px rgba(0, 0, 0, 0.1);
  font-family: "roboto", sans-serif;
  font-weight: 500;
  letter-spacing: 0.5px;
  /* text-transform: uppercase; */
}

.appOnboardingSuccessHeaderIcon {
  width: 38px;
  height: 38px;
  border-radius: 10px;
  padding: 7px;
  background-color: #1252f310;
  color: #1252f3;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.appOnboardingModalContentInitial .appOnboardingSuccessHeaderContent {
  padding: 10px 20px;
  font-size: 14px;
  padding-top: 25px;
  display: flex;
  /* align-items: center; */
  flex-direction: row;
  padding-bottom: 20px;
}

.appOnboardingModalContentInitial .appOnboardingSuccessHeader {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.appOnboardingModalContent {
  width: 500px;
  height: fit-content;
  background-color: white;
  z-index: 9999999999;
  display: flex;
  align-self: center;
  justify-self: center;
  border-radius: 10px;
  animation: fadeIn 0.4s ease-in-out;
  transition: max-height 1s ease-in-out;
}

.appOnboardingTopContent {
  display: flex;
  align-items: center;
  padding: 10px 30px;
  background-color: #1252f310;
  border-bottom: 1px solid #1252f3;
  position: relative;
}

.appOnbaordingTopEmoji {
  font-size: 16px;
}

.appOnboardingTopInfo {
  margin-left: 12px;
  color: #1252f3;
}

.appOnboardingTopText {
  font-size: 13px;
  font-weight: 500;
}

.appOnboardingTopDescription {
  font-size: 13px;
  margin-top: 3px;
  color: #4274f2;
}

.appOnboardingVideoContainer {
  position: absolute;
  right: 0;
  background-color: #1252f3;
  height: 22px;
  width: 22px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 50px;
  margin-right: 10px;
}

.appOnboardingSuccess {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  /* text-align: center; */
  padding-bottom: 30px;
}

.appOnboardingSuccessHeaderContent {
  border-bottom: 1px solid #191f2f30;
  padding: 20px 30px;
  padding-top: 25px;
  background-color: #191f2f08;
  display: flex;
  flex-direction: column;
  text-align: left;
  /* align-items: center; */
  /* justify-content: center; */
  /* text-align: center; */
}

.appOnboardingSuccessContent {
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  /* text-align: center; */
  width: 100%;
}

.appOnboardingSuccessContent fieldset {
  margin: 0px 30px;
  width: 90%;
}

.appOnboardingTags {
  display: flex;
  align-items: center;
  width: 90%;
  margin: auto;
  overflow: auto;
  white-space: nowrap;
  margin-top: 10px;
  padding: 2px 2px;
}

.appOnboardingTag {
  width: fit-content;
  padding: 5px 10px;
  border-radius: 4px;
  background-color: #191f2f05;
  border: 1px solid #191f2f40;
  font-size: 13px;
  margin-right: 5px;
  color: #272d3e;
  cursor: pointer;
}

.selectedAppOnboardingTag {
  background-color: #1252f310;
  color: #1252f3;
  border: 1px solid #1252f3;
  outline: 2px solid #1252f342;
  font-weight: 500;
}

.appOnboardingInfoLabel {
  margin-bottom: 7px;
  font-size: 14px;
  color: #191f2fbc;
  margin-left: 10px;
}

.appOnboardingDocuments {
  max-height: 280px;
  /* height: fit-content; */
  overflow: scroll;
  margin: 0px 22px;
  margin-top: 10px;
  position: relative;
  padding-bottom: 30px;
}

.documentSelected {
  height: fit-content;
  padding-bottom: 10px;
}

.appOnboardingGradient {
  height: 50px;
  width: 500px;
  bottom: 13.7%;
  position: fixed;
  background: linear-gradient(rgba(255, 255, 255, 0.098), white);
}

.documentButton {
  background-color: #1344c0;
  padding: 12px;
  width: 92%;
  margin: 0 auto;
  border-radius: 10px;
  margin-top: 10px;
  color: white;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #1252f3;
  border: 1px solid #1252f3;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.18),
    rgba(92, 92, 92, 0.1)
  );
}

.disabledDocumentButton {
  opacity: 0.8;
  /* background-color: #191f2f30;
  color: #191f2f80;
  border: none; */
}

.documentButtonIcon {
  opacity: 0.7;
}

.documentButtonText {
  margin-left: 10px;
  font-weight: 500;
}

.noDocumentsFound {
  font-size: 14px;
  margin-top: 5px;
  margin-left: 10px;
  color: #40485f;
}

.noDocumentsFound span {
  text-decoration: underline;
  cursor: pointer;
}

.selectedAppOnboardingEachDocument {
  background-color: #1252f320;
  color: #1252f3;
  font-weight: 500;
  padding: 15px !important;
}

.selectedAppOnboardingEachDocument:hover {
  background-color: #1252f320 !important;
}

@keyframes fadeOut {
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  0% {
    opacity: 0;
  }
}

.documentCheckIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectedAppOnboardingEachDocument .documentCheckIcon {
  position: absolute;
  right: 20px;
  /* padding: 1px; */
}

.selectedAppOnboardingEachDocument .documentCheckIcon:hover {
  /* background-color: #1252f330;
  border-radius: 50px; */
}

.appOnboardingEachDocument {
  display: flex;
  align-items: center;
  padding: 14px 10px;
  transition: 0.2s ease-in-out;
  border-radius: 15px;
}

.appOnboardingEachDocument:hover {
  background-color: #191f2f09;
  cursor: pointer;
}

.appOnboardingEachDocumentIcon {
  color: #1252f3;
  font-size: 17px;
}

.appOnboardingEachDocumentText {
  margin-left: 10px;
  font-size: 15px;
}

.appOnboardingSuccess .onboardingNextButton {
  padding: 11px 20px;
}

.appOnboardingSuccessHeader {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.01em;
}

.appOnboardingSuccessDescription {
  font-size: 15px;
  color: #4b505e;
  width: 90%;
  margin-top: 10px;
  line-height: 1.5em;
}

.appOnboardingVideo {
  margin: 20px 0;
  border-radius: 10px;
  margin-bottom: 10px;
}

.appOnboardingContent {
  width: 100%;
}

.appOnboardingMain {
  padding: 30px;
  padding-top: 5px;
  margin-top: 20px;
}

.stepLabel {
  font-size: 13px;
  color: #191f2f90;
  margin-bottom: 12px;
}

.appOnboardingMainButtons {
  display: flex;
  margin-top: 20px;
}

.appOnboardingBackButton {
  display: flex;
  padding: 7px 12px;
  color: #191f2f60;
  margin-left: 5px;
  font-size: 14px;
  cursor: pointer;
}

.onboardingNextButton {
  background-color: #1344c0;
  padding: 7px 12px;
  border-radius: 5px;
  color: white;
  font-size: 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #1252f3;
  border: 1px solid #1252f3;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.14),
    rgba(92, 92, 92, 0.1)
  );
}

/* CSS */
.onboardingButtonText {
  margin-right: 4px;
}

.appOnboardingHeader {
  margin-bottom: 0px;
  border-bottom: 1px solid #191f2f30;
  padding: 27px 30px;
  padding-bottom: 20px;
  background-color: #191f2f05;
  /* padding-top: 25px; */
}

.appOnboardingLogo {
  width: 35px;
  height: 35px;
  margin-bottom: 10px;
}

.appOnboardingAddWebsite {
  background-color: #4d4ae830;
  padding: 25px 30px;
  border-top: 1px solid #4d4ae875;
}

.appOnboardingAddWebsiteHeader {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #4d4ae8;
}

.appOnboardingAddWebsiteHeader p {
  margin-left: 4px;
}

.appOnboardingAddWebsiteInputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 13px;
}

.appOnboardingAddWebsiteInputs .onboardingNextButton {
  background-color: #4d4ae8;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  border: 1px solid #4d4ae8;
  border-radius: 5px;
  box-shadow: rgba(255, 255, 255, 0.15) 0 1px 0 inset,
    rgba(46, 54, 80, 0.075) 0 1px 1px;
  color: white;
}

.appOnboardingAddWebsiteInputs .input {
  width: 65%;
  padding: 10px;
  border: 1px solid #4d4ae8;
  border-radius: 5px;
  font-size: 14px;
}

.appOnboardingHeaderText {
  font-size: 22px;
  font-weight: 700;
  letter-spacing: -0.01em;
}

.appOnboardingHeaderDescription {
  line-height: 1.6em;
  margin-top: 10px;
  color: #4b505e;
  font-size: 15px;
}

.appOnboardingInputContainer {
  display: flex;
  flex-direction: column;
}

.appOnboardingInputContainer .label {
  font-size: 14px;
  color: rgb(69, 69, 69);
}

.appOnboardingInputContainer .input {
  padding: 10px;
  border: 1px solid rgba(128, 128, 128, 0.426);
  margin-top: 5px;
  border-radius: 5px;
  font-size: 15px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
}

.appOnboardingInputContainer .textarea {
  min-height: 90px;
  max-height: 130px;
  line-height: 1.4em;
  max-width: 100%;
  min-width: 100%;
}

.appOnboardingInputContainer .input:focus {
  outline: 2px solid #303a5525;
}

.onboardingModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999;
}

.onboardingModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #191f2ff1;
}

.onboardingModalContent {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  z-index: 9999999;
  width: 500px;
  border-radius: 10px;
  box-shadow: 0 20px 25px -5px #15203e5f, 0 8px 10px -6px #15203e2f;
  background: linear-gradient(
    180deg,
    rgba(18, 83, 243, 1) 0%,
    rgba(255, 255, 255, 1) 60%
  );
}

.onboardingModalWelcomeText {
  text-align: center;
  padding-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.2px;
  color: white;
}

.onboardingModalTop {
  padding: 20px 30px;
  padding-bottom: 10px;
  animation: fadeIn 0.3s ease-in-out;
}

.onboardingModalImage {
  width: 100%;
  height: 250px;
  background-size: cover;
  background-position: top;
  border-radius: 10px;
  box-shadow: 0 4px 16px -1px rgb(0 0 0 / 0.09), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.lastOnboaradingModalStep {
  /* background: linear-gradient(
    0deg,
    hsl(225, 17%, 40%) 0%,
    hsl(0, 0%, 100%) 40%
  ); */
  display: flex;
  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.632);
  /* transform: rotate(-1deg); */
  user-select: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.onboardingLogoLabel {
  background-image: linear-gradient(
    to right,
    hsl(224deg 62% 14%),
    hsl(224deg 41% 63%)
  );
  background-clip: text;
  -webkit-background-clip: text; /* For Safari/WebKit browsers */

  color: transparent;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  margin-bottom: 5px;
  font-size: 10px;
}

.logoSection {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.logoSectionLine {
  width: 100px;
  height: 0.5px;
  background-color: #59627b40;
  margin-top: 5px;
  margin-bottom: 10px;
}

.onboardingLogoName {
  font-size: 18px;
  color: hsl(224, 29%, 20%);

  margin-left: 0px;
  font-weight: 600;
  letter-spacing: -0.4px;
}

.logoIcon {
  font-size: 22px;
  font-weight: 600;
  color: hsl(224, 26%, 20%);
}

.onboardingLogoText {
  font-size: 34px;
  font-weight: 600;
  letter-spacing: -0.07rem;

  background-color: hsla(225, 55%, 13%, 0.091);
  margin-top: 0px;

  color: #293146;
  padding: 10px;
  border-radius: 10px;
  /* width: 60%; */
  text-align: center;
}

.onboardingModalInfo {
  margin-top: 30px;
  margin-bottom: 25px;
  text-align: center;
}

.onboardingModalTitle {
  font-size: 18px;
  font-weight: 600;
  color: #191f2f;
  /* width: 320px; */
  margin: 0 auto;
}

.onboardingModalDescription {
  font-size: 15px;
  padding-top: 5px;
  line-height: 1.6em;
  width: 90%;
  margin: 0 auto;
  color: #4d5468;
}

.onboardingModalButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #191f2f13;
  padding: 9px 20px;
}

.onboardingModalButtons > div {
  padding: 10px 5px;
  text-align: center;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.skipButtonText {
  color: #121620a1 !important;
}

.onboardingModalButtons p {
  color: #1252f3;
  font-size: 14px;
  font-weight: 500;
}

.stepCircles {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.stepCircle {
  height: 6px;
  width: 6px;
  background-color: #191f2f20;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
}

.currentStepCircle {
  background-color: #3f67cc;
}

.nextButton {
  color: #1252f3;
}

.nextButtonIcon {
  margin-left: 5px;
}

.finishButton {
  /* width: 79px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #1252f313;
  padding: 7.5px !important; */
}

.finishButton .nextButtonIcon {
  font-size: 14px;
}

.signupFlowOnboardingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  position: relative;
  background-image: linear-gradient(
    35deg,
    hsl(240deg 80% 72%) 0%,
    hsl(324deg 69% 70%) 3%,
    hsl(5deg 86% 79%) 10%,
    hsl(32deg 56% 78%) 20%,
    hsl(41deg 40% 84%) 31%,
    hsl(15deg 73% 86%) 42%,
    hsl(344deg 78% 87%) 53%,
    hsl(280deg 54% 84%) 64%,
    hsl(247deg 71% 85%) 74%,
    hsl(236deg 74% 84%) 84%,
    hsl(228deg 79% 81%) 93%,
    hsl(221deg 81% 77%) 100%
  );
  /* padding: 20px; */
}

.loaderPattern {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: url(/public/axiom-pattern.png);
  opacity: 0.5;
  /* background-color: blue; */
}

.addingInfoLoader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #eff5ff;
  /* background: url("/public/cubes.png"), #f7faff; */
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: fadeIn 0.5s ease-in-out;
}

.addingInfoContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 40%;
}

.addingInfoLogo {
  width: 17.2%;
  margin-bottom: 10px;
}

.successAddingInfoContent {
  height: 50%;
}

.successAddingInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  height: 700px;
  border-radius: 15px;
  box-shadow: 0px 4px 28px 0px #191f2f06;
  width: 500px;
  position: relative;
}

.addingInfoSuccessButton {
  background-color: #1252f3;
  width: 160px;
  font-size: 14px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 10px;
  border-radius: 50px;
  cursor: pointer;
}

.addingInfoSuccessButton:hover {
  opacity: 0.9;
}

.autoRedirectCountdownText {
  margin-top: 7px;
  font-size: 13px;
  color: #525a72;
  position: absolute;
  top: 100%;
  margin-top: 20px;
  font-weight: 500;
}

.successAddingInfoLogo {
  width: 70px;
  height: 70px;
  /* box-shadow: 0px 5px 20px 1px #1252f3; */
  outline: 6px solid #376cf325;
  border-radius: 15px;
  animation: rotateLogo 0.7s ease-in-out;
}

@keyframes rotateLogo {
  0% {
    transform: rotate(20deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.successAddingInfoHeader {
  margin-bottom: 10px !important;
  margin-top: 20px;
  font-weight: 600;
}

.addingInfoLoaderHeader {
  font-weight: 600;
  letter-spacing: -0.02em;
  margin-bottom: 32px;
  font-size: 30px;
}

.addingInfoLoaderContainer {
  width: 450px;
  height: 10px;
  background-color: #1252f310;
  margin-bottom: 20px;
  border-radius: 10px;
  position: relative;
}

.addingInfoLoaderIndicator {
  position: absolute;
  left: 0;
  height: 100%;
  width: 30%;
  background-color: #1252f3;
  border-radius: 10px;
  transition: 0.7s ease-in-out;
  animation: pulse 1.5s infinite;
}

.addingInfoLoaderText {
  font-size: 14px;
  width: 500px;
  text-align: center;
  line-height: 1.5em;
  margin: 0 auto;
  color: #1f2434;
}

.signupFlowRightImage {
  width: 100%;
  height: 100%;
  background-image: url("/public//cubes.png");
  opacity: 1;
}

.signupFlowRightContainer {
  width: 30%;
  height: 100%;
  /* background-color: #eff3fb; */
  background-image: linear-gradient(
    35deg,
    hsl(240deg 80% 72%) 0%,
    hsl(324deg 69% 70%) 3%,
    hsl(5deg 86% 79%) 10%,
    hsl(32deg 56% 78%) 20%,
    hsl(41deg 40% 84%) 31%,
    hsl(15deg 73% 86%) 42%,
    hsl(344deg 78% 87%) 53%,
    hsl(280deg 54% 84%) 64%,
    hsl(247deg 71% 85%) 74%,
    hsl(236deg 74% 84%) 84%,
    hsl(228deg 79% 81%) 93%,
    hsl(221deg 81% 77%) 100%
  );
  position: relative;

  /* border-radius: 20px; */
}

.signupFlowRightDetailsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  transform: scale(0.86);
}

.signupFlowRightDetails {
  background-color: white;
  min-height: 570px;
  width: 570px;
  margin: 0 auto;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 28px 0px rgba(0, 0, 0, 0.03);
  transition: 0.4s ease-in-out;
  /* animation: fadeIn 0.3s ease-in-out; */
}

.secondStepRightDetails {
  min-height: fit-content;
  width: 580px;
  padding: 10px 10px;
  transition: 0.4s ease-in-out;
}

.secondStepRightDetails .signupFlowRightDetailsInfo {
  padding-top: 50px;
  border-radius: 12px;
  text-align: left;
  align-items: flex-start;
  /* background-color: blue; */
  height: 100%;
}

.signupFlowRightDetailsInfoHeaderIcon {
  font-size: 50px;
  margin-bottom: 20px;
}

.secondStepRightDetails
  .signupFlowRightDetailsInfo
  .signupFlowRightDetailsInfoHeader {
  width: 100%;
}

.secondStepRightDetails
  .signupFlowRightDetailsInfo
  .signupFlowRightDetailsInfoDescription {
  width: 100%;
  /* margin-left: 15px; */
}

.signupFlowDetailsImageContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 35px;
  /* background-color: #191f2f05; */
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.signupFlowRightDetailsImage {
  width: 75%;
  margin: 0 auto;
  padding: 40px;
  padding-top: 60px;
  padding-bottom: 0px;
}

.signupFlowRightDetailsInfo {
  /* border-top: 1px solid #191f2f09; */
  background-color: white;
  padding: 40px 50px;
  padding-top: 0px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  text-align: center;
  height: 100%;
  width: 100%;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.signupFlowRightDetailsInfoHeader {
  /*  */
  color: #191f2f;
  /* text-align: center; */
  font-family: Inter;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: -0.02em;
  width: 440px;
}

.signupFlowRightDetailsInfoDescription {
  margin-top: 15px;
  line-height: 1.6em;
  color: #444a59;
  font-size: 16.5px;
  width: 440px;
}

.signupFlowRightProgress {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 530px;
}

.signupFlowRightProgressLeft {
  display: flex;
  align-items: center;
}

.signupFlowRightProgressCircle {
  width: 9px;
  height: 9px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50px;
  margin-right: 10px;
  transition: 0.3s ease-in-out;
}

.currentProgress {
  background-color: white;
}

.signupFlowLeftContainer {
  width: 550px;
  margin: 0 auto;
  background-color: white;
  overflow: scroll;
  height: fit-content;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 10px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.signupFlowLeftContainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.signupFlowLeftContainer {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.signupFlowLeftHeader {
  /*  */
  /* padding: 0px 20px; */
  width: 100%;
  width: 100%;
}

.signupFlowLogo {
  width: 120px;
  margin: 0 auto;
}

.signupFlowLeftProgress {
  width: 95%;
  margin: 0px 20px;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.signupFlowProgressInfo {
  display: flex;
  align-items: center;
  margin: 0px 20px;
  margin-top: 5px;
  justify-content: space-between;
}

.signupFlowProgressInfoText {
  font-size: 13px;
  font-weight: 600;
  margin-top: 5px;
}

.finalStepProgressInfoText {
  color: #1252f3;
  font-weight: 600;
}

.signupFlowProgressText {
  font-size: 12px;
  float: right;
}

.signupFlowLeftProgressStep {
  height: 7px;
  background-color: #191f2f0d;
  border-radius: 10px;
  width: 24%;
  transition: 0.4s ease-in-out;
}

.signupFlowLeftProgressStepFinished {
  background-color: #191f2f;
}

.signupFlowLeft {
  padding: 0px 40px;
  height: 65%;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.signupFlowWelcomeText {
  color: #444b5e;
  margin-bottom: 30px;
  letter-spacing: -0.01em;
  font-size: 15px;
}

.signupFlowHeader {
  font-size: 28px;
  /* width: 400px; */
  font-weight: 600;
  letter-spacing: -0.02em;
  /* text-align: center; */
}

.signupFlowDescription {
  margin-top: 5px;
  line-height: 1.6em;
  color: #444956;
  margin-bottom: 30px;
  letter-spacing: -0.01em;
  font-size: 15px;
  /* text-align: center; */
}

.signupFlowQuestionContainer {
  /*  */
  width: 100%;
  margin-top: 10px;
}

.signupFlowQuestionContainer .droppedFiles {
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
}

.signupFlowQuestionContainer .eachDroppedFile {
  height: 100px;
  width: 160px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 14px;
  background-color: #191f2f04;
}

.eachDroppedFileIcon {
  font-size: 18px;
  color: #1252f3;
  margin-bottom: 5px;
}

.removeEachDroppedFile {
  position: absolute;
  top: 5px;
  right: 5px;
}

.signupFlowQuestionContainer .errorText {
  margin-top: 10px;
  color: green;
  margin-bottom: 10px;
}

.signupFlowUploadDocument {
  display: flex;
  align-items: center;
  width: 100%;
  height: 200px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #1252f3;
  font-weight: 500;
  background-color: #1252f310;
  border: 2px solid #1252f3;
  border-style: dashed;
  cursor: pointer;
}

.signupFlowQuestionLabelContainer {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

.signupFlowQuestionToolTipIcon {
  background-color: #191f2f10;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  padding: 3px;
  font-size: 14px;
  border-radius: 50px;
}

.whyTooltip {
  font-size: 13px;
  color: #2f333f;
  text-decoration: underline;
  margin-bottom: 5px;
  margin-left: 10px;
  font-weight: 500;
  cursor: help;
}

.signupFlowQuestionLabel {
  font-size: 15px;
  color: #464b5a;
  margin-bottom: 7px;
  /* width: 100%; */
  white-space: nowrap; /* Prevent text from wrapping to a new line */
  overflow: hidden; /* Hide overflowing content */
  text-overflow: ellipsis; /* Display an ellipsis (...) when text is truncated */
}

.signupFlowQuestionInput {
  width: 100%;
  padding: 13px 15px;
  font-family: "Inter";
  border: none;
  border: 1px solid #d4d6df;
  /* background-color: #191f2f03; */
  background: transparent;
  border-radius: 7px;
  font-size: 14px;
  position: relative;
  margin-top: 3px;
  margin-bottom: 10px;
}

.countriesLoading {
  /* position: absolute;
  top: 0;
  left: 0; */
}

.signupFlowQuestionInput::placeholder {
  color: #989da8;
}

.signupFlowQuestionInput:focus {
  outline: 3px solid #1252f33d;
  /* outline: none; */
}

.signupFlowQuestionTextarea {
  min-width: 100%;
  max-width: 100%;
  min-height: 150px;
  max-height: 180px;
  line-height: 1.5em;
}

.signupFlowQuestionInputInfo {
  /* float: right; */
  line-height: 1.7em;
  font-size: 13px;
  color: #575f76;
  margin-top: 12px;
}

.signupFlowFooter {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-between;
  width: 100%;
}

.signupFlowFooterButtons {
  display: flex;
  align-items: center;
  width: 100%;
}

.skipButton {
  margin-left: auto;
  margin-right: 15px;
  width: fit-content;
  padding: 15px 20px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 10px;
  color: #404659;
  font-size: 15px;
  /* background-color: #191f2f07; */
}

.skipButton:hover {
  background-color: #191f2f10;
}

.signupFlowBackButton {
  display: flex;
  align-items: center;
  color: #191f2f;
  width: 50%;
  margin-top: 40px;
  cursor: pointer;
  padding-bottom: 40px;
}

.signupFlowBackButtonIcon {
  font-size: 23px;
}

.signupFlowBackButtonText {
  margin-left: 2px;
  font-size: 15px;
}

.signupFlowNextButtonIcon {
  font-size: 16px;
}

.signupFlowNextButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #191f2f;
  padding: 13px 22px;
  border-radius: 7px;
  color: white;
  position: relative;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  font-size: 15px;
  width: 100%;
}

.finalSignupFlowButton {
  width: 170px;
  background-color: #0f49dd;
  /* margin-left: auto; */
}

.finalSignupFlowButton .signupFlowNextButtonIcon {
  margin-left: 10px;
}

.signupFlowNextButton:hover {
  opacity: 0.9;
}

.signupFlowNextButtonIcon {
  /* position: absolute; */
  /* right: 15px; */
  margin-left: 20px;
  font-size: 22px;
}

.signupFlowLeftFooter {
  padding: 0px 40px;
  width: 100%;
  margin-bottom: 10px;
  /* background-color: blue; */
}

.loading {
  opacity: 0.7;
}

.signupFlowStates {
  /*  */
}

.signupFlowQuestionInputsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
}

.signupFlowQuestionInputsContainer .signupFlowQuestion {
  /* background-color: blue; */
}

.signupFlowQuestion {
  /* width: 50%; */
  /* background-color: blue; */
  position: relative;
}

.countriesLoading {
  position: absolute;
  left: 50%;
  top: 50%;
}

.signupFlowQuestionInputsContainer .signupFlowQuestion:nth-child(2) {
  width: 100% !important;
  /* margin-left: 20px; */
}

.signupFlowQuestionInput::after {
  /* Add your custom arrow icon styling here */
  /* For example, you can use content and display to add a custom character */
  content: "\25BC" !important; /* Unicode character for a downward-pointing triangle */
  display: none;
  /* You can also add styling for the position, size, color, etc. of the arrow icon */
}

.simplifyContainer {
  /*  */
}

.rightContainerHeader {
  padding-top: 40px;
}

.rightContainerHeaderText {
  font-size: 20px;
  letter-spacing: -0.01em;
  color: #191f2f;
  font-weight: 600;
}

.rightContainerHeaderDescription {
  margin-top: 7px;
  color: #191f2f;
  opacity: 0.8;
  font-size: 14px;
}

.simplifyDocumentsContainer {
  height: 100%;
}

.simplifyDocumentsContainer .skeletonLoader {
  height: fit-content;
}

.loadingContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.461);
  display: flex;
  align-items: center;
  justify-content: center;
}

.simplifyDocumentsUploadContainer {
  /*  */
  background-color: #191f2f04;
  width: 100%;
  height: 78%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed #191f2f20;
  border-radius: 10px;
  margin-top: 30px;
  position: relative;
}

.activeUploadContainer {
  background-color: #1252f307;
  border: 2px dashed #1252f3;
}

.simplifyDocumentsContent {
  width: 440px;
  background-color: white;
  border: 1px solid #191f2f10;
  padding: 15px 18px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
}

.simplifyDocumentsListContainer {
  width: 100%;
  height: 280px;
  overflow: scroll;
}

.chooseDocumentHeaderText {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.1px;
  text-align: left;
  display: flex;
  width: 440px;
  /* align-self: flex-start; */
  margin-bottom: 10px;
  color: #191f2f;
  margin-top: 5px;
}

.simplifyDocumentsSearch {
  width: 100%;
  padding: 12px 15px;
  border-radius: 5px;
  border: 1px solid #191f2f20;
}

.simplifyDocumentsListLoading {
  /* height: 300px; */
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.simplifyDocumentsListLoadingText {
  font-size: 14px;
  color: #343a4d;
  margin-top: 20px;
}

.simplifyDocumentsList {
  margin: 5px 0;
}

.simplifiedDocumentsListContainer {
  margin-top: 30px;
}

.simplifiedDocumentsListHeader {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  color: #191f2f;
  cursor: pointer;
}

.simplifiedDocumentsListHeaderIcon {
  background-color: #5f6678;
  color: white;
  border-radius: 50px;
  margin-left: 7px;
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.simplifiedDocumentsList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  margin-top: 10px;
  grid-gap: 20px;
  animation: fadeIn 0.3s ease-in-out;
}

.emptyListText {
  font-size: 12px;
  color: #656b7d;
}

.simplifiedEachDocumentCard {
  cursor: pointer;
  height: 100px !important;
}

.simplifiedEachDocumentCard .resultCardIcon {
  /* transform: rotate(0deg); */
  top: -15px;
  right: 5px;
  font-size: 50px;
}

.simplifiedDocumentsListHeaderMainIcon {
  color: #191f2f;
  font-size: 14px;
  margin-right: 7px;
}

.simplifiedDocumentsListHeaderIconOpen {
  transform: rotate(180deg);
}

.simplifiedEachDocumentCard .resultCardText {
  font-size: 14px;
  font-weight: 600;
}

.simplifyEachDocument {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 5px;
  border-radius: 5px;
  cursor: pointer;
}

.simplifyEachDocument:hover {
  background-color: #191f2f09;
}

.simplifyEachDocumentLeft {
  display: flex;
  align-items: center;
}

.simplifyEachDocumentIcon {
  font-size: 20px;
  color: #1252f3;
}

.simplifyEachDocumentName {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
}

.simplifyEachDocumentCreatedDate {
  font-size: 12px;
  color: #191f2f;
  text-align: right;
}

.orText {
  margin: 15px 0;
  margin-bottom: 10px;
  color: #c9ccd5;
  font-size: 12px;
}

.simplifyDocumentsUploadButton {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  color: #191f2f;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  background-color: #191f2f07;
}

.simplifyDocumentsUploadButton:hover {
  background-color: #191f2f09;
}

.simplifyDocumentsUploadButtonText {
  margin-left: 10px;
}

.simplifyDocumentsUploadButtonIcon {
  font-size: 18px;
}

.simplifyDocumentsResultHeader {
  /* border-bottom: 1px solid #191f2f10; */
  /* padding-bottom: 15px; */
  margin-bottom: 40px;
}

.simplifyDocumentsHeaderText {
  font-size: 22px;
  letter-spacing: -0.06px;
  font-weight: 600;
  line-height: 1.4em;
  animation: fadeIn 0.2s ease-in-out;
}

.simplifyDocumentsDescription {
  margin-top: 7px;
  line-height: 1.7em;
  color: #424a5e;
  font-size: 15px;
}

.simplifyDocumentsResultsContainer {
  display: flex;
  margin: 90px auto;
  margin-top: 70px;
  padding-bottom: 100px;
  width: 100%;
}

.simplifyDocumentsHeader {
  margin-bottom: 20px;
}

.simplifyDocumentsName {
  display: flex;
  align-items: center;
  background-color: #191f2f15;
  width: fit-content;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

.simplifyDocumentsHeaderNameIcon {
  color: #1252f3;
  margin-right: 10px;
}

.simplifyDocumentsHeaderNameText {
  font-weight: 500;
}

.simplifyDocumentsResults {
  display: flex;
  position: relative;
  width: fit-content;
  margin: 0 auto;
  margin-top: 10px;
}

.simplifyDocumentsGoBack {
  display: flex;
  align-items: center;
  align-self: flex-start;
  font-size: 12px;
  font-weight: 500;
  color: #191f2f70;
  cursor: pointer;
  margin-top: 10px;
  margin-right: 20px;
  text-decoration: none;
}

.simplifyDocumentsGoBackIcon {
  /*  */
}

.simplifyDocumentsGoBackText {
  margin-left: 5px;
}

.simplifyDocumentResultsMainContent {
  width: 630px;
  margin: 0 auto;
}

.simplifiedDocumentRightSection {
  position: absolute;
  top: -60px;
  right: 0;
  display: flex;
  align-items: center;
}

.simplifiedDocumentSaveButton {
  padding: 7px 12px;
  background-color: #1252f3;
  border-radius: 50px;
  font-size: 13px;
  color: white;
  font-weight: 500;
  margin-left: 10px;
  cursor: pointer;
}

.streamStatusContainer {
  display: flex;
  align-items: center;
  background-color: #191f2f10;
  padding: 5px 10px;
  border-radius: 10px;
}

.streamStatusLoading {
  /*  */
}

.streamStatusContainerText {
  margin-left: 10px;
  font-size: 12px;
}

.streamStatusSuccessContainer {
  background-color: rgba(0, 128, 0, 0.1);
}

.streamStatusSuccessContainer {
  color: green;
}

.simplifyDocumentsContents {
  /* position: absolute;
  left: 100%;
  top: 0; */
  margin-left: 70px;
  width: 300px;
  height: fit-content;
  border-left: 1px solid #c0c5d0;
  padding-left: 20px;
}

.simplifyDocumentsContentsHeader {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #2a3042;
  margin-bottom: 10px;
}

.simplifyDocumentsContentsList {
  display: flex;
  flex-direction: column;
}

.simplifyDocumentsEachContent {
  margin-bottom: 7px;
  color: #3c455c;
  line-height: 1.9em;
  font-size: 13px;
  cursor: pointer;
}

.simplifyDocumentsEachContent:hover {
  color: #191f2f;
}

.simplifyResultsCards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 20px;
  margin-top: 20px;
}

.simplifyResultEachCard {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: white;
  filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.03))
    drop-shadow(0 1px 1px rgb(0 0 0 / 0.03));
  border: 1px solid #191f2f20;
  /* box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.06); */
  height: 90px;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  overflow: hidden;
  text-decoration: none;
}

.resultCardIcon {
  font-size: 50px;
  position: absolute;
  top: -4px;
  right: -2px;
  transform: rotate(20deg);
  color: #1252f3;
  opacity: 0.7;
}

.resultCardLabel {
  font-weight: 500;
  font-size: 12px;
  color: #495167;
  margin-bottom: 4px;
}

.resultCardText {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #191f2f;
}

.simplifyResultsContent {
  margin-top: 0px;
}

.simplifyResultInfoContainer:nth-child(1) {
  margin-top: 60px;
}

.simplifyResultInfoContainer {
  margin: 50px 0;
  /* margin-bottom: 20px; */
}

.simplifyResultInfoHeader {
  font-weight: 600;
  letter-spacing: -0.04px;
  color: #191f2f;
  font-size: 15px;
  animation: fadeIn 0.2s ease-in-out;
  margin-top: 30px;
}

.simplifyResultInfoDescription {
  margin-top: 10px;
  line-height: 1.9em;
  font-size: 16px;
  color: #2f3649;
  margin-bottom: 30px;
  white-space: pre-line;
  animation: fadeIn 0.2s ease-in-out;
}

.simplifyResultInfoPoints {
  margin-top: 15px;
}

.simplifyResultInfoPoints > li {
  margin: 15px 0;
  line-height: 1.7em;
  color: #2f3649;
}

.codex-editor__redactor {
  padding-bottom: 20px !important;
}

.cdx-block {
  animation: fadeIn 0.3s ease-in-out !important;
}

.ce-paragraph {
  line-height: 2.2em !important;
  font-size: 14px !important;
  padding: 10px 0px !important;
}

.ce-block__content,
.ce-toolbar__content {
  max-width: 700px;
}

.ce-block:nth-child(1) .ce-header {
  font-weight: 700 !important;
  padding-bottom: 10px !important;
  margin-bottom: 20px !important;
  border-bottom: 1px solid #12162015 !important;
  font-size: 34px !important;
  letter-spacing: -0.4px !important;
  font-family: "Playfair Display", serif !important;
  text-decoration: none !important;
  margin-top: 0px !important;
}

.ce-header {
  margin-bottom: 10px !important;
  font-family: "Playfair Display";
  text-decoration: underline;
  font-size: 20px !important;
  margin-top: 25px !important;
}

.pricingPlansContentContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999999;
  background-color: white;
  padding: 10px 20px;
  overflow-y: scroll;
  animation: fadeInPricingPlans 0.4s ease-in-out;
}

.pricingLoading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.908);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999999;
}

@keyframes fadeInPricingPlans {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}

.pricingPlansNav {
  display: flex;
  align-items: center;
}

.pricingPlansBackButton {
  display: flex;
  align-items: center;
  color: #5d667e;
  padding: 8px 15px;
  border-radius: 10px;
  cursor: pointer;
}

.pricingPlansBackButton:hover {
  background-color: #191f2f07;
}

.pricingPlansBackButtonIcon {
  font-size: 15px;
}

.pricingPlansBackButtonText {
  font-size: 14px;
  margin-left: 7px;
}

.pricingPlanLogo {
  width: 100px;
  margin-left: 20px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  margin-top: 30px;
}

.selectPlanType {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  background-color: #191f2f12;
  padding: 6px 10px;
  border-radius: 50px;
}

.annualPlanType {
  /* outline: 2px solid #3d2e9e; */
  box-shadow: 0px 2px 5px #573ef69f;
  background-color: #573ef6 !important;
  color: white !important;
}

.selectPlanType > div {
  padding: 12px 30px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 14px;
}

.planType {
  color: #191f2f50;
}

.selectedPlanType {
  color: #191f2f;
  background-color: white;
  filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07))
    drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
  font-weight: 500;
}

.pricingPlansHeader {
  width: 570px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin: 0 auto;
  text-align: center;
  height: 170px;
  margin-bottom: 20px;
}

.pricingPlansHeaderText {
  font-size: 36px;
  font-weight: 700;
  color: #131826;
  letter-spacing: -0.01em;
  position: relative;
  /* text-decoration: underline;
  text-decoration-style: dotted;
  text-decoration-color: #573ef6; */
}

.pricingPlansHeaderText .upgradePlanButtonIcon {
  position: absolute;
  left: 101%;
  margin-left: 10px;
  padding: 7px;
  border-radius: 10px;
  transform: rotate(0deg);
  margin-top: 05px;
  background-color: #573ef6;
  box-shadow: 0px 2px 5px #573ef69f;
  color: white;
}

.pricingPlansHeaderTextPurple {
  /* color: #1252f3; */

  /* color: #573ef6; */
  /* background-image: linear-gradient(to top, #311db5, #7966f2);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent; */
  font-weight: 700;
  letter-spacing: -0.02em;
}

.pricingPlansHeaderDescription {
  margin-top: 10px;
  line-height: 1.6em;
  width: 90%;
  color: #31384c;
  font-size: 15px;
}

.pricingPlansContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 60px;
}

.pricingPlansTop {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.eachPricingPlan {
  width: 350px;
  height: 480px;
  background-color: white;
  filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.04))
    drop-shadow(0 2px 2px rgb(0 0 0 / 0.01));
  margin-right: 30px;
  border-radius: 15px;
  position: relative;
  border: 1px solid #191f2f20;
  /* transform: scale(1.04); */
}

.eachPricingPlan .disabledButton {
  opacity: 0.8;
}

.eachPricingPlanHeader {
  padding: 20px;
  border-bottom: 1px solid #191f2f07;
  position: relative;
  background-color: #191f2f05;
}

.pricingPlanBestValue {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  margin: 12px;
  font-size: 12px;
  font-weight: 500;
  color: #311db5;
  background-color: #2100fb0e;
  padding: 5px 10px;
  border-radius: 50px;
  border: 1px solid #311db5;
}

.bestValueText {
  color: #1252f3;
  background-image: linear-gradient(to top, #311db5, #7966f2);
  -webkit-background-clip: text; /* For Safari */
  background-clip: text;
  color: transparent;
  margin-left: 5px;
}

.pricingPlanName {
  font-size: 14px;
  color: #52586a;
}

.proPlan {
  box-shadow: 0px 20px 20px #311db520;
  border: 2px solid #311db5;
  /* height: 4e50px; */
  /* transform: scale(1.01); */
}

.proPlan .eachPricingPlanHeader {
  background-color: #311db505;
}

.proPlan .pricingPlanName {
  color: #1252f3;
  background-image: linear-gradient(to top, #311db5, #7966f2);
  -webkit-background-clip: text; /* For Safari */
  background-clip: text;
  color: transparent;
  font-weight: 600;
  font-size: 16px;
}

.pricingPlanPrice {
  margin-top: 5px;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #141928;
  display: flex;
  align-items: center;
}

.savedAmount {
  font-size: 14px;
  margin-top: 15px;
  display: inline-block;
  color: #626b6c;
  background-color: #311db51e;
  color: #311db5;
  padding: 3px 6px;
  border-radius: 5px;
}

.slashedPriceText {
  text-decoration: line-through;
  margin-right: 5px;
  opacity: 0.7;
}

.pricingFeatures {
  padding: 20px 35px;
  padding-top: 25px;
}

.eachPricingFeature {
  margin-bottom: 15px;
  color: #2c344a;
  font-size: 14.5px;
  line-height: 1.5em;
}

.notAvailableFeature {
  text-decoration-line: line-through;
  opacity: 0.5;
}

.pricingPlanButton {
  position: absolute;
  bottom: 0;
  background-color: #131724;
  padding: 12px 15px;
  color: white;
  width: 95%;
  text-align: center;
  margin: 0 auto;
  border-radius: 10px;
  font-size: 14px;
  left: 50%;
  transform: translate(-50%, 0);
  margin-bottom: 10px;
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.pricingPlanButton:hover {
  opacity: 0.9;
}

.currentPlan {
  opacity: 0.7;
  cursor: help;
}

.currentPlan:hover {
  opacity: 0.7;
}

.proPlanButton {
  background-image: linear-gradient(to top right, #311db5, #6550eb);
  border: 1px solid #1f1084;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  /* position: relative; */
}

.proPlanButton:hover {
  opacity: 0.9;
}

.loadingIconButton {
  position: absolute;
  right: 15px;
  top: 8px;
}

.payAsYouGoPricingPlan {
  width: 760px;
  margin-right: 50px;
  margin-top: 30px;
  margin-bottom: 20px;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  border: 2px solid #191f2f20;
  display: flex;
  align-items: center;
  justify-content: space-between;
  filter: drop-shadow(0 4px 7px rgb(0 0 0 / 0.03))
    drop-shadow(0 2px 2px rgb(0 0 0 / 0.03));
}

.payAsYouGoPricingHeader {
  /*  */
}

.payAsYouGoPricingPlanText {
  color: #191f2f;
  font-size: 22px;
  letter-spacing: -0.02em;
}

.payAsYouGoPricingPlanDescription {
  width: 82.5%;
  line-height: 1.5em;
  font-size: 14px;
  margin-top: 5px;
  color: #40485d;
}

.payAsYouGoPricingPlanPriceContainer {
  display: flex;
  flex-direction: column;
  width: 130px;
}

.payAsYouGoPrice {
  font-size: 36px;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #191f2f;
}

.payAsYouGoPriceText {
  font-size: 12px;
  font-weight: 500;
  color: #4a5164;
}

.chooseDocument {
  display: flex;
  align-items: center;
  margin-top: 7px;
  color: #191f2fd2;
  padding: 3px 0;
  cursor: pointer;
}

.chooseDocument:hover {
  text-decoration: underline;
}

.chooseDocumentText {
  font-size: 13px;
}

.chooseDocumentIcon {
  margin-left: 7px;
}

.settingsContent {
  /*  */
}

.settingsButtons {
  margin-top: 40px;
  width: 100%;
  border-bottom: 1px solid #191f2f10;
}

.selectedSettingButton {
  color: #4474ef !important;
  border-bottom: 2px solid #4474ef;
}

.eachSettingButton {
  font-weight: 500;
  font-size: 14px;
  color: #191f2f79;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
  display: inline-block;
  margin-right: 5px;
  cursor: pointer;
}

.contextSetting {
  padding-bottom: 60px;
}

.contextSettingGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.contextSettingDescription {
  font-size: 14px;
  line-height: 1.7em;
  margin-top: 10px;
  color: #30384b;
}

.contextSettingHeaderContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  /* background-color: #191f2f07; */
  /* padding: 12px 15px; */
  border-radius: 10px;
  /* border-left: 1px solid #191f2f10; */
  /* padding-left: 15px; */
}

.contextSettingDescriptionHeader {
  display: flex;
  align-items: center;
  color: #191f2f;
  cursor: pointer;
}

.contextSettingDescriptionHeaderIcon {
  font-size: 12px;
  color: #9ea3b1;
}

.contextSettingDescriptionHeaderText {
  font-weight: 600;
  font-size: 14px;
  margin-left: 7px;
  color: #191f2f;
}

.rotateIcon {
  transform: rotate(180deg);
}

.contextSettingUploadContainer {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  border-bottom: 1px solid #191f2f20;
  padding-bottom: 30px;
}

.contextSettingUploadContainer .contextSettingUploadDescription {
  padding: 0px;
  margin-top: 0px;
  margin-bottom: 15px;
  border: none;
}

.contextSettingUploadHeader {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 7px;
}

.droppedFilesContainer {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.droppedFiles {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 10px;
  margin-right: 10px;
}

.uploadDroppedFileIcon {
  font-size: 22px;
  color: #1252f3;
}

.uploadFilesButton {
  background-color: #1252f3;
  padding: 12px 25px;
  margin-left: 20px;
  font-weight: 500;
  font-size: 14px;
  color: white;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.uploadFilesButton:hover {
  opacity: 0.9;
}

.eachDroppedFile {
  border: 1px solid #191f2f10;
  padding: 10px 12px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  width: fit-content;
  display: flex;
  align-items: center;
}

.removeEachDroppedFile {
  opacity: 0.7;
  border-radius: 50px;
  margin-left: 5px;
  cursor: pointer;
}

.removeEachDroppedFile:hover {
  background-color: #191f2f10;
}

.contextSettingUploadContent {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #1252f3;
  font-weight: 500;
  background-color: #1252f310;
  border: 2px solid #1252f3;
  border-style: dashed;
  cursor: pointer;
}

.contextSettingUploadContentIcon {
  font-size: 22px;
}

.contextSettingUploadContentText {
  margin-top: 5px;
  font-size: 15px;
}

.contextSettingUploadedDocumentsContainer {
  margin-top: 30px;
  width: 50%;
  padding: 20px 0;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.07), 0 2px 4px -2px rgb(0 0 0 / 0.07);
  border-radius: 10px;
  min-height: 300px;
  border: 1px solid #191f2f10;
}

.contextSettingUploadDescription {
  padding: 0px 20px;
  font-size: 13px;
  margin-top: 4px;
  color: #656c81;
  border-bottom: 1px solid #191f2f08;
  padding-bottom: 12px;
}

.contextSettingUploadedDocumentsContainer .contextSettingUploadHeader {
  padding-left: 20px;
  font-weight: 500;
  margin-bottom: 0px;
}

.contextSettingDocuments {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100px;
}

.contextLoading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 150px;
}

.emptyFilesContext {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 450px;
}

.emptyFilesContextHeader {
  font-size: 16px;
  color: #191f2f;
  font-weight: 600;
}

.emptyFilesContextDescription {
  font-size: 13px;
  color: #3a4052;
  margin-top: 4px;
}

.contextSettingUploadedDocument {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #191f2f09;
}

/* .contextSettingUploadedDocument:hover {
  background-color: #191f2f04;
  cursor: pointer;
} */

.contextSettingUploadDocumentInfo {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.contextSettingDocumentIcon {
  font-size: 18px;
  color: #1252f3;
}

.contextSettingUploadDocumentTitle {
  font-size: 13px;
  font-weight: 500;
  color: #191f2f;
}

.contextSettingUploadDocumentDate {
  color: #191f2fb1;
  font-size: 11px;
  margin-top: 3px;
}

.onboardingSelect {
  padding: 12px 10px;
  margin-top: 10px;
  border-radius: 7px;
  border: 1px solid rgba(128, 128, 128, 0.407);
  font-size: 14px;
}

.lawyerReviewSection {
  background: linear-gradient(to right, #1b59f3, #1b59f3);
  margin: 18px;
  border-radius: 10px;
  padding: 15px;
  color: #c3d1f4;
  outline: 5px solid #a0b7f0;
}

.lawyerReviewHeader {
  /* display: flex;
  align-items: center; */
  color: #f0f2f8;
}

.lawyerReviewHeaderText {
  font-size: 14px;
  font-weight: 500;
  /* margin-left: 12px; */
}

.lawyerReviewDescription {
  margin-top: 7px;
  font-size: 13px;
  line-height: 1.5em;
  display: flex;
  align-items: center;
  color: #dee4f5;
}

.lawyerReviewLoading {
  position: relative;
  display: flex;
  margin-top: 10px;
  border-top: 1px solid #ffffff10;
  padding-top: 14px;
  margin-top: 10px;
  padding-bottom: 2px;
}

.lawyerReviewTextContainer {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  color: white;
}

.lawyerReviewLoadingText {
  font-size: 14px;
}

.lawyerReviewLoading .loadingCircle {
  position: unset;
  background-color: white;
}

.lawyerReviewButton {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  padding: 10px 12px;
  font-size: 13px;
  margin-top: 12px;
  font-weight: 600;
  color: #1348ce;
  cursor: pointer;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.reviewLoader {
  margin-left: 10px;
}

.lawyerReviewButton:hover {
  opacity: 0.9;
}

.redraft_button {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  padding: 10px 15px;
  border-radius: 20px;
  outline: 3px solid rgba(211, 211, 211, 0.374);
  margin-right: 20px;
}

.redraft_button p {
  margin-left: 10px;
  font-weight: 600;
}

.lockedSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 200px;
  align-items: center;
  justify-content: center;
  padding: 20px 30px;
  background-color: #191f2f10;
}

.lockedSectionTitle {
  font-size: 18px;
  margin-top: 15px;
}

.lockedSectionDescription {
  font-size: 14px;
  width: 80%;
  text-align: center;
  line-height: 1.6em;
  opacity: 0.8;
  margin-top: 10px;
}

.upgradeButton {
  background-color: white;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 14px;
  margin-top: 10px;
}

.lockIconContainer {
  background-color: white;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  padding: 14px 20px;
  color: #2256da;
  border-radius: 20px;
  font-size: 28px;
}

.settingsInput {
  padding: 10px;
  border: 1px solid #191f2f40;
  border-radius: 5px;
  font-size: 14px;
  margin-top: 20px;
  width: 400px !important;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.settingsInput:focus {
  outline: 2px solid #191f2f20;
}

.modelDropdownContainer {
  display: flex;
  align-items: center;
}

.inputAddDocument {
  padding: 1px;
  border: 1px solid #05133610;
  border-radius: 50px;
  font-size: 16px;
  margin-top: 10px;
  margin-right: 15px;
  padding: 5px 7px;
  border-radius: 50px;
  color: #191f2f60;
  display: flex;
  align-items: center;
}

.inputAddDocument p {
  font-weight: 500;
  margin-left: 5px;
  font-size: 13px;
}

.inputAddDocument:hover {
  background-color: #191f2f07;
  cursor: pointer;
}

.webSearchAdded {
  background-color: #0f49dd12;
  color: #0f49dd;
}

.newBadge {
  font-size: 10px;
  background-color: rgb(
    255,
    255,
    255,
    0.3
  ); /* Subtle purple background to match existing design */
  color: rgba(255, 255, 255); /* Bright purple color to grab attention */
  padding: 2px 7px;
  border-radius: 15px;
  margin-left: 7px;
  letter-spacing: -0.3px;
  font-weight: 500; /* Slightly bolder text for emphasis */
  /* border: 1px solid #7966f250; */
  /* text-transform: uppercase; Consistent with attention-grabbing design */
}

.input-with-suggestions {
  position: relative;
  width: 100%;
}

.suggestions-dropdown {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.suggestion-item {
  padding: 8px 12px;
  cursor: pointer;
  transition: background 0.2s;
}

.suggestion-item:hover,
.suggestion-item.selected {
  background: #f0f0f0;
}

.reasoningSection {
  padding: 15px 25px;
  border: 1px solid #05133610;
  border-radius: 10px;
  /* margin-bottom: 15px; */
}

.reasoningSection:first-child {
  margin-bottom: 15px;
}

.reasoningSection:last-child {
  /* margin-bottom: 0px; */
}

.reasoningSection:hover {
  border: 1px solid #05133620;
  cursor: pointer;
}

.reasoningSummary {
  opacity: 0.7;
  margin-bottom: 10px;
  font-size: 13px;
}

.reasoningContent {
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #05133610;
  font-style: italic;
  line-height: 1.7em;
  font-size: 13px;
  transition: 0.6s ease-in-out all;
}

.reasoningHeader {
  font-size: 14px;
  letter-spacing: -0.2px;
  /* margin-bottom: 10px; */
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.reasoningHeader:hover {
  /* background-color: #05133603; */
}

.reasoningContainer {
  display: flex;
  align-items: center;
}

.reasoningContainer span {
  margin-left: 5px;
}

.headerChevronDown {
  width: 30px;
  height: 30px;
  background-color: #191f2f10;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #0f49dd;
  padding: 6px;
}

.proBanner {
  padding: 7px;
  background-color: #0c48df;
  color: white;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
}

.proBanner:hover {
  background-color: rgba(12, 72, 223, 0.8);
}

.newChatButton {
  font-size: 14px;
  border-radius: 50px;
  margin-left: 10px;
}

.savedChatsHeader:hover {
  background-color: #191f2f07;
  cursor: pointer;
}

/* input @ suggestions */

.documentSuggestionsDropdown {
  position: absolute;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.07);
  max-height: 150px;
  overflow-y: auto;
  z-index: 1000;
  bottom: 100px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.05), 0 2px 4px -2px rgb(0 0 0 / 0.01);
  min-width: 200px;
  transition: 0.6s ease-in-out all;
}

.suggestionItem {
  padding: 8px;
  cursor: pointer;
  font-size: 13px;
  display: flex;
  align-items: center;
}

.suggestionItem p {
  margin-left: 10px;
}

.suggestionIcon {
  opacity: 0.7;
}

.suggestionItem:hover {
  background: rgba(0, 0, 0, 0.04);
  font-size: 13px;
  border-radius: 10px;
}

.remindersContainer {
  padding: 20px;
}

.eachReminder {
  margin-bottom: 15px;
  display: flex;
}

.eachReminder div {
  margin-left: 15px;
}

.eachReminder .reminderTitle {
  font-size: 14px;
  font-weight: 500;
}

.eachReminder .reminderDesc {
  font-size: 13px;
  margin-top: 10px;
  opacity: 0.7;
  line-height: 1.6em;
}

.reminderIcon {
  width: 20%;
  height: 30px;
  font-size: 27px;
  color: #0f49dd;
}

.reminderButton {
  padding: 5px 12px;
  border-radius: 50px;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  display: flex;
  align-items: center;
  border: 1px solid #191f2f15;
  font-size: 13px;
  font-weight: 500;
  margin-left: 15px;
  cursor: pointer;
}

.reminderButtonIcon {
  color: orange;
  margin-right: 5px;
}

.myDocumentsHeader .searchDocumentsInput {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px;
  margin: 0 auto;
  padding: 15px;
  border-radius: 50px;
  border: 1px solid #12162020;
  color: black !important;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  margin-top: 20px;
}

.myDocumentsHeader .searchDocumentsInput:focus {
  outline: 3px solid #191f2f16;
  border: 1px solid #12162040;
}

.myDocumentsHeader .searchDocumentsInput::placeholder {
  color: gray;
}

.myDocumentsHeader h3 {
  text-align: center;
  margin-top: 15px;
  font-weight: 600;
  letter-spacing: -0.2px;
  font-size: 18px;
  opacity: 0.85;
}

.myDocumentsHeader p {
  width: 500px;
  margin: 0 auto;
  text-align: center;
  line-height: 1.5em;
  opacity: 0.8;
  margin-top: 15px;
  font-size: 14px;
}

.documentsLength {
  color: #1146ce;
}

.noDocumentsFoundContainer {
  width: 400px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.noDocumentsImage {
  width: 30%;
}

.noDocumentsRight {
  margin-left: 25px;
}

.noDocumentsFoundText {
  font-size: 15px;
  line-height: 1.6em;
  color: rgba(0, 0, 0, 0.8);
}

.noDocumentsRightButton {
  background-color: #1146ce15;
  padding: 12px 15px;
  font-size: 12px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  color: white;
  width: fit-content;
  margin-top: 15px;
  color: #1146ce;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid #1146ce10;
}

.noDocumentsRightButton:hover {
  background-color: #1146ce30;
}

.iconNoDocs {
  opacity: 0.7;
  font-size: 15px;
}

.noDocumentsRightButton p {
  margin-left: 10px;
  font-size: 14px;
}

.searchResultsText {
  font-size: 13px;
  margin-bottom: 15px;
  opacity: 0.7;
}
