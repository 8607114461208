/* reset */
button,
fieldset,
.Input {
  all: unset;
}

.DialogOverlay {
  /* background-color: var(--black-a9); */
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  background-color: #00000074;
  z-index: 9999999999;
}

.DialogContent {
  background-color: white;
  border-radius: 10px;
  box-shadow: hsl(206 22% 7% / 60%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 60%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120vw;
  max-width: 500px;
  max-height: 85vh;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 9999999999999;
}
.DialogContent:focus {
  outline: none;
}

.DialogIcon {
  font-size: 22px;
  margin-bottom: 5px;
}

.DialogTitle {
  margin: 0;
  font-weight: 600;
  color: var(--mauve-12);
  font-size: 17px;
}

.DialogContentContainer {
  padding: 25px;
}

.DialogHeader {
  border-bottom: 1px solid var(--mauve-3);
  padding: 25px;
  padding-bottom: 0px;
}

.DialogDescription {
  margin: 10px 0 20px;
  color: var(--mauve-11);
  font-size: 15px;
  line-height: 1.5;
  margin-bottom: 16px;
}

.ButtonContainers {
  margin: 25px;
  display: flex;
  align-items: center;
}

.ButtonSecondary {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--mauve-11);
  margin-top: 20px;
  border-radius: 6px;
  padding: 0 15px;
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  height: 35px;
  cursor: pointer;
}

.ButtonSecondary:hover {
  background-color: #191f2f09;
}

.ButtonMain {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-top: 20px;
  background-color: #1252f3;
  border-radius: 6px;
  padding: 0 15px;
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  height: 35px;
  cursor: pointer;
}
.ButtonMain:hover {
  opacity: 0.8;
}
.Button.violet {
  background-color: white;
  color: var(--mauve-11);
  box-shadow: 0 2px 10px var(--black-a7);
}
.Button.violet:hover {
  background-color: var(--mauve-3);
}
.Button.violet:focus {
  box-shadow: 0 0 0 2px black;
}
.Button.green {
  background-color: var(--green-4);
  color: var(--green-11);
}
.Button.green:hover {
  background-color: var(--green-5);
}
.Button.green:focus {
  box-shadow: 0 0 0 2px var(--green-7);
}

.DialogContent .IconButton {
  font-family: inherit;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--mauve-11);
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 16px !important;
}
.IconButton:hover {
  background-color: var(--mauve-4);
}
.IconButton:focus {
  box-shadow: 0 0 0 2px var(--mauve-7);
}

.Fieldset {
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
  /* align-items: center; */
  margin-bottom: 15px;
  width: 100%;
}

.DialogContent .Label {
  font-size: 14px;
  color: var(--mauve-11) !important;
  padding-left: 0px !important;
  padding-bottom: 10px !important;
  /* padding: 0px; */
  /* width: 90px; */
  /* text-align: right; */
}

.Input {
  width: 95%;
  flex: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 15px;
  line-height: 1;
  color: var(--mauve-11);
  box-shadow: 0 0 0 1px var(--mauve-7) !important;
  height: 35px;
  margin-bottom: 2px;
}
.Input:focus {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.563);
}

.TextArea {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 10px 10px;
  font-size: 15px;
  line-height: 1;
  color: var(--black-a7);
  box-shadow: 0 0 0 1px var(--mauve-7);
  height: 100px;
  margin-bottom: 2px;
  max-height: 200px;
  max-width: 100%;
  border: none;
  outline: none;
  line-height: 1.4em;
}
.TextArea:focus {
  /* outline: 5px solid rgba(0, 0, 0, 0.1); */
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.563);
  background-color: rgba(0, 0, 0, 0.015);
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
