button {
  all: unset;
}

.chatSelectDropdown .SelectTrigger {
  border-radius: 50px;
  padding: 3px 12px;
  width: 70%;
}

.appOnboardingInputContainer .SelectTrigger {
  width: 95%;
  border-radius: 5px;
  padding: 10px 12px;
}

.SelectTrigger {
  width: 90%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  padding: 5px;
  font-size: 13px;
  line-height: 1;
  color: var(--mauve-11);
  box-shadow: 0 0 0 1px var(--mauve-7);
  height: 20px;
  margin-top: 10px;
  padding-left: 10px;
}
.SelectTrigger:hover {
  background-color: var(--mauve-3);
}
.SelectTrigger:focus {
  box-shadow: 0 0 0 2px black;
}
.SelectTrigger[data-placeholder] {
  color: var(--violet-9);
}

.SelectIcon {
  color: Var(--violet-11);
}

.SelectContent {
  overflow: hidden;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  border: 1px solid rgba(22, 23, 24, 0.07);
  z-index: 9999999999999999999999999999999999999;
}

.SelectViewport {
  padding: 5px;
  z-index: 9999999999999999999999999999999999999;
}

.SelectItem .newBadge {
  background-color: #573ef630;
  color: #573ef6;
  padding: 1px 5px;
  font-size: 8px;
}

.SelectItemDescription {
  font-size: 10px;
  margin-top: 4px;
  margin-top: 4px;
  margin-bottom: 3px;
  letter-spacing: -0.2px;
}

.SelectItem {
  font-size: 13px;
  line-height: 1;
  color: var(--violet-11);
  border-radius: 6px;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 35px 0 25px;
  position: relative;
  user-select: none;
}
.SelectItem[data-disabled] {
  color: var(--mauve-8);
  pointer-events: none;
}
.SelectItem[data-highlighted] {
  outline: none;
  background-color: var(--violet-9);
  color: var(--violet-1);
}
.SelectItem:hover {
  background-color: var(--mauve-3);
}

.SelectLabel {
  padding: 0 25px;
  font-size: 12px;
  line-height: 25px;
  color: var(--mauve-11);
}

.SelectSeparator {
  height: 1px;
  background-color: var(--mauve-3);
  margin: 5px;
}

.SelectItemIndicator {
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #1252f3;
}

.SelectScrollButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  background-color: white;
  color: var(--violet-11);
  cursor: default;
}

.SelectPlaceholder span {
  font-size: 12px !important;
}

.selectIcon {
}

/* @import "@radix-ui/colors/black-alpha.css";
@import "@radix-ui/colors/mauve.css";
@import "@radix-ui/colors/violet.css"; */

/* reset */
/* button {
  all: unset;
}

.SelectTrigger {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 15px;
  font-size: 13px;
  line-height: 1;
  height: 35px;
  gap: 5px;
  background-color: white;
  color: var(--violet-11);
  box-shadow: 0 2px 10px var(--black-a7);
}
.SelectTrigger:hover {
  background-color: var(--mauve-3);
}
.SelectTrigger:focus {
  box-shadow: 0 0 0 2px black;
}
.SelectTrigger[data-placeholder] {
  color: var(--violet-9);
}

.SelectIcon {
  color: Var(--violet-11);
}

.SelectContent {
  overflow: hidden;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  z-index: 99999999999999999999999;
}

.SelectViewport {
  padding: 5px;
  z-index: 9999999999999999;
}

.SelectItem {
  font-size: 13px;
  line-height: 1;
  color: var(--violet-11);
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 25px;
  padding: 0 35px 0 25px;
  position: relative;
  user-select: none;
}
.SelectItem[data-disabled] {
  color: var(--mauve-8);
  pointer-events: none;
}
.SelectItem[data-highlighted] {
  outline: none;
  background-color: var(--violet-9);
  color: var(--violet-1);
}

.SelectLabel {
  padding: 0 25px;
  font-size: 12px;
  line-height: 25px;
  color: var(--mauve-11);
}

.SelectSeparator {
  height: 1px;
  background-color: var(--violet-6);
  margin: 5px;
}

.SelectItemIndicator {
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.SelectScrollButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  background-color: white;
  color: var(--violet-11);
  cursor: default;
} */
