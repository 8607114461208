@import "@radix-ui/colors/brownDark.css";
@import "@radix-ui/colors/green.css";
@import "@radix-ui/colors/mauve.css";
@import "@radix-ui/colors/slate.css";
@import "@radix-ui/colors/violet.css";

/* reset */
button {
  all: unset;
}

.ToastViewport {
  --viewport-padding: 25px;
  position: fixed;
  /* left: 50%;
  bottom: 0%;
  transform: translate(-50%, 0); */
  top: 0px;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: var(--viewport-padding);
  gap: 10px;
  width: 390px;
  max-width: 100vw;
  margin: 0;
  list-style: none;
  z-index: 2147483647;
  outline: none;
}

.ToastRoot {
  background-color: white;
  border-radius: 7px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 48px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  padding: 15px;
  /* display: grid;
  grid-template-areas: "title action" "description action";
  grid-template-columns: auto max-content;
  align-items: center; */
  display: flex;
  position: relative;
  /* column-gap: 15px; */
}
.ToastRoot[data-state="open"] {
  animation: slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
}
.ToastRoot[data-state="closed"] {
  animation: hide 100ms ease-in;
}
.ToastRoot[data-swipe="move"] {
  transform: translateX(var(--radix-toast-swipe-move-x));
}
.ToastRoot[data-swipe="cancel"] {
  transform: translateX(0);
  transition: transform 200ms ease-out;
}
.ToastRoot[data-swipe="end"] {
  animation: swipeOut 100ms ease-out;
}

@keyframes hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(30px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes swipeOut {
  from {
    transform: translateX(0px);
    opacity: 1;
  }
  to {
    transform: translateX(30px);
    opacity: 0;
  }
}

.ToastTitle {
  grid-area: title;
  margin-bottom: 5px;
  font-weight: 600;
  color: #191f2f;
  font-size: 13px;
}

.ToastDescription {
  grid-area: description;
  margin: 0;
  color: #191f2fd1;
  font-size: 12px;
  line-height: 1.3;
}

.ToastAction {
  grid-area: action;
}

.Button {
  display: inline-flex;
  /* align-items: center; */
  /* justify-content: center; */
  border-radius: 50px;
  font-weight: 500;
  color: grey !important;
  /* border: 1px solid grey; */
  width: fit-content;
  margin-top: 5px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.Button.small {
  font-size: 13px;
  padding: 0 0px;
  line-height: 20px;
  /* height: 25px; */
}
.Button.large {
  font-size: 15px;
  padding: 0 15px;
  line-height: 35px;
  height: 35px;
}
.Button.violet {
  background-color: white;
  color: var(--violet-11);
  box-shadow: 0 2px 10px var(--black-a7);
}
.Button.violet:hover {
  background-color: var(--mauve-3);
}
.Button.violet:focus {
  box-shadow: 0 0 0 2px black;
}
.Button.green {
  background-color: var(--green-2);
  color: var(--green-11);
  box-shadow: inset 0 0 0 1px var(--green-7);
}
.Button.green:hover {
  box-shadow: inset 0 0 0 1px var(--green-8);
}
.Button.green:focus {
  box-shadow: 0 0 0 2px var(--green-8);
}

.toastContainer {
  display: flex;
  /* align-items: center; */
}

.toastIcon {
  background-color: rgba(0, 128, 0, 0.246);
  height: 30px;
  width: 30px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}

.documentSavedIcon {
  color: green;
  font-size: 24px;
}

.toastContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
